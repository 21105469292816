import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DataService } from "../data.service";

@Component({
  selector: "app-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
})
export class CounterComponent implements OnInit {
  canvas: HTMLCanvasElement;
  confettiCanvas;
  confettiLib;

  counter;
  audio;

  constructor(
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    public firestore: AngularFirestore,
    public dataService: DataService
  ) {}

  ngOnInit() {
    this.audio = new Audio();
    this.audio.src = "../../../assets/sound.mp3";
    this.audio.load();

    this.firestore
      .collection("stats")
      .doc("count")
      .valueChanges()
      .subscribe((res: any) => {
        console.log("newCount", res);
        if(this.counter){
          this.celebrate();
          this.play()
        }
        this.counter = res.count;
      });
  }

  play() {
    this.audio.play();
  }

  async importCanvas(): Promise<any> {
    this.confettiLib = await import("canvas-confetti");
    this.canvas = this.renderer2.createElement("canvas");
  }

  celebrate(): void {
    console.log("clelebrate");
    let checkCanvasConfettiExists = async () => Promise.resolve(); // set this to resolve regardless if confetti already exists
    if (!this.confettiCanvas) {
      // if not already imported, replace with importing function
      checkCanvasConfettiExists = this.importCanvas;
    }
    checkCanvasConfettiExists
      .call(this) // bind to 'this' as the importCanvas function will need the correct 'this'
      .then(() => {
        this.renderer2.appendChild(this.elementRef.nativeElement, this.canvas); // append the canvas

        this.confettiCanvas = this.confettiLib.create(this.canvas, {
          resize: true,
        }); // create the confetti canvas
        const end = Date.now() + 5 * 1000; // set the end time
        const interval = setInterval(() => {
          if (Date.now() > end) {
            // if time reached then clear the interval
            clearInterval(interval);

            return; //this.renderer2.removeChild(this.elementRef.nativeElement, this.canvas); // remove the canvas from the DOM
          }
          var colors = ["#FF1D3F", "#FFB400", "#FFFFFF"];

          this.confettiCanvas({
            // if time hasn't passed then call the start the confetti
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            shapes: ["square", "circle"],
            colors: colors,
            origin: {
              x: Math.random(),
              // since they fall down, start a bit higher than random
              y: Math.random() - 0.2,
            },
          });
        }, 500);
      });
  }
}
