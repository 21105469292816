<ion-content>

    <ion-grid>
        <ion-row>
            <ion-col sizeMd="5" offsetMd="1" size="6">
                <img id="logo" src="/assets/logo/logo_color.png" class="alignEnd" />
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col sizeMd="10" offsetMd="1" size="12">
                <!-- <h4>Vielen Dank, dass du uns hilfst. Hinterlasse hier genre deine Anregungen!</h4> -->
                <h4>Danke für deinen Support. Was hat dir gut gefallen und was weniger?</h4>
                <ion-textarea auto-grow='true' id="covid" [(ngModel)]="feedback" rows="2">
                </ion-textarea>
                <ui-select *ngIf="!done" (valueChange)="saveFeedback()" [items]="[{id: 'send', name: 'Senden', iconClass: 'far fa-forward'}]">
                </ui-select>
                <ui-select *ngIf="done" (valueChange)="back()" [items]="[{id: 'send', name: 'Zurück', iconClass: 'far fa-chevron-left'}]">
                </ui-select>
            </ion-col>
        </ion-row>
    </ion-grid>
