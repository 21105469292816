<ion-content style="padding-left:10px">

  <p><strong>Teilnahmebedingungen</strong></p>
  <p><br /> Die Teilnahme am Gewinnspiel von karriereheld, nachfolgend Betreiber oder genannt, ist kostenlos und richtet sich ausschlie&szlig;lich nach diesen Teilnahmebedingungen.</p>
  <p><strong><br /> Ablauf des Gewinnspiels<br /> </strong>Die Dauer des Gewinnspiels erstreckt sich vom 28.12.2020, 10.00 Uhr bis zum 31.01.2021, 20.00 Uhr.</p>
  <p>Innerhalb dieses Zeitraums erhalten Nutzer online die M&ouml;glichkeit, am Gewinnspiel teilzunehmen.</p>
  <p><strong><br /> Teilnahme<br /> </strong>Um am Gewinnspiel teilzunehmen, ist ein Ausf&uuml;llen und Absenden des angezeigten Teilnahmeformulars notwendig. Die Teilnahme ist nur innerhalb des Teilnahmezeitraums m&ouml;glich. Nach Teilnahmeschluss eingehende
      Einsendungen werden bei der Auslosung nicht ber&uuml;cksichtigt.</p>
  <p>Pro Teilnehmer nimmt nur eine &uuml;bermittelte Anmeldung am Gewinnspiel teil. Es ist strengstens untersagt, mehrere E-Mail-Adressen zur Erh&ouml;hung der Gewinnchancen zu verwenden.</p>
  <p>Die Teilnahme am Gewinnspiel ist kostenlos.</p>
  <p><strong><br /> Teilnahmeberechtigte<br /> </strong>Teilnahmeberechtigt sind nat&uuml;rliche Personen, die Ihren Wohnsitz in Deutschland haben und das 14. Lebensjahr vollendet haben. Die Teilnahme ist nicht auf Kunden des Betreibers beschr&auml;nkt
      und nicht vom Erwerb einer Ware oder Dienstleistung abh&auml;ngig.</p>
  <p>Sollte ein Teilnehmer in seiner Gesch&auml;ftsf&auml;higkeit eingeschr&auml;nkt sein, bedarf es der Einwilligung seines gesetzlichen Vertreters.</p>
  <p>Nicht teilnahmeberechtigt am Gewinnspiel sind alle an der Konzeption und Umsetzung des Gewinnspiels beteiligte Personen und Mitarbeiter des Betreibers sowie deren direkte Familienmitglieder. Zudem beh&auml;lt sich der Betreiber vor, nach eigenem Ermessen
      Personen von der Teilnahme auszuschlie&szlig;en, wenn berechtigte Gr&uuml;nde vorliegen, beispielsweise</p>
  <p>(a) bei Manipulationen im Zusammenhang mit Zugang zum oder Durchf&uuml;hrung des Gewinnspiels, (b) bei Verst&ouml;&szlig;en gegen diese Teilnahmebedingungen, (c) bei unlauterem Handeln oder (d) bei falschen oder irref&uuml;hrenden Angaben im Zusammenhang
      mit der Teilnahme an dem Gewinnspiel.</p>
  <p><strong><br /> Gewinn, Benachrichtigung und &Uuml;bermittlung des Gewinns<br /> </strong>Folgende Preise werden vergeben:<br /> 3 Amazon-Gutscheine im Wert von je 20 &euro;</p>
  <p>&nbsp;</p>
  <p>Die Ermittlung der Gewinner erfolgt nach Teilnahmeschluss im Rahmen einer auf dem Zufallsprinzip beruhenden Verlosung unter allen Teilnehmern. Ist das Gewinnspiel mit einer Aufgabe verkn&uuml;pft, kommen ausschlie&szlig;lich diejenigen Teilnehmer
      in die Verlosung, welche die Aufgabe korrekt durchgef&uuml;hrt haben.</p>
  <p>Die Gewinner der Verlosung werden zeitnah &uuml;ber eine gesonderte E-Mail &uuml;ber den Gewinn informiert.</p>
  <p>Die Aush&auml;ndigung des Gewinns erfolgt ausschlie&szlig;lich an den Gewinner oder an den gesetzlichen Vertreter des minderj&auml;hrigen Gewinners. Ein Umtausch, eine Selbstabholung sowie eine Barauszahlung des Gewinns sind nicht m&ouml;glich.</p>
  <p>Eventuell f&uuml;r den Versand der Gewinne anfallende Kosten &uuml;bernimmt der Betreiber. Mit der Inanspruchnahme des Gewinns verbundene Zusatzkosten gehen zu Lasten des Gewinners. F&uuml;r eine etwaige Versteuerung des Gewinns ist der Gewinner selbst
      verantwortlich.
  </p>
  <p>Meldet sich der Gewinner nach zweifacher Aufforderung innerhalb einer Frist von 3 Wochen nicht, kann der Gewinn auf einen anderen Teilnehmer &uuml;bertragen werden.</p>
  <p><strong><br /> Beendigung des Gewinnspiels<br /> </strong>Der Veranstalter beh&auml;lt sich ausdr&uuml;cklich vor, das Gewinnspiel ohne vorherige Ank&uuml;ndigung und ohne Mitteilung von Gr&uuml;nden zu beenden. Dies gilt insbesondere f&uuml;r jegliche
      Gr&uuml;nde, die einen planm&auml;&szlig;igen Ablauf des Gewinnspiels st&ouml;ren oder verhindern w&uuml;rden.</p>
  <p>&nbsp;</p>
  <p><strong>Haftung</strong></p>
  <p>Schadenersatzanspr&uuml;che gegen&uuml;ber dem Betreiber, die im Zusammenhang mit der Gewinnaktion stehen, sind &ndash; innerhalb des gesetzlich zul&auml;ssigen &ndash; unabh&auml;ngig vom Rechtsgrund ausgeschlossen, es sei denn, der Betreiber h&auml;tte
      vors&auml;tzlich oder grob fahrl&auml;ssig gesetzliche Pflichten verletzt. Ferner haftet der Betreiber nicht f&uuml;r Sch&auml;den aus der Beeintr&auml;chtigung der Verf&uuml;gbarkeit der Gewinnaktion-Internetseite bei nicht beeinflussbaren technischen
      St&ouml;rungen und Ereignissen h&ouml;herer Gewalt, sowie Angriffen Dritter gegen die Gewinnaktion- Internetseite. Der Betreiber wird jedoch alles unternehmen, um die Zuverl&auml;ssigkeit und Funktionsf&auml;higkeit der Gewinnaktion- Internetseite
      sicherzustellen. Weiterhin &uuml;bernimmt der Betreiber keine Garantie daf&uuml;r, dass die Gewinnaktion-Internetseite auf dem jeweiligen Teilnehmerrechner ordnungsgem&auml;&szlig; funktionsf&auml;hig ist.</p>
  <p><strong><br /> Datenschutz<br /> </strong>F&uuml;r die Teilnahme am Gewinnspiel ist die Angabe von pers&ouml;nlichen Daten notwendig. Der Teilnehmer versichert, dass die von ihm gemachten Angaben zur Person, insbesondere Vor-, Nachname und Emailadresse
      wahrheitsgem&auml;&szlig; und richtig sind.</p>
  <p>Der Veranstalter weist darauf hin, dass s&auml;mtliche personenbezogenen Daten des Teilnehmers ohne Einverst&auml;ndnis weder an Dritte weitergegeben noch diesen zur Nutzung &uuml;berlassen werden.</p>
  <p>Im Falle eines Gewinns, erkl&auml;rt sich der Gewinner mit der Ver&ouml;ffentlichung seines Namens und Wohnorts in den vom Veranstalter genutzten Werbemedien einverstanden. Dies schlie&szlig;t die Bekanntgabe des Gewinners auf der Webseite des Betreibers
      und seinen Social Media Plattformen mit ein.</p>
  <p>Der Teilnehmer kann seine erkl&auml;rte Einwilligung jederzeit widerrufen. Der Widerruf ist schriftlich an die E-Mail-Adresse <a href="mailto:gewinnspiel@karriereheld.team">gewinnspiel@karriereheld.team</a> des Betreibers zu richten. Nach Widerruf
      der Einwilligung werden die erhobenen und gespeicherten personenbezogenen Daten des Teilnehmers umgehend gel&ouml;scht.</p>
  <p><strong><br /> Facebook Disclaimer<br /> </strong>Diese Aktion steht in keiner Verbindung zu Facebook und wird in keiner Weise von Facebook gesponsert, unterst&uuml;tzt oder organisiert.</p>
  <p><strong><br /> Anwendbares Recht<br /> </strong>Fragen oder Beanstandungen im Zusammenhang mit dem Gewinnspiel sind schriftlich an die E-Mail-Adresse <a href="mailto:gewinnspiel@karriereheld.team">gewinnspiel@karriereheld.team</a> des Betreibers zu
      richten.
  </p>
  <p>Das Gewinnspiel des Betreibers unterliegt ausschlie&szlig;lich dem Recht der Bundesrepublik Deutschland. Der Rechtsweg ist ausgeschlossen.</p>
  <p><strong><br /> Salvatorische Klausel<br /> </strong>Sollte eine Bestimmung dieser Teilnahmebedingungen ganz oder teilweise unwirksam sein oder werden, so wird dadurch die G&uuml;ltigkeit dieser Teilnahmebedingungen im &Uuml;brigen nicht ber&uuml;hrt.
      Statt der unwirksamen Bestimmung gilt diejenige gesetzlich zul&auml;ssige Regelung, die dem in der unwirksamen Bestimmung zum Ausdruck gekommenen Sinn und Zweck wirtschaftlich am n&auml;chsten kommt. Entsprechendes gilt f&uuml;r den Fall des Vorliegens
      einer Regelungsl&uuml;cke in diesen Teilnahmebedingungen.</p>
  <p>&nbsp;</p>
  <p>Viel Gl&uuml;ck und Erfolg w&uuml;nscht das Team von karriereheld</p>

</ion-content>