import { DoneComponent } from "./survey/done/done.component";
import { CounterComponent } from "./counter/counter.component";
import { SetStaticComponent } from "./static/set-static/set-static.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule, NoPreloading } from "@angular/router";
import { SurveyComponent } from "./survey/survey/survey.component";
import { TestingComponent } from "./survey/testing/testing.component";
import { FeedbackComponent } from "./survey/feedback/feedback.component";
import { PrivacyAgbComponent } from "./privacy/privacy-agb/privacy-agb.component";
import { PrivacyImprintComponent } from "./privacy/privacy-imprint/privacy-imprint.component";
import { PrivacyParticipationComponent } from "./privacy/privacy-participation/privacy-participation.component";
import { environment } from "src/environments/environment";
import { BetaComponent } from "./beta/beta.component";

const routes: Routes = [
  {
    path: "demographic",
    component: SurveyComponent,
  },

  {
    path: "testing",
    component: TestingComponent,
  },

  {
    path: "",
    loadChildren: () =>
      import("./survey/fit/fit.module").then((m) => m.FitModule),
  },
  {
    path: "auth/sso",
    loadChildren: () =>
      import("./auth/sso/sso.module").then((m) => m.SsoModule),
  },
  {
    path: "done",
    component: DoneComponent,
  },
  {
    path: "beta",
    component: BetaComponent,
  },
  {
    path: "feedback",
    component: FeedbackComponent,
  },
  {
    path: "agb",
    component: PrivacyAgbComponent,
  },
  {
    path: "imprint",
    component: PrivacyImprintComponent,
  },
  {
    path: "participation",
    component: PrivacyParticipationComponent,
  },
  {
    path: "counter",
    component: CounterComponent,
  },
];

if (!environment.production) {
  routes.push({
    path: "static",
    component: SetStaticComponent,
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
