<!-- <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 10; background: white;">

      <ui-matrix></ui-matrix>

</div> -->




<ion-content #pageTop>
  <ng-container *ngIf="!done">
      <div style="display: flex; height: 50px; position: fixed; width: 100%; z-index: 10">

          <ng-container *ngFor="let p of pages; let i = index">
              <div class="progressBar" [class.disabled]="i+1>currentPageIndex" [class.hideMobile]="p.hideMobile"
                  *ngIf="p.icon">
                  <ion-button class="small" [class.disabled]="i>currentPageIndex">
                      <i [class]="p.icon"></i>
                  </ion-button>
              </div>
          </ng-container>
      </div>
      <!-- <div style="padding-top: 50px" (keydown.enter)="EnterToTab($event)" id="test" > -->
      <div style="padding-top: 50px" id="test">
          <ion-slides #slides (ionSlideDidChange)="slideChanged()" (ionSlideTap)="nothing()" style="width:100%"
              [options]="{cssMode: false, simulateTouch: false, shortSwipes:false}" *ngIf="user && DataService.static">

            


              <ion-slide id="beruf_happy">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12">
                            <h2><i class="far fa-industry"></i> Antwortmöglichkeit 1
                            </h2>

                           Welcher Seite stimmt man mehr zu? 

                        </ion-col>
                        <ion-col offset="1" size="10" class="slider">
                            <ngx-slider [(value)]="user.beruf_happy" [options]="optionsSlider_100_50_100"></ngx-slider>
                        </ion-col>
                        <ion-col size="12">


                            <p *ngIf="user.beruf_happy == 50" class="typeInfo">50% kann nicht ausgewählt werden</p>

                            <ui-select *ngIf="user.beruf_happy != 50" [items]="weiterButtonBerufHappy"></ui-select>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-slide>


        


              <ion-slide id="beruf_happy">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="12">
                              <h2><i class="far fa-industry"></i> Antwortmöglichkeit 3
                              </h2>

                              Ich stimme der Aussage zu {{user.beruf_happy}} % zu

                          </ion-col>
                          <ion-col offset="1" size="10" class="slider">
                              <ngx-slider [(value)]="user.beruf_happy" [options]="optionsSlider0_100"></ngx-slider>
                          </ion-col>
                          <ion-col size="12">


                              <p *ngIf="user.beruf_happy == 50" class="typeInfo">50% kann nicht ausgewählt werden</p>

                              <ui-select *ngIf="user.beruf_happy != 50" [items]="weiterButtonBerufHappy"></ui-select>
                          </ion-col>
                      </ion-row>
                  </ion-grid>
              </ion-slide>


              <ion-slide id="berufsfeld">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12">
                            <h2><i class="far fa-caret-up"></i> Was zeichnet dich aus? </h2>
                        </ion-col>
                        <ion-col size="12">
                          Wähle aus den Wörtern die aus, die dich am besten beschreiben! Du kannst maximal 5 auswählen.
                            <!-- <ui-select [items]="DataService.static?.berufsfelder" [(value)]="user.berufsfeld"
                            [custom]="true" [customMap]="DataService.staticMap?.berufsfelder"
                            (valueChange)="selectedItemGo('berufsfeld', user.berufsfeld)"></ui-select> -->
                            <ui-select [items]="DataService.static?.berufsfelder" [(value)]="user.berufsfeld"
                                 type="checkbox" maxCheckbox="5"></ui-select>
                        </ion-col>
                        <!-- <ion-col size="12" *ngIf="user.berufsfeld" style="margin-top: 1rem;">
                        Stellenbezeichnung
      
                        <ui-select [items]="DataService.static?.stellenbezeichnungen[user.berufsfeld]" [(value)]="user.stellenbezeichnung" [search]="true" [customMap]="DataService.staticMap?.stellenbezeichnungen"></ui-select>
                    </ion-col> -->
                    </ion-row>
                </ion-grid>
            </ion-slide>
            


              <ion-slide id="beruf_diffrentTeam">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="12">
                              <h2><i class="far fa-industry"></i> Würde ein anderes Team deine Zufriedenheit
                                  verbessern oder
                                  verschlechtern?
                              </h2>
                          </ion-col>
                          <ion-col offset="1" size="10" class="slider">
                              <ngx-slider [(value)]="user.beruf_diffrentTeam" [options]="optionsSliderDiffrent">
                              </ngx-slider>
                          </ion-col>
                          <ion-col size="12">
                              <p *ngIf="user.beruf_diffrentTeam == 5" class="typeInfo">Entscheide dich für eine Seite
                                  | 5 kann
                                  nicht ausgewählt werden</p>
                              <ui-select *ngIf="user.beruf_diffrentTeam != 5" [items]="weiterButtonBerufFreundschaft">
                              </ui-select>
                          </ion-col>
                      </ion-row>
                  </ion-grid>
              </ion-slide>


              <ion-slide id="beruf_effektiv">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="12">
                              <h2><i class="far fa-industry"></i> Wie effektiv schätzt du dein Team ein?
                              </h2>
                              Entscheide dich auf der Skala zwischen 1 (schlecht) und 9 (genau richtig)
                          </ion-col>

                          <ion-col offset="1" size="10" class="slider">


                              <ngx-slider [(value)]="user.beruf_effektiv" [options]="optionsSlider"></ngx-slider>
                          </ion-col>

                          <ion-col size="12">
                              <p *ngIf="user.beruf_effektiv == 5" class="typeInfo">5 kann nicht ausgewählt werden</p>
                              <ui-select *ngIf="user.beruf_effektiv != 5" [items]="weiterButtonBerufEffektiv">
                              </ui-select>
                          </ion-col>

                      </ion-row>
                  </ion-grid>
              </ion-slide>

              <ion-slide id="effectiveranderesteam">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="12">
                              <h2>
                                  <i class="far fa-users"></i> Glaubst du in einem anderen Team wärst du effektiver?
                              </h2>
                              <ui-select [items]="DataService.static?.effectiveranderesteam"
                                  [(value)]="user.effectiveranderesteam"
                                  (valueChange)="selectedItemGo('effectiveranderesteam', user.effectiveranderesteam)">
                              </ui-select>
                          </ion-col>
                      </ion-row>
                  </ion-grid>
              </ion-slide>
              
              <ion-slide id="herkunft">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="12">^
                              <h2>
                                  <i class="far fa-home-lg"></i> Woher kommst du?
                              </h2>
                              <ui-select [items]="DataService.static?.herkunft" [(value)]="user.herkunft"
                                  (valueChange)="selectedItemGo('herkunft', user.herkunft)"></ui-select>
                          </ion-col>
                      </ion-row>
                  </ion-grid>
              </ion-slide>

              <ion-slide id="einkommen">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="12">

                              <h2>
                                  <i class="far fa-money-bill-wave"></i> Wie viel verdienst du im Jahr?
                              </h2>
                              <p class="typeInfo ">Es ist uns nicht möglich diese Daten deiner Person zuzuordnen
                              </p>
                          </ion-col>

                          <!-- <ui-select [items]="DataService.static?.einkommen " [(value)]="user.einkommen "
                                  (valueChange)="selectedItemGo( 'einkommen', user.einkommen) "></ui-select>
                                   -->

                          <ion-col offset="1" size="10" class="slider">
                              <ngx-slider [(value)]="user.einkommen" [options]="optionsEinkommen"></ngx-slider>
                          </ion-col>
                          <ion-col size="12">
                              <ui-select [items]="weiterButtonEinkommen"></ui-select>
                          </ion-col>

                      </ion-row>
                  </ion-grid>
              </ion-slide>


              <ion-slide id="anfahrt">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="12">
                              <h2>
                                  <i class="far fa-subway"></i> Wie lange braucht du durchschnittlich zur Arbeit?
                              </h2>
                          </ion-col>
                          <ion-col offset="1" size="10" class="slider">
                              <ngx-slider [(value)]="user.anfahrt" [options]="optionsAnfahrt"></ngx-slider>
                          </ion-col>
                          <ion-col size="12">
                              <ui-select *ngIf="user.anfahrt" [items]="fertigButton"></ui-select>
                          </ion-col>
                      </ion-row>
                  </ion-grid>
              </ion-slide>





          </ion-slides>
      </div>

      <!-- <ion-button *ngIf="currentPageIndex> 0" (click)="scrollTo(pages[currentPageIndex-1].id)">
                              <i class="far fa-chevron-left"></i> Zurück</ion-button> -->
      <ion-button *ngIf="currentPageIndex > 0 && (currentPageId != 'personalfit_speed')" (click)="slidePrev()"
          style="position: fixed; bottom:80px; left:30px; z-index:1">
          <i class="far fa-chevron-left"></i>
      </ion-button>
      <!-- 
      <ion-button *ngIf=" currentPageIndex==2" (click)="slideNext()" style="position: fixed; bottom:80px; right:30px; z-index:1" size="large">
          Weiter <i class="far fa-chevron-right"></i></ion-button>
-->

      <ion-button *ngIf="loading" style="position: fixed; bottom:80px; right:30px; z-index:1" size="large">
          <ui-loading [small]="true"></ui-loading>
      </ion-button>
  </ng-container>

  <ng-container *ngIf="done">
      <div style="padding: 100px 0 50px 0;">
          <h2>Geschafft 🥳</h2>

          <h4>Du kannst jetzt von Unternehmen und Personaldienstleistern gefunden werden.</h4>
          <h4>Sollte irgendetwas wichtiges passieren, informieren wir dich selbstverständlich per E-Mail.</h4>
      </div>
      <ui-select class="flex" [items]="doneOpt"></ui-select>

  </ng-container>
</ion-content>