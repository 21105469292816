<!-- <ion-input *ngIf="search" type="text" [(ngModel)]="searchTerm" (ngModelChange)="search()"></ion-input> -->

<div class="searchFieldContainer" *ngIf="custom || search">
    <ion-input type="text" [(ngModel)]="customDefault" (ngModelChange)="textFieldChange()">
    </ion-input>
    <i class="far fa-times" (click)="customDefault='';textFieldChange()"></i>
</div>
<p *ngIf="type === 'checkbox'" class="typeInfo">Es können maximal 5 Optionen gewählt werden</p>
<div class="selectContainer" [class.fullWidth]="fullWidth" [class.radio]="type === 'radio'" [class.allSmall]="allSmall"
    [class.checkbox]="type === 'checkbox'">
    <!-- <div *ngFor="let i of results" class="selectItem" [class.active]="i.active"
        [class.inactive]="(allSmall || (value && (!isArray(value) || value.length>0))) && !i.active"
        (click)="select(i)"> -->
    <div *ngFor="let i of results" class="selectItem" [class.active]="i.active" (click)="select(i)">
        <i *ngIf="i.iconClass && !i.image" [class]="i.iconClass" class="alignEnd"></i>
        <div class="imageContainer" *ngIf="i.image">
            <img [src]="i.image" class="alignEnd" />
        </div>
        <span class="name breakable" [innerHtml]="i.name"></span>
        <span class="info breakable alignStart" *ngIf="i.info">{{i.info}}</span>
    </div>
</div>