<div class="stage-progress-bar" *ngIf="questionsDone && questionsMax">
    <div class="progress-bar">
        <div class="bar" [style.width]="((questionsDone /questionsMax)*75)+'%'"></div>
        <div class="background"></div>
    </div>
</div>
<ion-content #pageTop>
  <app-fit-welcome
    [personalFitSpeed]="personalFitSpeed"
    [(acceptPrivacy)]="acceptPrivacy"
    *ngIf="personalFitSpeed.currentSlide < -1"
  ></app-fit-welcome>
  <div style="text-align: center;" *ngIf="personalFitSpeed.currentSlide > -2">
    <!-- <div class="progress" *ngIf="personalFitSpeed.currentSlide>=0">
        <div class="progress-value"
            [style.width]="((this.personalFitSpeed.slides?personalFitSpeed.currentSlide/this.personalFitSpeed.slides.length:0)*100)*0.75+'%'">
        </div>
    </div> -->
    <div class="logoContainer" *ngIf="personalFitSpeed.currentSlide >= -1">
        <img id="logo" src="/assets/logo/logo_grey.svg" (click)="DataService.logoClick()">
      </div>
    <!-- <div class="progressCounter" *ngIf="personalFitSpeed.currentSlide >= 0">
      <p>Noch maximal</p>
      <h2>{{ fancyTimeFormat(this.timeLeft) }}</h2>
      <p>Minuten</p>
    </div> -->
    <ion-grid>
      <ion-row>
        <ng-container *ngFor="let slide of personalFitSpeed.slides">
          <ion-col
            size="12"
            class="text-center"
            *ngIf="personalFitSpeed.currentSlide == slide.id && slide.typ == 'binary'"
          >
            <h2 [class.rot]="timewarning">
              <i class="far fa-stopwatch"></i> {{ personalFitSpeed.timer }}
            </h2>
          </ion-col>
          <ion-col
            size="12"
            [style.display]="
              personalFitSpeed.currentSlide == slide.id ? 'block' : 'none'
            "
          >
            <app-fit-binary
              *ngIf="slide.typ == 'binary'"
              [slide]="slide"
              (selectAnswer)="selectAnswer(slide, $event)"
            ></app-fit-binary>
            <app-fit-list
              *ngIf="slide.typ == 'list'"
              [slide]="slide"
              (selectAnswer)="selectAnswer(slide, $event)"
            ></app-fit-list>
            <app-fit-matrix-introduction
            *ngIf="slide.typ == 'matrix-introduction'"
            [slide]="slide"
            (next)="selectAnswer(slide, {ok:true})"
          ></app-fit-matrix-introduction>
            <app-fit-matrix
              *ngIf="slide.typ == 'matrix'"
              [slide]="slide"
              (selectAnswer)="selectAnswer(slide, $event)"
            ></app-fit-matrix>
            <app-fit-slider-full
              *ngIf="slide.typ == 'slider-full'"
              [slide]="slide"
              (selectAnswer)="selectAnswer(slide, $event)"
            ></app-fit-slider-full>
            <app-fit-slider-ratio
              *ngIf="slide.typ == 'slider-ratio'"
              [slide]="slide"
              (selectAnswer)="selectAnswer(slide, $event)"
            ></app-fit-slider-ratio>
          </ion-col>
        </ng-container>
        <ion-col size="12" *ngIf="personalFitSpeed.currentSlide == -1">
            <h1>Fast geschafft!</h1>
            Bitte beantworte noch ein paar Fragen zu deiner Person
            <ui-select [items]="personalFitSpeed.weiterButton"></ui-select>
        </ion-col>
      </ion-row>
    </ion-grid>
</div>
</ion-content>
