import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ReplaySubject } from "rxjs";
import { DataServiceStatic } from "./data.service.static";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import { environment } from "src/environments/environment";
import { AlertController } from "@ionic/angular";

export class Constants {
  public static weekDays = [
    {
      id: "mo",
      name: "Montag",
    },
    {
      id: "di",
      name: "Dienstag",
    },
    {
      id: "mi",
      name: "Mittwoch",
    },
    {
      id: "do",
      name: "Donnerstag",
    },
    {
      id: "fr",
      name: "Freitag",
    },
  ];

  public static weekDaysMap = {
    mo: {
      name: "Montag",
    },
    di: {
      name: "Dienstag",
    },
    mi: {
      name: "Mittwoch",
    },
    do: {
      name: "Donnerstag",
    },
    fr: {
      name: "Freitag",
    },
  };

  public static typStellenanzeige = {
    link: { id: "link", name: "Link", icon: "far fa-link" },
    pdf: { id: "pdf", name: "angehängtes Dokument", icon: "far fa-file-pdf" },
    text: { id: "text", name: "Text", icon: "far fa-align-justify" },
    kein: { id: "kein", name: "Keine Stelle", icon: "far fa-ban" },
  };

  public static geschlechter = [
    { id: "m", name: "Männlich", iconClass: "far fa-mars" },
    { id: "w", name: "Weiblich", iconClass: "far fa-venus" },
    { id: "d", name: "Divers", iconClass: "far fa-transgender" },
  ];

  public static bewerber_status = [
    { id: "aktiv", name: "Aktiv", info: "Aktiv auf Jobsuche" },
    {
      id: "latent",
      name: "Latent",
      info: "Nicht aktiv auf der Suche, aber offen für Anfragen",
    },
    { id: "inaktiv", name: "Inaktiv", info: "Aktuell nicht auf der Suche" },
  ];

  public static bewerber_privacy = [
    {
      id: "immer",
      name: "Immer",
      info: "Ab Suche",
      iconClass: "far fa-shield-alt",
    },
    {
      id: "nach_annahme",
      name: "Nach Annahme",
      info: "Daten sind erst nach Annahme einer Anfrage sichtbar",
      iconClass: "far fa-shield",
    },
  ];

  public static user_typ = [
    { id: "bewerber", name: "Bewerber", info: "Bewerber auf Jobsuche" },
    {
      id: "pdl",
      name: "Personaldienstleister",
      info: "Personaldienstleister zur Verwaltung mehrerer Bewerber",
    },
    {
      id: "unternehmen",
      name: "Unternehmen",
      info: "Unternehmen auf Suche nach Bewerbern",
    },
  ];

  public static typRankParticipant = {
    teamleader: {
      id: "teamleader",
      name: "Teamleiter",
      iconClass: "far fa-phone-square",
    },
    headofdepartment: {
      id: "headofdepartment",
      name: "Abteilungsleiter",
      iconClass: "far fa-globe",
    },
    areamanager: {
      id: "areamanager",
      name: "Bereichsleiter",
      iconClass: "far fa-map-marker-alt",
    },
  };

  public static SEARCH_QUERY_LIMIT = 10;
  public static USER_COLLECTION = environment.production ? "users" : "usersDev";
  public static REQUEST_COLLECTION = "request";
}

@Injectable({
  providedIn: "root",
})
export class DataService {
  // public geofirex = geofirex.init(firebaseApp)
  public constants = Constants;

  public static;
  public staticQuestions;
  public staticMap;
  public tooltips;
  public static staticMap;
  public skill_categories: any[];
  public skillsMap: { [key: string]: any } = {};
  public levelsMap = {};
  public skipMissing = false;

  public countryMap;
  public countries;

  public countryGeoHashMap;

  public bundeslandMap;
  public bundeslaender;

  public bundeslandGeoHashMap;

  public staticLoad = new ReplaySubject<any>(1);
  public staticQuestionsLoad = new ReplaySubject<any>(1);
  static skill_categories: any;
  public missing = new ReplaySubject<{
    total: number;
    fit: any[];
    personal: any[];
  }>();

  // public googleMapsClient = new Client({});
  public isMobile;
  public isMobileObservable = new ReplaySubject(1);
  darkMode;
  public isKioskTablet;
  public isKarriereheldUser;

  constructor(
    public firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private angularFireFunctions: AngularFireFunctions,
    public alertController: AlertController
  ) {
    this.loadQuestions();
    this.loadStatic();
    DataServiceStatic.Constants = Constants;
    // this.enableGoogle()
  }

  // private enableGoogle() {
  //   if (!google) {
  //     setTimeout(() => this.enableGoogle(), 500)
  //     return
  //   }
  //   geocoder = new google.maps.Geocoder();
  // }

  //REMOVE when .flat() is used

  async initAuth() {
    this.angularFireAuth.onAuthStateChanged((currentUser) => {
      // const currentUser = await this.angularFireAuth.currentUser;
      console.log(currentUser);
      if (currentUser) {
        currentUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            if (!!idTokenResult.claims.karriereheld) {
              console.log("karriereheld", idTokenResult);
              this.isKarriereheldUser = true;
              this.getMissingQuestions();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.angularFireAuth
          .signInAnonymously()
          .then(async (user) => {
            console.log("login success", user);
            await this.getMissingQuestions();
            // console.log(use)
          })
          .catch((error) => {
            console.log("login fail");
          });
      }
    });
  }

  logoClickCounter = 0;
  logoClick() {
    this.logoClickCounter++;
    console.log("count");
    if (this.logoClickCounter > 4) {
      if (this.isKioskTablet) {
        this.nochDaAlert();
      } else {
        this.easterEggAlert();
      }
    }

    if (this.logoClickCounter > 10) {
      this.logoClickCounter = 0;
    }
  }

  private nochDaAlertAlert;
  nochDaAlert = (byLogo = false) =>
    new Promise(async (resolve) => {
      if (!this.nochDaAlertAlert) {
        this.nochDaAlertAlert = await this.alertController.create({
          // cssClass: 'my-custom-class',
          header: "Bist du noch da?🧐",
          buttons: [
            {
              text: "Nein, neu starten",
              role: "cancel",
              cssClass: "secondary",
              handler: async () => {
                await this.savePersonalData(
                  "abort",
                  "byAlert" + byLogo ? "-byLogo" : ""
                );
                this.restart();
                resolve(false);
              },
            },
            {
              text: "Ja!",
              handler: () => {
                this.nochDaAlertAlert = null;
                resolve(true);
              },
            },
          ],
        });

        await this.nochDaAlertAlert.present();
      }
    });

  private easterEggAlertAlert;
  easterEggAlert = (byLogo = false) =>
    new Promise(async (resolve) => {
      if (!this.easterEggAlertAlert) {
        this.easterEggAlertAlert = await this.alertController.create({
          header: "Gefunden 🎉🎉🎉",
          message: "Komm im Büro vorbei und hol dir nen Snack 😉",
          buttons: [
            {
              text: "Nice, ich bin gleich bei euch!",
              handler: () => {
                this.easterEggAlertAlert = null;
                resolve(true);
              },
            },
          ],
        });

        await this.easterEggAlertAlert.present();
      }
    });

  restart() {
    this.angularFireAuth.signOut();
    window.location.href = "/";
  }

  private flattenDeep = (arr1) =>
    arr1.reduce(
      (acc, val) =>
        Array.isArray(val)
          ? acc.concat(this.flattenDeep(val))
          : acc.concat(val),
      []
    );

  private loadStatic = () => {
    this.firestore
      .doc("static/app")
      .get()
      .subscribe((ref) => {
        let data: any = ref.data();
        this.static = data;
        this.tooltips = {};
        // console.log(this.static)

        this.staticMap = {};
        for (let key in this.static) {
          if (Array.isArray(this.static[key]) && key != "skill_categories") {
            this.staticMap[key] = this.arrayToObject(this.static[key]);
            this.tooltips[key] = this.static[key]
              .map((k) => `${k.name}: ${k.info}`)
              .join("<br/>");
          } else if (key == "stellenbezeichnungen") {
            // this.staticMap[key] = this.arrayToObject(Object.values(this.static[key]).flat())  //https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
            this.staticMap[key] = this.arrayToObject(
              this.flattenDeep(Object.values(this.static[key]))
            );
            this.static.stellenbezeichnungen_list = this.flattenDeep(
              Object.values(this.static[key])
            );
          }
        }
        // console.log(this.staticMap)

        this.skill_categories = data.skill_categories;
        for (let category of this.skill_categories ?? []) {
          if (category.sub_categories) {
            for (let sub_category of category.sub_categories) {
              if (sub_category.skills) {
                this.handleSkills(sub_category.skills, category, sub_category);
              }
            }
          }
          if (category.skills) {
            this.handleSkills(category.skills, category);
          }
        }

        DataService.staticMap = this.staticMap;
        DataServiceStatic.staticMap = this.staticMap;

        this.staticLoad.next(this.static);
      });
  };

  public loadQuestions = () => {
    this.firestore
      .doc("static/questions")
      .get()
      .subscribe((ref) => {
        let data: any = ref.data();
        console.log("DATA", data);
        this.staticQuestions = data["questions"];
        this.tooltips = {};
        // console.log(this.staticQuestions)

        console.log(data);
        console.log(data["questions"]);
        const images = [];
        for (let question of data["questions"]) {
          // console.log(question.question);
          if (question.answers) {
            for (let a of question.answers) {
              if (a.image) {
                images.push(a.image);
              }
            }
          }
        }

        console.log("images", images);
        this.staticQuestionsLoad.next(this.staticQuestions);
        this.preload(images);
      });
  };

  preload(images) {
    const temp = [];
    for (let i = 0; i < images.length; i++) {
      temp[i] = new Image();
      temp[i].src = images[i];
    }
  }

  private handleSkills(skills, category, sub_category?) {
    for (let skill of skills) {
      if (skill.id) {
        if (skill.levels) {
          for (let level of skill.levels) {
            if (level.id) this.levelsMap[level.id] = level;
          }
        } else if (sub_category?.levels) {
          this.handleLevels(skill, sub_category.levels);
        } else if (category?.levels) {
          this.handleLevels(skill, category.levels);
        }
        this.skillsMap[skill.id] = { ...skill, category: category };
        if (sub_category) {
          this.skillsMap[skill.id].sub_category = {
            id: sub_category.id,
            name: sub_category.name,
          };
        }
        if (skill.sub_skills) {
          for (let sub_skill of skill.sub_skills) {
            this.levelsMap[sub_skill.id] = sub_skill;
          }
        }
      }
    }
  }

  private handleLevels(skill, levels) {
    skill.levels = [];
    for (let level of levels) {
      let lvl = this.deepClone(level);
      lvl.id = `${skill.id}_${lvl.id}`;
      this.levelsMap[lvl.id] = lvl;
      skill.levels.push(lvl);
    }
  }

  public async getMissingQuestions() {
    const missing = await this.angularFireFunctions
      .httpsCallable("getMissingQuestions")({})
      .toPromise();
    this.missing.next(missing);
    return this.missing;
  }

  getUserId = (): Promise<string> =>
    new Promise(async (resolve) => {
      let user = await this.angularFireAuth.currentUser;
      if (!user.uid) {
        user = (await this.angularFireAuth.signInAnonymously())?.user;
      }
      return resolve(user.uid);
    });

  public async saveFitQuestion(QuestionKey, value, time) {
    const id = await this.getUserId();

    var me = this.firestore.collection(Constants.USER_COLLECTION).doc(id);

    me.set(
      { fit: { [QuestionKey]: { antwort: value, zeit: time } } },
      { merge: true }
    );
  }

  public async savePersonalData(key, value) {
    const id = await this.getUserId();

    var me = this.firestore.collection(Constants.USER_COLLECTION).doc(id);

    me.set({ personal: { [key]: value } }, { merge: true });
  }

  public async setDone() {
    const id = await this.getUserId();
    if (this.isKioskTablet) {
      await this.savePersonalData("tablet", true);
    }

    var me = this.firestore
      .collection(Constants.USER_COLLECTION)
      .doc(id)
      .collection("map")
      .doc("done");
    me.set({ done: true }, { merge: true });
  }

  //GEOCODE
  // getLocation = (term: string): Promise<any> =>
  //   new Promise((resolve, reject) => {
  //     geocoder.geocode({ 'address': term }, function (results, status) {
  //       if (status === 'OK' && results[0]) {
  //         resolve(results[0])
  //       } else {
  //         reject(results)
  //       }
  //     })
  //     // this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${term}&key=${environment.GOOGLE_MAPS_API_KEY}`).pipe(take(1))
  //     //   .subscribe(
  //     //     (res: any) => {
  //     //       console.log(res)
  //     //       return resolve(((res?.status == 'OK') ? res.results[0] : null))
  //     //     },
  //     //     error => reject(error)
  //     //   )
  //   })

  // HELPERS
  public json_encode = (d) => JSON.stringify(d);

  public arrayToObject = (array: any[], newClass?) =>
    array.reduce((obj, item) => {
      // obj[item.id] = newClass ? Object.assign(new newClass, item) : item
      obj[item.id] = newClass ? new newClass(item) : item;
      return obj;
    }, {});

  public deepClone = (d) => {
    return JSON.parse(JSON.stringify(d));
  };
  public deepCloneFast = this.deepClone;

  public datePicker = {
    monthYear: {
      mode: "month",
    },
  };

  public datePickerConfig = {
    appendTo: "body",
    locale: "de",
    format: "MMMM, YYYY",
  };
}
