export default [
  {
    questionID: "lieblingsrestautrant|neueröffnung",
    question: "Was ist dir lieber?",
    answers: [
      {
        id: "lieblingsrestautrant",
        name: "Lieblingsrestaurant",
        iconClass: "far fa-home-heart",
      },
      {
        id: "neueröffnung",
        name: "Neueröffnung",
        iconClass: "far fa-house-return",
      },
    ],
  },
  {
    questionID: "hohesrisikohighreward|nachhaltigeswachstum",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "hohesrisikohighreward",
        name: "Hohes Risiko",
      },
      {
        id: "nachhaltigeswachstum",
        name: "Nachhaltiges<br>Wachstum",
      },
    ],
  },
  {
    questionID: "flexiblearbeitszeiten|definiertearbeitszeiten",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "flexiblearbeitszeiten",
        name: "flexible<br>Arbeitszeiten",
        iconClass: "far fa-calendar-edit",
      },
      {
        id: "definiertearbeitszeiten",
        name: "definierte<br>Arbeitszeiten",
        iconClass: "far fa-calendar-week",
      },
    ],
  },
  {
    questionID: "ordnung|kreativeschaos",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "ordnung",
        name: "Ordnung",
        iconClass: "far fa-box-check",
      },
      {
        id: "kreativeschaos",
        name: "Kreatives Chaos",
        iconClass: "far fa-box-full",
      },
    ],
  },
  {
    questionID: "hemd/bluse|shirt/hoody",
    question: "Was ist dir lieber?",
    question: "Bevorzugte Arbeitskleidung",
    answers: [
      {
        id: "hemd/bluse",
        name: "Hemd/Bluse",
        iconClass: "fab fa-black-tie",
      },
      {
        id: "shirt/hoody",
        name: "Shirt/Hoodie",
        iconClass: "far fa-tshirt",
      },
    ],
  },
  {
    questionID: "essengehen|selbstkochen",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "essengehen",
        name: "Essen gehen",
        iconClass: "far fa-utensils",
      },
      {
        id: "selbstkochen",
        name: "Selbst kochen",
        iconClass: "far fa-hat-chef",
      },
    ],
  },
  {
    questionID: "eigentum|sharing",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "eigentum",
        name: "Eigentum",
        iconClass: "far fa-cart-arrow-down",
      },
      {
        id: "sharing",
        name: "Sharing",
        iconClass: "far fa-share-square",
      },
    ],
  },
  {
    questionID: "frei|reich",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "frei",
        name: "Frei",
        iconClass: "far fa-dove",
      },
      {
        id: "reich",
        name: "Reich",
        iconClass: "far fa-money-check-edit-alt",
      },
    ],
  },
  {
    questionID: "weitermachen|anderewegefinden",
    question: "Was ist dir lieber?",
    question: "Du steckst bei einer Aufgabe fest. Wie gehst du vor?",
    answers: [
      {
        id: "weitermachen",
        name: "weitermachen",
        iconClass: "far fa-repeat",
      },
      {
        id: "anderewegefinden",
        name: "andere Wege finden",
        iconClass: "fal fa-random",
      },
    ],
  },
  {
    questionID: "tagesgeschäft|projekte",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "tagesgeschäft",
        name: "Tagesgeschäft",
        iconClass: "far fa-phone",
      },
      {
        id: "projekte",
        name: "Projekte",
        iconClass: "far fa-scroll",
      },
    ],
  },
  {
    questionID: "verhandlung|harmonie",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "verhandlung",
        name: "Verhandlung",
        iconClass: "far fa-comments",
      },
      {
        id: "harmonie",
        name: "Harmonie",
        iconClass: "far fa-yin-yang",
      },
    ],
  },
  {
    questionID: "herausforderungen|einfachelösungen",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "herausforderungen",
        name: "Herausforderungen",
        iconClass: "far fa-integral",
      },
      {
        id: "einfachelösungen",
        name: "Einfache Lösungen",
        iconClass: "far fa-sigma",
      },
    ],
  },
  {
    questionID: "zahlen|sprache",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "zahlen",
        name: "Zahlen",
        iconClass: "far fa-function",
      },
      {
        id: "sprache",
        name: "Sprache",
        iconClass: "far fa-language",
      },
    ],
  },
  {
    questionID: "@home|unterwegs",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "@home",
        name: "@home",

        image: "./assets/imgPoll/home.jpg",
      },
      {
        id: "unterwegs",
        name: "unterwegs",

        image: "./assets/imgPoll/unterwegs.jpg",
      },
    ],
  },
  {
    questionID: "earlybird|nightowl",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "earlybird",
        name: "Early Bird",

        image: "./assets/imgPoll/earlybird.jpg",
      },
      {
        id: "night owl",
        name: "Night Owl",

        image: "./assets/imgPoll/nightowl.jpg",
      },
    ],
  },
  {
    questionID: "fokusaufkarriere|fokusfamilie/freizeit",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "fokusaufkarriere",
        name: "Fokus<br>auf Karriere",
        iconClass: "far fa-briefcase",
      },
      {
        id: "fokusfamilie/freizeit",
        name: "Fokus<br>Familie/Freizeit",
        iconClass: "far fa-volleyball-ball",
      },
    ],
  },
  {
    questionID: "visionär|bewahrend/traditionell",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "visionär",
        name: "Visionär",
        iconClass: "fab fa-bitcoin",
      },
      {
        id: "bewahrend/traditionell",
        name: "Bewahrend/<br>Traditionell",
        iconClass: "far fa-euro-sign",
      },
    ],
  },
  {
    questionID: "zeiterfassung|vertrauensarbeitzeit",
    question: "Was ist dir lieber?",
    question: "Unter welchen Bedingungen arbeitest du lieber?",
    answers: [
      {
        id: "zeiterfassung",
        name: "Zeiterfassung",
        iconClass: "far fa-stopwatch",
      },
      {
        id: "vertrauensarbeitzeit",
        name: "Vertrauensarbeitzeit",
        iconClass: "far fa-user-clock",
      },
    ],
  },
  {
    questionID: "sozialeverantwortung|wirtschaftlichererfolg",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "sozialeverantwortung",
        name: "Soziale<br>Verantwortung",
        iconClass: "far fa-hand-holding-seedling",
      },
      {
        id: "wirtschaftlichererfolg",
        name: "Wirtschaftlicher<br>Erfolg",
        iconClass: "far fa-chart-line",
      },
    ],
  },
  {
    questionID: "dasgroßeganze|hierundjetzt",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "dasgroßeganze",
        name: "Das große Ganze",
        iconClass: "far fa-circle",
      },
      {
        id: "hierundjetzt",
        name: "Hier und Jetzt",
        iconClass: "far fa-map-marker",
      },
    ],
  },
  {
    questionID: "intuition|wissen",
    question: "Was ist dir lieber?",

    answers: [
      {
        id: "intuition",
        name: "Intuition",
        iconClass: "far fa-stomach",
      },
      {
        id: "wissen",
        name: "Wissen",
        iconClass: "far fa-brain",
      },
    ],
  },
];
