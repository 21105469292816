import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-fit-list",
  templateUrl: "./fit-list.component.html",
  styleUrls: ["./fit-list.component.scss"],
})
export class FitListComponent implements OnInit {
  @Input() slide;
  @Output() selectAnswer = new EventEmitter();

  value = []

  public weiterButton = [
    {
      id: "weiter",
      name: "Weiter",
      iconClass: "far fa-forward",
      onClick: () => {
        this.selectAnswer.emit(this.value.map(x => ({id:x})));
      },
    },
  ];

  change(event){
    console.log(event)
  }

  constructor() {}

  ngOnInit() {}


}
