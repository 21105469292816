<ion-content>
  <div class="logoContainer">
    <img
      id="logo"
      src="/assets/logo/logo_grey.svg"
      (dblclick)="dbl()"
    />
    <p *ngIf="showUserId" style="font-size: 8pt;">{{ userId }}</p>
  </div>
  <ion-grid>
    <!-- <canvas #canvas width="100%" height="100%"></canvas> -->

    <div style="padding-top: 50px" class="text-center">
      <ion-row>
        <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <h1 style="font-size: 48pt;"><i class="far fa-gift"></i> Danke für deine Zeit!</h1>
          <p>Wir hoffen, es hat dir Spaß gemacht 😉</p>
          <!-- <h2 class="text-center">Wie haben dir die Fragen gefallen?</h2> -->
        </ion-col>
        <!-- <ion-col offset="1" size="10" class="slider">
          <ngx-slider
            (userChangeEnd)="likeSliderDone()"
            [(value)]="value"
            [options]="optionsSlider"
          >
          </ngx-slider>
        </ion-col>
        <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <h2 class="text-center">
            Welches Antwortformat hat dir am besten gefallen?
          </h2>
        </ion-col>
        <ion-col size="12" class="slider">
          <div style="margin: 0 -10px">
            <ui-select
              [allSmall]="true"
              [items]="formats"
              [(value)]="favoriteFormat"
              (valueChange)="setFavoriteFormat()"
            ></ui-select>
          </div>
        </ion-col> -->

        <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <p>
            Wir würden uns freuen, wenn du uns in ein paar Worten Feedback geben
            würdest, damit wir den Test in Zukunft angenehmer für alle
            Teilnehmer:innen gestalten können.
          </p>
          <p>Gerne kannst du auch direkt bei uns vorbeikommen.</p>
          <p>Vielen Dank 😉</p>
        </ion-col>
        <ion-col size="12" style="display: flex; justify-content: center">
          <ui-select
            routerLink="/feedback"
            [items]="[
              {
                id: 'feedback',
                name: 'Feedback geben',
                iconClass: 'far fa-comments'
              }
            ]"
          >
          </ui-select>
          <div class="selectContainer">
            <div class="selectItem" style="background-color: white !important">
              <img
                width="90%"
                style="margin: 0 auto"
                src="/assets/img/QRCode_Insta.jpg"
                (click)="openInstagram()"
              />
            </div>
          </div>
        </ion-col>

        <ion-col sizeMd="10" offsetMd="1" size="12">
          Möchtest du bei der weiteren Entwicklung von karriereheld dabei sein
          und weitere Infos erhalten? Dann melde dich hier direkt bei unserem
          Newsletter an.
          <br />Keine Sorge: deine E-Mailadresse kann dem Test nicht zugewiesen
          werden.

          <p>
            <ion-checkbox
              color="primary"
              [(ngModel)]="acceptNewsletter"
            ></ion-checkbox
            >&nbsp;Newsletter erhalten
          </p>
          <ng-container
            *ngIf="acceptGewinnspiel || acceptNewsletter || acceptBeta"
          >
            <ion-input
              [placeholder]="
                'Email' +
                (acceptNewsletter || acceptBeta
                  ? ''
                  : ' | Telefonnummer | Instagram')
              "
              [(ngModel)]="kontaktValue"
              #kontakt
              (keydown.enter)="teilnehmen()"
              (keydown.tab)="teilnehmen()"
            >
            </ion-input>

            <!-- <input type="text"> -->
            <ng-container *ngIf="!loading && !check && kontaktValue != ''">
              <ui-select
                *ngIf="acceptGewinnspiel && !acceptNewsletter && !acceptBeta"
                [items]="teilnehmenButton"
              ></ui-select>
              <ui-select
                *ngIf="!acceptGewinnspiel && (acceptNewsletter || acceptBeta)"
                [items]="teilnehmenButtonMail"
              ></ui-select>
              <ui-select
                *ngIf="acceptGewinnspiel && (acceptNewsletter || acceptBeta)"
                [items]="teilnehmenButtonBeide"
              ></ui-select>
            </ng-container>
            <ui-select
              *ngIf="loading"
              [items]="teilnehmenButtonLoading"
            ></ui-select>
            <ui-select
              *ngIf="check && !loading"
              [items]="teilnehmenButtonCheck"
            ></ui-select>
          </ng-container>

          <ng-container *ngIf="DataService.isKioskTablet">
            <p>Reset in {{ secondsLeft }} Sekunden.</p>
            <ui-select
              (click)="DataService.restart()"
              [items]="[
                {
                  id: 'restart',
                  name: 'Neu starten',
                  iconClass: 'far fa-gift'
                }
              ]"
            ></ui-select>
          </ng-container>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-content>
