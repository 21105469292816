<ion-content>
  <div class="logoContainer">
    <img id="logo" src="/assets/logo/logo_grey.svg" />
    <p *ngIf="showUserId" style="font-size: 8pt">{{ userId }}</p>
  </div>
  <ion-grid>
    <!-- <canvas #canvas width="100%" height="100%"></canvas> -->

    <div style="padding-top: 50px" class="text-center">
      <ion-row>
        <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <h1>
            <!-- <i class="far fa-gift"></i> -->
            Du bist student, Young Professional, Expert, Senior Professional
            oder Teamleader?
          </h1>
          <p>Nimm jetzt an der Beta teil.</p>
        </ion-col>

        <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <ion-input
            placeholder="Email | Telefonnummer | Instagram"
            [(ngModel)]="kontaktValue"
            #kontakt
            (keydown.enter)="teilnehmen()"
            (keydown.tab)="teilnehmen()"
          >
          </ion-input>
          <ng-container *ngIf="!loading && !check">
            <ui-select [items]="teilnehmenButton"></ui-select>
          </ng-container>
          <ui-select
            *ngIf="check && !loading"
            [items]="teilnehmenButtonCheck"
          ></ui-select>
        </ion-col>

        <!-- <ion-col size="12" sizeMd="10" offsetMd="1" style="line-height: 1.5">
          <p>
            Wir würden uns freuen, wenn du uns in ein paar Worten Feedback geben
            würdest, damit wir den Test in Zukunft angenehmer für alle
            Teilnehmer:innen gestalten können.
          </p>
          <p>Gerne kannst du auch direkt bei uns vorbeikommen.</p>
          <p>Vielen Dank 😉</p>
        </ion-col> -->
        <ion-col
          size="12"
          style="display: flex; justify-content: center; margin-top: 48px"
        >
          <div class="selectContainer">
            <div class="selectItem" style="background-color: white !important">
              <img
                width="90%"
                style="margin: 0 auto"
                src="/assets/img/QRCode_Insta.jpg"
                (click)="openInstagram()"
              />
            </div>
          </div>
        </ion-col>

        <!-- <ion-col sizeMd="10" offsetMd="1" size="12">
          Möchtest du bei der weiteren Entwicklung von karriereheld dabei sein
          und weitere Infos erhalten? Dann melde dich hier direkt bei unserem
          Newsletter an.
          <br />Keine Sorge: deine E-Mailadresse kann dem Test nicht zugewiesen
          werden.
        </ion-col> -->
      </ion-row>
    </div>
  </ion-grid>
</ion-content>
