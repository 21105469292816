import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-fit-binary",
  templateUrl: "./fit-binary.component.html",
  styleUrls: ["./fit-binary.component.scss"],
})
export class FitBinaryComponent implements OnInit {
  @Input() slide;
  @Output() selectAnswer = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
