import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fit-matrix-introduction',
  templateUrl: './fit-matrix-introduction.component.html',
  styleUrls: ['./fit-matrix-introduction.component.scss'],
})
export class FitMatrixIntroductionComponent implements OnInit {
  @Input() slide;
  @Output() next = new EventEmitter();

  position;

  public weiterButton = [
    {
      id: "weiter",
      name: "Weiter",
      iconClass: "far fa-forward",
      onClick: () => {
        this.next.emit();
      },
    },
  ];

  constructor() { }

  ngOnInit() {}

}
