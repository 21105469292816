import { Options } from "@angular-slider/ngx-slider";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-fit-slider-full",
  templateUrl: "./fit-slider-full.component.html",
  styleUrls: ["./fit-slider-full.component.scss"],
})
export class FitSliderFullComponent implements OnInit {
  @Input() slide;
  @Output() selectAnswer = new EventEmitter();

  value = 50

  public optionsSliderFull: Options = {
    floor: 0,
    ceil: 100,
    vertical: this.dataService.isMobile,
    translate: (value: number): string => value + " %",
  };

  public weiterButton = [
    {
      id: "weiter",
      name: "Weiter",
      iconClass: "far fa-forward",
      onClick: () => {
        this.selectAnswer.emit([
          {
            value: this.value / 100,
          },
        ]);
      },
    },
  ];

  constructor(public dataService: DataService) {
    this.dataService.isMobileObservable.subscribe((mobile) => {
      const newOptions: Options = Object.assign({}, this.optionsSliderFull);
      newOptions.vertical = this.dataService.isMobile;
      this.optionsSliderFull = newOptions;
    });
  }

  ngOnInit() {}
}
