import { DoneComponent } from "./survey/done/done.component";
import { FitComponent } from "./survey/fit/fit.component";

import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import { DataService } from "./data.service";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ThemeModule } from "./theme/theme.module";
// import { IonicStorageModule } from '@ionic/storage';
import { ToastService } from "./shared/toast.service";
// import { Ng2IziToastModule } from 'ng2-izitoast';
import { NgInitDirective } from "./shared/directives/ngInit.directive";
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from "@angular/fire/performance";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import {
  AngularFireFunctionsModule,
  REGION,
  ORIGIN,
} from "@angular/fire/functions";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { RouteReuseStrategy } from "@angular/router";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";
import {
  DlDateTimeDateModule,
  DlDateTimePickerModule,
  DlDateTimeInputModule,
} from "angular-bootstrap-datetimepicker";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { SurveyComponent } from "./survey/survey/survey.component";
import { UiComponentsModule } from "./ui-components/ui-components.module";
import { SetStaticComponent } from "./static/set-static/set-static.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { PrivacyAgbComponent } from "./privacy/privacy-agb/privacy-agb.component";
import { PrivacyImprintComponent } from "./privacy/privacy-imprint/privacy-imprint.component";
import { PrivacyParticipationComponent } from "./privacy/privacy-participation/privacy-participation.component";
import { FeedbackComponent } from "./survey/feedback/feedback.component";
import { TestingComponent } from "./survey/testing/testing.component";
import { FitModule } from "./survey/fit/fit.module";
import { CounterComponent } from "./counter/counter.component";
import { BetaComponent } from "./beta/beta.component";

@NgModule({
  declarations: [
    AppComponent,
    NgInitDirective,
    SurveyComponent,
    SetStaticComponent,
    // FitComponent,
    DoneComponent,
    TestingComponent,
    PrivacyAgbComponent,
    PrivacyImprintComponent,
    PrivacyParticipationComponent,
    FeedbackComponent,
    CounterComponent,
    BetaComponent,
  ],
  entryComponents: [SurveyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    // IonicStorageModule.forRoot(),
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ThemeModule,
    NgSelectModule,
    UiComponentsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    DlDateTimeDateModule,
    DlDateTimeInputModule,
    DlDateTimePickerModule,
    NgxSliderModule,
    FitModule,
    IonicModule,
    // AngularFirestoreModule.enablePersistence(),
  ],
  providers: [
    ToastService,
    DataService,
    PerformanceMonitoringService,
    StatusBar,
    AngularFirestore,
    ScreenTrackingService,
    UserTrackingService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: REGION, useValue: "europe-west3" },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    SplashScreen,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
