<h1 *ngIf="slide.heading">{{ slide.heading }}</h1>
<h2 *ngIf="slide.question">{{ slide.question }}</h2>

<div style="margin: 0 -10px">
  <ui-select
    [items]="slide.answers"
    [(value)]="value"
    (valueChange)="change($event)"
    type="checkbox"
    maxCheckbox="5"
    [allSmall]="true"
  ></ui-select>
</div>

<ui-select *ngIf="value && value.length > 0" [items]="weiterButton"></ui-select>
