import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-fit-welcome",
  templateUrl: "./fit-welcome.component.html",
  styleUrls: ["./fit-welcome.component.scss"],
})
export class FitWelcomeComponent implements OnInit {
  @Input() personalFitSpeed;
  loading;
  @Input() acceptPrivacy;
  @Output() acceptPrivacyChange = new EventEmitter();

  constructor(private dataService: DataService) {}

  ngOnInit() {}

  accept(event) {
    console.log("Accept");
    this.acceptPrivacyChange.next(event);
  }

  showDatenschutz = false;
  openDatenschutz() {
    if (this.dataService.isKioskTablet) {
      this.showDatenschutz = !this.showDatenschutz;
    } else {
      window.open("https://teamfit.karriereheld.team/agb", "_blank");
    }
  }
}
