<h1 *ngIf="slide.heading" class="pb">{{ slide.heading }}</h1>
<h2
  style="font-weight: 400 !important"
  *ngIf="slide.question"
  class="pb"
  [innerHTML]="slide.question"
></h2>

<div class="mx-auto pb">
  <ui-matrix
    [id]="slide.id"
    (positionChange)="position = $event"
    [textX]="slide.answers[0].name"
    [textY]="slide.answers[1].name"
  ></ui-matrix>
</div>

<ui-select [items]="weiterButton"></ui-select>
