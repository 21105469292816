import { Component, HostListener, OnInit } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import "moment/locale/de";
import { AngularFireAuth } from "@angular/fire/auth";
import { DataService } from "./data.service";
import { ActivatedRoute } from "@angular/router";
moment.locale("de");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "karriereheld";
  private wasOffline = false;

  constructor(
    public toastController: ToastController,
    public dataService: DataService,
    private route: ActivatedRoute
  ) {
    // this.AuthService.initAuth()
    this.dataService.isMobile =
      window.screen.width < 568 || window.screen.width < window.screen.height;
    this.dataService.isMobileObservable.next(this.dataService.isMobile);

    this.dataService.darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches


    this.dataService.initAuth();
    this.toastInit();

    window.addEventListener("online", async (e) => {
      console.log("online");
      if (this.wasOffline) {
        const toast = await this.toastController.create({
          color: "success",
          position: "top",
          duration: 2000,
          header: "Internetverbindung wiederhergestellt 😍",
        });
        toast.present();
      }
      this.wasOffline = false;
    });
    window.addEventListener("offline", async (e) => {
      console.log("offline");
      const toast = await this.toastController.create({
        color: "danger",
        position: "top",
        duration: 4000,
        header: "Oh nein! Keine Internetverbindung gefunden.",
        message:
          "Bitte verbinde dich wieder mit dem Internet, sonst können wir nicht weitermachen...",
      });
      toast.present();
      this.wasOffline = true;
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.dataService.isMobile = event.target.innerWidth < 568 || event.target.innerWidth<event.target.innerHeight;
    this.dataService.isMobileObservable.next(this.dataService.isMobile);
  }

  async toastInit() {
    const toast = await this.toastController.create({
      duration: 1,
      header: "",
      cssClass: "hidden",
    });
    toast.present();
  }

  ngOnInit() {
    this.dataService.isKioskTablet =
      localStorage.getItem("isKioskTablet") == "true";
    console.log("kioskCookie", this.dataService.isKioskTablet);
    if (!this.dataService.isKioskTablet) {
      this.route.queryParams.subscribe((params) => {
        this.dataService.isKioskTablet =
          params.tablet !== undefined ? true : false;

        if (this.dataService.isKioskTablet) {
          localStorage.setItem("isKioskTablet", "true");
        }
      });
    }
  }
}
