import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiLoadingComponent } from "./ui-loading/ui-loading.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import { UiTooltipComponent } from "./ui-tooltip/ui-tooltip.component";
import { UiSelectComponent } from "./ui-select/ui-select.component";
import { UiMatrixComponent } from "./ui-matrix/ui-matrix.component";

const decExp = [
  UiLoadingComponent,
  UiTooltipComponent,
  UiSelectComponent,
  UiMatrixComponent,
];

@NgModule({
  declarations: decExp,
  imports: [CommonModule, IonicModule, FormsModule],
  exports: decExp,
})
export class UiComponentsModule {}
