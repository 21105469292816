
<h2 style="font-weight: 400 !important" *ngIf="slide.question" class="pb">
Das nächste Antwortformat ist ein klein wenig komplizierter als die zuvor. 
</h2>

<p>Du erhältst gleich eine Matrixdarstellung mit zwei Achsen. Deinen Punkt kannst du in dieser Matrix komplett frei bewegen. Damit kannst du deine Antwort ganz einfach auf beide Skalen aufteilen.</p>
<p>Probiers einfach direkt mal aus!</p>


<ui-select [items]="weiterButton"></ui-select>
