export default [
  {
    questionID: "sr_dreimonateimunternehmen",
    question:
      "Du bist seit 3 Monaten in deinem neuen Unternehmen.<br/>Wie sollen dich deine Kolleg:innen nach dieser Zeit eher sehen?",
    answers: [
      {
        id: "puenktlichfehlerfrei",
        name: "Er/sie liefert immer pünktlich und fehlerfrei ab.",
      },
      {
        id: "denktumdieecke",
        name: "Er/sie denkt um die Ecke und bringt neue Ideen!",
      },
    ],
  },
  {
    questionID: "sr_neuesteam",
    question:
      "Du wechselst in ein neues Team.<br/>Was möchtest du am Ende der ersten Woche eher erreicht haben?",
    answers: [
      {
        id: "gutverstehen",
        name: "Ich möchte mich mit allen gut verstehen und angefreundet haben. ",
      },
      {
        id: "zeigenwasichdraufhabe",
        name: "Ich möchte erste Ergebnisse liefern und zeigen, was ich fachlich drauf ab.",
      },
    ],
  },
  {
    questionID: "sr_erstertag",
    question:
      "Dein erster Tag im neuen Job steht vor der Tür.<br/>Du hast keine Information, was dich heute erwartet. Was geht dir eher durch den Kopf?",
    answers: [
      {
        id: "vorbereitet",
        name: "Ich bin auf alles vorbereitet: Anfahrtsbeschreibung, Organigramm und Finanzbericht vom letzten Jahr sind eingepackt!",
      },
      {
        id: "egalwaskommt",
        name: "Infos im Voraus brauch ich nicht. Egal was kommt, ich weiß, ich bekomme das hin!",
      },
    ],
  },
  {
    questionID: "sr_bisherigeteamerfahrungen",
    question:
      "Wenn ich an meine bisherigen Teamerfahrungen denke,<br/> glaube ich, meine Kolleg:innen sagen eher über mich:",
    answers: [
      {
        id: "gutgelaunt",
        name: "Was eine gut gelaunte Person! So viel Optimismus hab ich selten gesehen!",
      },
      {
        id: "bemuehtjedemgut",
        name: "Toll, wie er/sie bemüht ist, dass es jedem im Team gut geht!",
      },
    ],
  },
  {
    questionID: "sr_dritteermahnung",
    question:
      "Dein:e Vorgesetzte:r ermahnt dich zum 3. Mal, dass du Arbeitsunterlagen falsch ablegst.<br/>Du bist dir aber sicher, dass du es richtig machst. Was passiert jetzt eher?",
    answers: [
      {
        id: "akzeptiere",
        name: "Ich akzeptiere es und werde es zukünftig so machen, wie er/sie es möchte.",
      },
      {
        id: "sprecheunddiskutiere",
        name: "Ich spreche es offen an und diskutiere darüber, wenn es sein muss.",
      },
    ],
  },
  {
    questionID: "sr_diskussionprojekt",
    question:
      "Dein Team und du diskutiert über ein Projekt, das dir sehr gefällt.<br/>Was passt besser zu dir?",
    answers: [
      {
        id: "bezieheteamein",
        name: "Ich beziehe auch die Teammitglieder ins Gespräch ein, die von sich aus nichts sagen, und frage nach Meinungen und Ideen.",
      },
      {
        id: "eigenemeinungdeutlich",
        name: "Ich mache meine eigene Motivation deutlich und reiße die anderen mit meiner Begeisterung mit!",
      },
    ],
  },
  {
    questionID: "sf_schnelleentscheidung",
    question:
      "Wie gehst du eher vor,<br/>wenn deine Kolleg:in eine schnelle Entscheidung von dir braucht?",
    answers: [
      {
        id: "faktenorientiert",
        name: "Faktenorientiert",
        iconClass: "far fa-chart-scatter",
      },
      {
        id: "bauchgefuehl",
        name: "Bauchgefühl",
        iconClass: "far fa-heart",
      },
    ],
  },
];
