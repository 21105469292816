import { Options } from "@angular-slider/ngx-slider";
import { DataService } from "../../data.service";

import { isPlatformBrowser } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from "@angular/core";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { AngularFireFunctions } from "@angular/fire/functions";
import { ToastService } from "src/app/shared/toast.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: "app-done",
  templateUrl: "./done.component.html",
  styleUrls: ["./done.component.scss"],
})
export class DoneComponent implements OnInit {
  canvas: HTMLCanvasElement;
  confettiCanvas;
  confettiLib;
  celebrate$: Observable<any>;
  destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  check = false;

  acceptNewsletter;
  acceptGewinnspiel;
  acceptBeta;
  loading = false;
  public kontaktValue = "";

  secondsLeft;
  secondsInterval;

  platformID;

  showUserId = false;
  clickCount = 0;

  userId;

  dbl(){
    this.showUserId = !this.showUserId
    this.DataService.getUserId().then(res => this.userId = res)
  }

  constructor(
    // @Inject(PLATFORM_ID) private platformID: Object,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private angularFireFunctions: AngularFireFunctions,
    private toastService: ToastService,
    public DataService: DataService,
    public angularFireAuth: AngularFireAuth
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.secondsInterval) {
          console.log("Clear");
          clearInterval(this.secondsInterval);
        }
      }
    });
  }

  ngOnInit(): void {
    this.DataService.isMobileObservable.subscribe((mobile) => {
      const newOptions: Options = Object.assign({}, this.optionsSlider);
      newOptions.vertical = this.DataService.isMobile;
      this.optionsSlider = newOptions;
    });



    if (this.DataService.isKioskTablet) {
      setTimeout(() => {
        console.log("Tab");
        this.secondsLeft = 90;
        this.secondsInterval = setInterval(() => {
          this.secondsLeft--;
          console.log("count", this.secondsLeft);
          if (this.secondsLeft === 0) {
            clearInterval(this.secondsInterval);
            this.DataService.restart();
          }
        }, 1000);
      }, 100);
    }

    if (isPlatformBrowser(this.platformID)) {
      console.log("GOGO");
      this.celebrate();
    }

    this.DataService.missing.subscribe((res: any) => {
      if (res && !this.DataService.skipMissing) {
        console.log(res);
        if (res.total > 1) {
          if (res.fit.length == 0) {
            this.router.navigate(["/demographic"]);
          } else {
            this.router.navigate(["/"]);
          }
        }
      }
    });
  }

  openInstagram() {
    if (!this.DataService.isKioskTablet) {
      window.open("https://instagram.com/karriereheld.team", "_blank");
    }
  }

  public likeSliderDone() {
    this.DataService.savePersonalData("like", this.value);
  }

  setFavoriteFormat() {
    this.DataService.savePersonalData("favoriteFormat", this.favoriteFormat);
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
  async importCanvas(): Promise<any> {
    this.confettiLib = await import("canvas-confetti");
    this.canvas = this.renderer2.createElement("canvas");
  }

  teilnehmenButton = [
    {
      id: "teilnehmen",
      name: "Teilnehmen",
      iconClass: "far fa-gift",
      onClick: () => {
        this.teilnehmen();
      },
    },
  ];

  teilnehmenButtonMail = [
    {
      id: "teilnehmen",
      name: "Eintragen",
      iconClass: "far fa-gift",
      onClick: () => {
        this.teilnehmen();
      },
    },
  ];
  teilnehmenButtonBeide = [
    {
      id: "teilnehmen",
      name: "Teilnehmen & Eintragen",
      iconClass: "far fa-gift",
      onClick: () => {
        this.teilnehmen();
      },
    },
  ];

  teilnehmenButtonLoading = [
    {
      id: "teilnehmen",
      name: "Senden...",
      iconClass: "far fa-spinner-third fa-spin",
    },
  ];

  teilnehmenButtonCheck = [
    {
      id: "teilnehmen",
      name: "Du nimmst teil",
      iconClass: "far fa-check",
    },
  ];

  async teilnehmen() {
    this.loading = true;
    const enter = await this.angularFireFunctions
      .httpsCallable("enterContest")({
        acceptNewsletter: this.acceptNewsletter,
        acceptBeta: this.acceptBeta,
        acceptGewinnspiel: this.acceptGewinnspiel,
        mail: this.kontaktValue,
      })
      .toPromise()
      .catch((err) => {
        console.log("error", err);
        this.loading = false;
        this.toastService.error({ title: err });
      });
    this.loading = false;
    if (enter.error) {
      this.toastService.error({ title: enter.error });
      if (enter.error == "Du hast bereits teilgenommen") {
        this.check = true;
      } else if (
        enter.error == "Es müssen zuerst alle Fragen beantwortet werden"
      ) {
        this.router.navigate(["/"]);
      }
    } else if (enter.success) {
      this.toastService.success({
        title: "Du wurdest eingetragen. Vielen Dank",
      });
      this.check = true;
    }
    console.log("TEILNEHMEN", enter);
  }

  favoriteFormat;
  formats = [
    {
      id: "matrix",
      name: "Matrix Auswahl",
      iconClass: "far fa-border-style fa-rotate-270",
    },
    {
      id: "slider-full",
      name: "0-100% zutreffend",
      iconClass: "far fa-arrows-alt-h",
    },
    {
      id: "slider-ratio",
      name: "100% aufteilen",
      iconClass: "far fa-balance-scale-right",
    },
    { id: "binary", name: "Entweder-Oder", iconClass: "far fa-bring-forward" },
    {
      id: "list",
      name: "Mehrfachauswahl",
      iconClass: "far fa-grip-horizontal",
    },
  ];

  public value: number = 5;
  public optionsSlider: Options = {
    showTicksValues: true,
    vertical: this.DataService.isMobile,
    stepsArray: [
      { value: 1, legend: "Gar nicht" },
      { value: 2 },
      { value: 3, legend: "Okay" },
      { value: 4 },
      { value: 5, legend: "Normal" },
      { value: 6 },
      { value: 7, legend: "Gut" },
      { value: 8 },
      { value: 9, legend: "Sehr" },
    ],
  };

 

  celebrate(): void {
    console.log("clelebrate");
    let checkCanvasConfettiExists = async () => Promise.resolve(); // set this to resolve regardless if confetti already exists
    if (!this.confettiCanvas) {
      // if not already imported, replace with importing function
      checkCanvasConfettiExists = this.importCanvas;
    }
    checkCanvasConfettiExists
      .call(this) // bind to 'this' as the importCanvas function will need the correct 'this'
      .then(() => {
        this.renderer2.appendChild(this.elementRef.nativeElement, this.canvas); // append the canvas

        this.confettiCanvas = this.confettiLib.create(this.canvas, {
          resize: true,
        }); // create the confetti canvas
        const end = Date.now() + 5 * 1000; // set the end time
        const interval = setInterval(() => {
          if (Date.now() > end) {
            // if time reached then clear the interval
            clearInterval(interval);

            return; //this.renderer2.removeChild(this.elementRef.nativeElement, this.canvas); // remove the canvas from the DOM
          }
          var colors = ["#FF1D3F", "#FFB400", "#FFFFFF"];

          this.confettiCanvas({
            // if time hasn't passed then call the start the confetti
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            shapes: ["square", "circle"],
            colors: colors,
            origin: {
              x: Math.random(),
              // since they fall down, start a bit higher than random
              y: Math.random() - 0.2,
            },
          });
        }, 500);
      });
  }
}
