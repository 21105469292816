<!-- <ion-app>
    <ion-router-outlet></ion-router-outlet>
</ion-app> -->

<ion-app>
    <!-- <div class="menu">
        <app-theme-sidebar></app-theme-sidebar>
    </div> -->


    <!-- <ion-content class="content"> -->
    <ion-content>
        <!-- <ion-grid>
            <ion-row style="position: absolute; top:0; bottom:0; left:0; right:0;">
                <ion-col size-md="11" offset-md="1">
                    <ion-content> -->
        <ion-router-outlet></ion-router-outlet>
        <!-- <div class="space"></div> -->
        <!-- </ion-content>
                </ion-col>
            </ion-row>
        </ion-grid> -->
        <a *ngIf="!dataService.isKioskTablet" class="imprint" href="https://teamfit.karriereheld.team/imprint" target="_blank">Impressum</a>
    </ion-content>
</ion-app>


<!-- <ion-tabs>
    <ion-tab-bar slot="bottom">
        <ion-tab-button tab="search">
            <ion-icon name="calendar"></ion-icon>
            <ion-label>Schedule</ion-label>
            <ion-badge>6</ion-badge>
        </ion-tab-button>

        <ion-tab-button tab="user">
            <ion-icon name="person-circle"></ion-icon>
            <ion-label>Speakers</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="user/steps">
            <ion-icon name="map"></ion-icon>
            <ion-label>Map</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="about">
            <ion-icon name="information-circle"></ion-icon>
            <ion-label>About</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs> -->