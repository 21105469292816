export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCtaPg0wtX4GAXivVDMKrPSMK67Jvn9F3o",
    authDomain: "karriereheld-poll-bewerber.firebaseapp.com",
    projectId: "karriereheld-poll-bewerber",
    storageBucket: "karriereheld-poll-bewerber.appspot.com",
    messagingSenderId: "745276255251",
    appId: "1:745276255251:web:4a45f59925918c5ebb63e6",
  },
};
