<ion-grid>
  <ion-row>
    <ng-container *ngIf="personalFitSpeed.currentSlide == -2">
      <ion-col sizeMd="5" offsetMd="1" size="6">
        <img
          id="logo"
          src="/assets/logo/logo_color.svg"
          width="200px"
          class="alignEnd"
        />
      </ion-col>
      <!-- <ion-col size="6" sizeMd="5" class="amazonContainer">
              <div class="amazon" *ngFor="let i of [1,1,1]">
                  <img src="/assets/img/amazon_giftcard.png" />
              </div>
          </ion-col> -->
      <ion-col sizeMd="10" offsetMd="1" size="12" style="line-height: 1.5">
        <h2>building.perfect.teams – genau daran arbeiten wir gerade!</h2>

        <h2>Hilf uns dabei, unseren Schnell-Test noch besser zu machen</h2>
        <p>
          Mit deiner Hilfe finden wir künftig den Perfect Match – den richtigen
          Job und das passende Team für jede Kandidatin und jeden Kandidaten!
        </p>
        <p>Und das ganz einfach und superschnell.</p>
        <p>
          Mit einem Klick gehts los – dieser Test dauert maximal
          8 Minuten.
        </p>

        <p>
          Beantworte die Fragen komplett intuitiv und ehrlich – es gibt kein
          „Richtig“ oder „Falsch“ und auch kein „Schlechter“ oder „Besser“.
        </p>

        <h6>Vielen Dank und viel Spaß 😄</h6>

        <p class="pb">
          <ion-checkbox
            color="primary"
            [(ngModel)]="acceptPrivacy"
            (ngModelChange)="accept($event)"
            name="agb"
          ></ion-checkbox
          >&nbsp;
          <a class="pb" (click)="openDatenschutz()">Datenschutzbedingungen</a>
          akzeptieren
        </p>

        <div *ngIf="showDatenschutz">
          <p>
            Hier kannst du die Datenschutzbedingungen einsehen:
            <a color="primary">https://teamfit.karriereheld.team/agb</a>
          </p>
          <img src="/assets/img/QRdatenschutz.png" width="200px" />
        </div>
        <ui-select
          *ngIf="!loading"
          [items]="personalFitSpeed.startButton"
        ></ui-select>
        <ui-select
          *ngIf="loading"
          [items]="[
            {
              id: 'start',
              name: 'Start',
              iconClass: 'far fa-spinner-third fa-spin'
            }
          ]"
        >
        </ui-select>
      </ion-col>
    </ng-container>
  </ion-row>
</ion-grid>
