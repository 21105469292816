import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import binary from "./questions/binary";
import list from "./questions/list";
import matrix from "./questions/matrix";
import slider_full from "./questions/slider-full";
import slider_ratio from "./questions/slider-ratio";

@Component({
  selector: "app-set-static",
  templateUrl: "./set-static.component.html",
  styleUrls: ["./set-static.component.scss"],
})
export class SetStaticComponent {
  constructor(public firestore: AngularFirestore) {}

  ionViewWillEnter(): void {}

  public static;
  public staticMap;

  createCSV() {
    this.firestore
      .doc("static/app")
      .get()
      .subscribe((ref) => {
        let data = ref.data();
        this.static = data;

        console.log(this.static);
        /* this.tooltips = {}
               // console.log(this.static)
         
               this.staticMap = {}
               for (let key in this.static) {
                 if (Array.isArray(this.static[key]) && key != 'skill_categories') {
                   this.staticMap[key] = this.arrayToObject(this.static[key])
                   this.tooltips[key] = this.static[key].map(k => `${k.name}: ${k.info}`).join('<br/>')
                 } else if (key == 'stellenbezeichnungen') {
                   // this.staticMap[key] = this.arrayToObject(Object.values(this.static[key]).flat())  //https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
                   this.staticMap[key] = this.arrayToObject(this.flattenDeep(Object.values(this.static[key])))
                   this.static.stellenbezeichnungen_list = this.flattenDeep(Object.values(this.static[key]))
                 }
               }
               // console.log(this.staticMap)
               
         */
        console.log(this);
      });
  }

  setStatic() {
    this.firestore
      .collection("static")
      .doc("app")
      .set({
        einkommen: [
          {
            id: "einkommen_0_19k",
            name: "0 - 19K",
          },
          {
            id: "einkommen_20_39k",
            name: "20 - 39K",
          },
          {
            id: "einkommen_40_49k",
            name: "40 - 49K",
          },
          {
            id: "einkommen_50_59k",
            name: "50 - 59K",
          },
          {
            id: "einkommen_60_69k",
            name: "60 - 69K",
          },
          {
            id: "einkommen_70_79k",
            name: "70 - 79K",
          },
          {
            id: "einkommen_80_89k",
            name: "80 - 89K",
          },

          {
            id: "einkommen_90_99k",
            name: "90 - 99K",
          },

          {
            id: "einkommen_100_119k",
            name: "100 - 119K",
          },

          {
            id: "einkommen_120_139k",
            name: "120 - 139K",
          },
          {
            id: "einkommen_140_199k",
            name: "140 - 199K",
          },
          {
            id: "einkommen_200k",
            name: "+200K",
          },
        ],

        herkunft: [
          {
            id: "herkunft_land",
            name: "Land",
            iconClass: "far fa-farm",
          },
          {
            id: "herkunft_stadt",
            name: "Stadt",
            iconClass: "far fa-city",
          },
        ],

        effectiveranderesteam: [
          {
            id: "effectiveranderesteam_ja",
            name: "Ja",
            iconClass: "far fa-check",
          },
          {
            id: "effectiveranderesteam_nein",
            name: "Nein",
            iconClass: "far fa-times",
          },
        ],
        sex: [
          {
            id: "sex_m",
            name: "Männlich",
            iconClass: 'far fa-mars'
          },
          {
            id: "sex_w",
            name: "Weiblich",
            iconClass:'far fa-venus'
          },
          {
            id: "sex_d",
            name: "Divers",
            iconClass:'far fa-transgender'
          },
        ],
        berufsfelder: [
          {
            id: "banken",
            name: "Banken, Finanzen, Versicherung",
            iconClass: "far fa-money-check-edit",
            flow: "standard",
          },
          {
            id: "bau",
            name: "Bau, Gebäudetechnik",
            iconClass: "far fa-user-hard-hat",
            flow: "standard",
          },
          {
            id: "gesundheitswesen",
            name: "Beauty, Gesundheit, Soziales",
            iconClass: "far fa-briefcase-medical",
            flow: "standard",
          },
          {
            id: "controlling",
            name: "Controlling",
            iconClass: "far fa-chart-bar",
            flow: "standard",
          },
          {
            id: "design",
            name: "Design",
            iconClass: "far fa-spray-can",
            flow: "standard",
          },
          {
            id: "innovationsmanagement_digitalisierung",
            name: "Digitalisierung",
            iconClass: "far fa-lightbulb-on",
            flow: "standard",
          },
          {
            id: "einkauf_und_supply_chain",
            name: "Einkauf und Supply Chain",
            iconClass: "far fa-shopping-basket",
            flow: "standard",
          },
          {
            id: "elektro",
            name: "Elektrotechnik",
            iconClass: "far fa-bolt",
            flow: "standard",
          },
          // {
          //   id: "engineering",
          //   name: "Engineering",
          //   iconClass: "far fa-pencil-ruler",
          //   flow: "standard",
          // },
          {
            id: "finanz_und_rechnungswesen",
            name: "Finanz- und Rechnungswesen",
            iconClass: "far fa-abacus",
            flow: "standard",
          },
          // {
          //   id: "forschung",
          //   name: "Forschung & Entwicklung",
          //   iconClass: "far fa-brain",
          //   flow: "standard",
          // },
          {
            id: "gastgewerbe",
            name: "Gastgewerbe",
            iconClass: "far fa-utensils",
            flow: "standard",
          },
          {
            id: "einzelhandel",
            name: "Handel",
            iconClass: "far fa-store",
            flow: "standard",
          },
          {
            id: "informatik",
            name: "IT",
            iconClass: "far fa-code",
            flow: "standard",
          },
          {
            id: "kunststoffe",
            name: "Kunststoffe",
            iconClass: "far fa-prescription-bottle",
            flow: "standard",
          },
          {
            id: "branche_kultur_unterhaltung_veranstaltung",
            name: "Kunst, Kultur, Veranstaltung",
            iconClass: "far fa-theater-masks",
          },
          {
            id: "land_forstwirtschaft",
            name: "Land-, Forstwirtschaft, Garten",
            iconClass: "far fa-tractor",
            flow: "standard",
          },
          {
            id: "lebensmittelindustrie",
            name: "Lebensmittel",
            iconClass: "far fa-utensils-alt",
            flow: "standard",
          },
          {
            id: "marketing",
            name: "Marketing",
            iconClass: "far fa-megaphone",
            flow: "standard",
          },
          {
            id: "maschinenbau_kfz_metall",
            name: "Maschinenbau, Kfz, Metall",
            iconClass: "far fa-car-mechanic",
            flow: "standard",
          },
          {
            id: "office_und_administration",
            name: "Office und Administration",
            iconClass: "far fa-chair-office",
            flow: "standard",
          },
          {
            id: "human_resources",
            name: "Personalwesen / HR",
            iconClass: "far fa-people-arrows",
            flow: "standard",
          },
          {
            id: "projektmanagement",
            name: "Projektmanagement",
            iconClass: "far fa-project-diagram",
            flow: "standard",
          },
          {
            id: "recht",
            name: "Rechtsberufe",
            iconClass: "far fa-gavel",
            flow: "standard",
          },
          {
            id: "logistik",
            name: "Spedition",
            iconClass: "far fa-warehouse-alt",
            flow: "standard",
          },
          // {
          //   id: "top_level_management",
          //   name: "Top-Level-Management",
          //   iconClass: "far fa-user-tie",
          //   flow: "standard",
          // },
          {
            id: "verkehr",
            name: "Verkehr",
            iconClass: "far fa-traffic-light",
            flow: "standard",
          },
          {
            id: "sales_und_business_development",
            name: "Vertrieb, Business Development",
            iconClass: "far fa-handshake",
            flow: "standard",
          },
          {
            id: "wissenschaft",
            name: "Wissenschaft",
            iconClass: "far fa-atom",
            flow: "standard",
          },
          {
            id: "verwaltung",
            name: "Verwaltung",
            iconClass: "far fa-landmark",
            flow: "standard",
          },
          {
            id: "gewerbliche_fachkraefte",
            name: "Gewerbliche Fach- und Führungskräfte/Techniker/Meister",
            iconClass: "far fa-cogs",
            flow: "gewerbliche_technische_hilfskraefte",
          },
          {
            id: "gewerbliche_hilfskraefte",
            name: "Gewerbliche Hilfskräfte",
            iconClass: "far fa-hands-helping",
            flow: "gewerbliche_technische_hilfskraefte",
          },
          {
            id: "sonstiges",
            name: "Sonstiges",
            iconClass: "far fa-question-square",
            flow: "standard",
          },
        ],
        anstellungsarten: [
          {
            id: "anstellungsart_festanstellung",
            name: "Festanstellung",
          },
          {
            id: "anstellungsart_freelance",
            name: "Freelance",
          },
          {
            id: "anstellungsart_befristet",
            name: "Befristet",
          },
          {
            id: "anstellungsart_ausbildung",
            name: "Ausbildung",
          },
          {
            id: "anstellungsart_duales_studium",
            name: "Duales Studium",
          },
          {
            id: "anstellungsart_praktikum",
            name: "Praktikum",
          },
          {
            id: "anstellungsart_pflichtpraktikum",
            name: "Pflichtpraktikum",
          },
          {
            id: "anstellungsart_abschlussarbeit",
            name: "Abschlussarbeit",
          },
          {
            id: "anstellungsart_promotion",
            name: "Promotion",
          },
          {
            id: "anstellungsart_ferienbeschaeftigung",
            name: "Ferienbeschäftigung",
          },
          {
            id: "anstellungsart_werkstudent",
            name: "Werkstudent",
          },
        ],
        fuehrungserfahrung: [
          {
            id: "fuehrungserfahrung_kauge",
            name: "keine",
            rang: 0,
          },
          {
            id: "fuehrungserfahrung_bis_3_jahre",
            name: "bis 3 Jahre",
            rang: 1,
          },
          {
            id: "fuehrungserfahrung_groesser_3_jahre",
            name: "größer 3 Jahre",
            rang: 2,
          },
          {
            id: "fuehrungserfahrung_groesser_8_jahre",
            name: "größer 8 Jahre",
            rang: 3,
          },
        ],

        karrierelevel: [
          {
            id: "karrierelevel_junior",
            name: "Junior",
            iconClass: "far fa-baby",
            info: "1-2 Jahre Berufserfahrung",
            rang: 0,
          },
          {
            id: "karrierelevel_middle",
            name: "Middle",
            iconClass: "far fa-child",
            info: "3-5 Jahre Berufserfahrung",
            rang: 1,
          },
          {
            id: "karrierelevel_senior",
            name: "Senior",
            iconClass: "far fa-user",
            info: ">5 Jahre Berufserfahrung",
            rang: 2,
          },
          {
            id: "karrierelevel_manager",
            name: "Manager",
            iconClass: "far fa-user-tie",
            info: "Personalverantwortung",
            rang: 3,
          },
        ],
        hoechster_abschluss: [
          {
            id: "hauptschulabschluss",
            name: "Hauptschulabschluss",
            rang: 1,
          },

          {
            id: "mittlere_reife_mittlerer_bildungsabschluss",
            name: "Mittlere Reife / Mittlerer Bildungsabschluss",
            rang: 1,
          },

          {
            id: "fachhochschulreife",
            name: "Fachhochschulreife / Abitur",
            rang: 1,
            alternativNamen: ["Sekundarbildung Oberstufe", "Abitur"],
          },

          {
            id: "hoechster_abschluss_bachelor",
            name: "Bachelor",
            rang: 2,
          },

          {
            id: "diplom",
            name: "Master / Diplom",
            rang: 3,
          },

          {
            id: "promotion",
            name: "Promotion",
            rang: 4,
          },
        ],
        unternehmensgroesse: [
          {
            id: "startup",
            name: "Startup",
          },
          {
            id: "mittelstand_k_250",
            name: "Mittelstand <250 Mitarbeiter",
          },
          {
            id: "mittelstand_g_250",
            name: "Mittelstand >250 Mitarbeiter",
          },
          {
            id: "konzern",
            name: "Konzern >5000 Mitarbeiter",
          },
        ],
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    console.log("ok");

    const q = [
      ...slider_ratio.map((x) => ({ ...x, typ: "slider-ratio" })),
      ...slider_full.map((x) => ({ ...x, typ: "slider-full" })),
      ...matrix.map((x) => ({ ...x, typ: "matrix" })),
      ...list.map((x) => ({ ...x, typ: "list" })),
      ...binary.map((x) => ({ ...x, typ: "binary" })),
      // {typ: 'matrix-introduction'},
    ].map((x, i) => ({ ...x, id: i }));

    this.firestore
      .collection("static")
      .doc("questions")
      .set({ questions: q })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    console.log("ok");
  }
}

const skill_categories = [
  {
    id: "finanz_und_rechnungswesen",
    name: "Finanz- und Rechnungswesen",
    sub_categories: [
      {
        id: "debitorenbuchhaltung",
        name: "Debitorenbuchhaltung",
        skills: [
          {
            id: "debitorenbuchhaltung_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "debitorenbuchhaltung_kontierung",
            name: "Kontierung",
          },
          {
            id: "debitorenbuchhaltung_verbuchung",
            name: "Verbuchung",
          },
          {
            id: "debitorenbuchhaltung_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "debitorenbuchhaltung_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "debitorenbuchhaltung_stammdatenpflege",
            name: "Stammdatenpflege",
          },
          {
            id: "debitorenbuchhaltung_verbuchung_zahlungen",
            name: "Verbuchung Zahlungen",
          },
          {
            id: "debitorenbuchhaltung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "debitorenbuchhaltung_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
          },
          {
            id: "debitorenbuchhaltung_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "debitorenbuchhaltung_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "kreditorenbuchhaltung",
        name: "Kreditorenbuchhaltung",
        skills: [
          {
            id: "kreditorenbuchhaltung_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "kreditorenbuchhaltung_kontierung",
            name: "Kontierung",
          },
          {
            id: "kreditorenbuchhaltung_verbuchung",
            name: "Verbuchung",
          },
          {
            id: "kreditorenbuchhaltung_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "kreditorenbuchhaltung_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "kreditorenbuchhaltung_stammdatenpflege",
            name: "Stammdatenpflege",
          },
          {
            id: "kreditorenbuchhaltung_verbuchung_zahlungen",
            name: "Verbuchung Zahlungen",
          },
          {
            id: "kreditorenbuchhaltung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "kreditorenbuchhaltung_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
          },
          {
            id: "kreditorenbuchhaltung_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "kreditorenbuchhaltung_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "sachbearbeitung_buchhaltung",
        name: "Sachbearbeitung Buchhaltung",
        skills: [
          {
            id: "sachbearbeitung_buchhaltung_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "sachbearbeitung_buchhaltung_kontierung",
            name: "Kontierung",
          },
          {
            id: "sachbearbeitung_buchhaltung_verbuchung",
            name: "Verbuchung",
          },
          {
            id: "sachbearbeitung_buchhaltung_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "sachbearbeitung_buchhaltung_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "sachbearbeitung_buchhaltung_stammdatenpflege",
            name: "Stammdatenpflege",
          },
          {
            id: "sachbearbeitung_buchhaltung_verbuchung_zahlungen",
            name: "Verbuchung Zahlungen",
          },
          {
            id: "sachbearbeitung_buchhaltung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "sachbearbeitung_buchhaltung_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
          },
          {
            id: "sachbearbeitung_buchhaltung_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "sachbearbeitung_buchhaltung_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "anlagenbuchhaltung",
        name: "Anlagenbuchhaltung",
        skills: [
          {
            id: "anlagenbuchhaltung_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "anlagenbuchhaltung_kontierung",
            name: "Kontierung",
          },
          {
            id: "anlagenbuchhaltung_verbuchung",
            name: "Verbuchung",
          },
          {
            id: "anlagenbuchhaltung_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "anlagenbuchhaltung_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "anlagenbuchhaltung_stammdatenpflege",
            name: "Stammdatenpflege",
          },
          {
            id: "anlagenbuchhaltung_verbuchung_zahlungen",
            name: "Verbuchung Zahlungen",
          },
          {
            id: "anlagenbuchhaltung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "anlagenbuchhaltung_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
          },
          {
            id: "anlagenbuchhaltung_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "anlagenbuchhaltung_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "anlagenbuchhaltung_anlageninventur",
            name: "Anlageninventur",
          },
          {
            id: "anlagenbuchhaltung_bestandsauswertungen",
            name: "Bestandsauswertungen",
          },
          {
            id: "anlagenbuchhaltung_abschreibungen",
            name: "Abschreibungen",
          },
          {
            id: "anlagenbuchhaltung_mitwirkung_bei_monats/quartalsabschlüssen",
            name: "Mitwirkung bei Monats-/Quartalsabschlüssen",
          },
          {
            id: "anlagenbuchhaltung_mitwirkung_bei_jahressabschlüssen",
            name: "Mitwirkung bei Jahressabschlüssen",
          },
        ],
      },
      {
        id: "hauptbuchhaltung",
        name: "Hauptbuchhaltung",
        skills: [
          {
            id: "hauptbuchhaltung_monats/quartalsabschlüsse_nach_hgb",
            name: "Monats-/Quartalsabschlüsse nach HGB",
          },
          {
            id: "hauptbuchhaltung_jahressabschlüsse_nach_hgb",
            name: "Jahressabschlüsse nach HGB",
          },
          {
            id: "hauptbuchhaltung_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "hauptbuchhaltung_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "hauptbuchhaltung_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "hauptbuchhaltung_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "hauptbuchhaltung_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "hauptbuchhaltung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "hauptbuchhaltung_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "hauptbuchhaltung_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "hauptbuchhaltung_umsatzsteuervoranmeldungen",
            name: "Umsatzsteuervoranmeldungen",
          },
          {
            id: "hauptbuchhaltung_monats/quartalsabschlüsse_nach_ifrs",
            name: "Monats-/Quartalsabschlüsse nach IFRS",
          },
          {
            id: "hauptbuchhaltung_monats/quartalsabschlüsse_nach_usgaap",
            name: "Monats-/Quartalsabschlüsse nach US-GAAP",
          },
          {
            id: "hauptbuchhaltung_jahressabschlüsse_nach_ifrs",
            name: "Jahressabschlüsse nach IFRS",
          },
          {
            id: "hauptbuchhaltung_jahressabschlüsse_nach_usgaap",
            name: "Jahressabschlüsse nach US-GAAP",
          },
          {
            id: "hauptbuchhaltung_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "bilanzbuchhaltung_national",
        name: "Bilanzbuchhaltung national",
        skills: [
          {
            id: "bilanzbuchhaltung_national_monats/quartalsabschlüsse_nach_hgb",
            name: "Monats-/Quartalsabschlüsse nach HGB",
          },
          {
            id: "bilanzbuchhaltung_national_jahressabschlüsse_nach_hgb",
            name: "Jahressabschlüsse nach HGB",
          },
          {
            id: "bilanzbuchhaltung_national_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "bilanzbuchhaltung_national_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "bilanzbuchhaltung_national_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "bilanzbuchhaltung_national_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "bilanzbuchhaltung_national_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "bilanzbuchhaltung_national_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "bilanzbuchhaltung_national_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "bilanzbuchhaltung_national_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "bilanzbuchhaltung_national_umsatzsteuervoranmeldungen",
            name: "Umsatzsteuervoranmeldungen",
          },
          {
            id: "bilanzbuchhaltung_national_zmmeldungen",
            name: "ZM-Meldungen",
          },
          {
            id: "bilanzbuchhaltung_national_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "bilanzbuchhaltung_national_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "bilanzbuchhaltung_international",
        name: "Bilanzbuchhaltung international",
        skills: [
          {
            id: "bilanzbuchhaltung_international_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "bilanzbuchhaltung_international_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "bilanzbuchhaltung_international_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "bilanzbuchhaltung_international_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "bilanzbuchhaltung_international_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "bilanzbuchhaltung_international_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "bilanzbuchhaltung_international_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "bilanzbuchhaltung_international_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "bilanzbuchhaltung_international_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "bilanzbuchhaltung_international_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "bilanzbuchhaltung_international_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "bilanzbuchhaltung_international_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "bilanzbuchhaltung_international_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "bilanzbuchhaltung_international_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "bilanzbuchhaltung_international_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "bilanzbuchhaltung_international_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "bilanzbuchhaltung_international_umsatzsteuervoranmeldungen",
            name: "Umsatzsteuervoranmeldungen",
          },
          {
            id: "bilanzbuchhaltung_international_zmmeldungen",
            name: "ZM-Meldungen",
          },
          {
            id: "bilanzbuchhaltung_international_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "bilanzbuchhaltung_international_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "referent_rechnungswesen",
        name: "Referent Rechnungswesen",
        skills: [
          {
            id: "referent_rechnungswesen_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "referent_rechnungswesen_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "referent_rechnungswesen_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "referent_rechnungswesen_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "referent_rechnungswesen_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "referent_rechnungswesen_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "referent_rechnungswesen_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "referent_rechnungswesen_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "referent_rechnungswesen_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "referent_rechnungswesen_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "referent_rechnungswesen_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "referent_rechnungswesen_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "referent_rechnungswesen_reporting",
            name: "Reporting",
          },
          {
            id: "referent_rechnungswesen_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "referent_rechnungswesen_analyse_abschlüsse",
            name: "Analyse Abschlüsse",
          },
          {
            id: "referent_rechnungswesen_projektaufgaben",
            name: "Projektaufgaben",
          },
          {
            id: "referent_rechnungswesen_intere_kontrollsysteme",
            name: "Intere Kontrollsysteme",
          },
          {
            id: "referent_rechnungswesen_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "referent_rechnungswesen_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "referent_rechnungswesen_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "group_accountant",
        name: "Group Accountant",
        skills: [
          {
            id: "group_accountant_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "group_accountant_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "group_accountant_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "group_accountant_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "group_accountant_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "group_accountant_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "group_accountant_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "group_accountant_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "group_accountant_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "group_accountant_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "group_accountant_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "group_accountant_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "group_accountant_reporting",
            name: "Reporting",
          },
          {
            id: "group_accountant_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "group_accountant_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "group_accountant_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "group_accountant_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "teamleiter_buchhaltung",
        name: "Teamleiter Buchhaltung",
        skills: [
          {
            id: "teamleiter_buchhaltung_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "teamleiter_buchhaltung_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "teamleiter_buchhaltung_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "teamleiter_buchhaltung_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "teamleiter_buchhaltung_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "teamleiter_buchhaltung_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "teamleiter_buchhaltung_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "teamleiter_buchhaltung_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "teamleiter_buchhaltung_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "teamleiter_buchhaltung_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "teamleiter_buchhaltung_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "teamleiter_buchhaltung_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "teamleiter_buchhaltung_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "teamleiter_buchhaltung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "teamleiter_buchhaltung_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "teamleiter_buchhaltung_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "teamleiter_buchhaltung_umsatzsteuervoranmeldungen",
            name: "Umsatzsteuervoranmeldungen",
          },
          {
            id: "teamleiter_buchhaltung_zmmeldungen",
            name: "ZM-Meldungen",
          },
          {
            id: "teamleiter_buchhaltung_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "teamleiter_buchhaltung_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "teamleiter_buchhaltung_mitwirkung_controlling",
            name: "Mitwirkung Controlling",
          },
          {
            id: "teamleiter_buchhaltung_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "teamleiter_buchhaltung_teamführung",
            name: "Teamführung",
          },
          {
            id: "teamleiter_buchhaltung_projektaufgaben",
            name: "Projektaufgaben",
          },
          {
            id: "teamleiter_buchhaltung_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "teamleiter_buchhaltung_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "teamleiter_buchhaltung_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "teamleiter_buchhaltung_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "teamleiter_buchhaltung_reporting",
            name: "Reporting",
          },
          {
            id: "teamleiter_buchhaltung_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "teamleiter_buchhaltung_controlling",
            name: "Controlling",
          },
          {
            id: "teamleiter_buchhaltung_shared_service_center",
            name: "Shared Service Center",
          },
        ],
      },
      {
        id: "leiter_buchhaltung",
        name: "Leiter Buchhaltung",
        skills: [
          {
            id: "leiter_buchhaltung_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "leiter_buchhaltung_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "leiter_buchhaltung_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "leiter_buchhaltung_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "leiter_buchhaltung_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "leiter_buchhaltung_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "leiter_buchhaltung_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "leiter_buchhaltung_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "leiter_buchhaltung_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "leiter_buchhaltung_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "leiter_buchhaltung_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "leiter_buchhaltung_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "leiter_buchhaltung_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "leiter_buchhaltung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "leiter_buchhaltung_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "leiter_buchhaltung_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "leiter_buchhaltung_umsatzsteuervoranmeldungen",
            name: "Umsatzsteuervoranmeldungen",
          },
          {
            id: "leiter_buchhaltung_zmmeldungen",
            name: "ZM-Meldungen",
          },
          {
            id: "leiter_buchhaltung_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "leiter_buchhaltung_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "leiter_buchhaltung_mitwirkung_controlling",
            name: "Mitwirkung Controlling",
          },
          {
            id: "leiter_buchhaltung_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "leiter_buchhaltung_teamführung",
            name: "Teamführung",
          },
          {
            id: "leiter_buchhaltung_projektaufgaben",
            name: "Projektaufgaben",
          },
          {
            id: "leiter_buchhaltung_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "leiter_buchhaltung_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "leiter_buchhaltung_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "leiter_buchhaltung_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "leiter_buchhaltung_reporting",
            name: "Reporting",
          },
          {
            id: "leiter_buchhaltung_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "leiter_buchhaltung_controlling",
            name: "Controlling",
          },
          {
            id: "leiter_buchhaltung_shared_service_center",
            name: "Shared Service Center",
          },
        ],
      },
      {
        id: "leiter_finanzen",
        name: "Leiter Finanzen",
        skills: [
          {
            id: "leiter_finanzen_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "leiter_finanzen_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "leiter_finanzen_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "leiter_finanzen_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "leiter_finanzen_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "leiter_finanzen_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "leiter_finanzen_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "leiter_finanzen_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "leiter_finanzen_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "leiter_finanzen_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "leiter_finanzen_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "leiter_finanzen_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "leiter_finanzen_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "leiter_finanzen_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "leiter_finanzen_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "leiter_finanzen_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "leiter_finanzen_umsatzsteuervoranmeldungen",
            name: "Umsatzsteuervoranmeldungen",
          },
          {
            id: "leiter_finanzen_zmmeldungen",
            name: "ZM-Meldungen",
          },
          {
            id: "leiter_finanzen_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "leiter_finanzen_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "leiter_finanzen_mitwirkung_controlling",
            name: "Mitwirkung Controlling",
          },
          {
            id: "leiter_finanzen_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "leiter_finanzen_teamführung",
            name: "Teamführung",
          },
          {
            id: "leiter_finanzen_projektaufgaben",
            name: "Projektaufgaben",
          },
          {
            id: "leiter_finanzen_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "leiter_finanzen_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "leiter_finanzen_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "leiter_finanzen_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "leiter_finanzen_reporting",
            name: "Reporting",
          },
          {
            id: "leiter_finanzen_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "leiter_finanzen_controlling",
            name: "Controlling",
          },
          {
            id: "leiter_finanzen_shared_service_center",
            name: "Shared Service Center",
          },
        ],
      },
      {
        id: "finance_manager",
        name: "Finance Manager",
        skills: [
          {
            id: "finance_manager_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "finance_manager_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "finance_manager_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "finance_manager_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "finance_manager_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "finance_manager_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "finance_manager_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "finance_manager_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "finance_manager_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "finance_manager_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "finance_manager_zahlläufe",
            name: "Zahlläufe",
          },
          {
            id: "finance_manager_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "finance_manager_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "finance_manager_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "finance_manager_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "finance_manager_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "finance_manager_umsatzsteuervoranmeldungen",
            name: "Umsatzsteuervoranmeldungen",
          },
          {
            id: "finance_manager_zmmeldungen",
            name: "ZM-Meldungen",
          },
          {
            id: "finance_manager_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "finance_manager_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "finance_manager_mitwirkung_controlling",
            name: "Mitwirkung Controlling",
          },
          {
            id: "finance_manager_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "finance_manager_teamführung",
            name: "Teamführung",
          },
          {
            id: "finance_manager_projektaufgaben",
            name: "Projektaufgaben",
          },
          {
            id: "finance_manager_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "finance_manager_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "finance_manager_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "finance_manager_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "finance_manager_reporting",
            name: "Reporting",
          },
          {
            id: "finance_manager_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "finance_manager_controlling",
            name: "Controlling",
          },
          {
            id: "finance_manager_shared_service_center",
            name: "Shared Service Center",
          },
        ],
      },
      {
        id: "cfo",
        name: "CFO",
        skills: [
          {
            id: "cfo_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "cfo_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "cfo_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "cfo_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "cfo_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "cfo_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "cfo_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "cfo_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "cfo_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "cfo_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "cfo_digitalsierung",
            name: "Digitalsierung",
          },
          {
            id: "cfo_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "cfo_steuererklärungen",
            name: "Steuererklärungen",
          },
          {
            id: "cfo_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "cfo_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "cfo_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "cfo_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "cfo_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "cfo_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "cfo_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "cfo_reporting",
            name: "Reporting",
          },
          {
            id: "cfo_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "cfo_controlling",
            name: "Controlling",
          },
          {
            id: "cfo_shared_service_center",
            name: "Shared Service Center",
          },
          {
            id: "cfo_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "cfo_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "cfo_forecasting",
            name: "Forecasting",
          },
          {
            id: "cfo_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "cfo_planung",
            name: "Planung",
          },
          {
            id: "cfo_reporting",
            name: "Reporting",
          },
          {
            id: "cfo_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "cfo_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "cfo_sox",
            name: "SOX",
          },
          {
            id: "cfo_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "cfo_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "cfo_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "kaufmännischer_leiter",
        name: "Kaufmännischer Leiter",
        skills: [
          {
            id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "kaufmännischer_leiter_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "kaufmännischer_leiter_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "kaufmännischer_leiter_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "kaufmännischer_leiter_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "kaufmännischer_leiter_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "kaufmännischer_leiter_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "kaufmännischer_leiter_digitalsierung",
            name: "Digitalsierung",
          },
          {
            id: "kaufmännischer_leiter_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "kaufmännischer_leiter_steuererklärungen",
            name: "Steuererklärungen",
          },
          {
            id: "kaufmännischer_leiter_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "kaufmännischer_leiter_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "kaufmännischer_leiter_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "kaufmännischer_leiter_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "kaufmännischer_leiter_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "kaufmännischer_leiter_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "kaufmännischer_leiter_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "kaufmännischer_leiter_reporting",
            name: "Reporting",
          },
          {
            id: "kaufmännischer_leiter_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "kaufmännischer_leiter_controlling",
            name: "Controlling",
          },
          {
            id: "kaufmännischer_leiter_shared_service_center",
            name: "Shared Service Center",
          },
          {
            id: "kaufmännischer_leiter_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "kaufmännischer_leiter_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "kaufmännischer_leiter_forecasting",
            name: "Forecasting",
          },
          {
            id: "kaufmännischer_leiter_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "kaufmännischer_leiter_planung",
            name: "Planung",
          },
          {
            id: "kaufmännischer_leiter_reporting",
            name: "Reporting",
          },
          {
            id: "kaufmännischer_leiter_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "kaufmännischer_leiter_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "kaufmännischer_leiter_sox",
            name: "SOX",
          },
          {
            id: "kaufmännischer_leiter_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "kaufmännischer_leiter_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "kaufmännischer_leiter_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
          {
            id: "kaufmännischer_leiter_einkauf",
            name: "Einkauf",
          },
          {
            id: "kaufmännischer_leiter_personalwesen",
            name: "Personalwesen",
          },
          {
            id: "kaufmännischer_leiter_it",
            name: "IT",
          },
        ],
      },
      {
        id: "internal_audit",
        name: "Internal Audit",
        skills: [
          {
            id: "internal_audit_erstellung_revisionsplan",
            name: "Erstellung Revisionsplan",
          },
          {
            id: "internal_audit_internes_kontrollsystem",
            name: "Internes Kontrollsystem",
          },
          {
            id: "internal_audit_nachverfolgung_maßnahmen",
            name: "Nachverfolgung Maßnahmen",
          },
          {
            id: "internal_audit_bewertung",
            name: "Bewertung",
          },
          {
            id: "internal_audit_inventur",
            name: "Inventur",
          },
          {
            id: "internal_audit_erstellung_auswertungen",
            name: "Erstellung Auswertungen",
          },
          {
            id: "internal_audit_anaylse_auswertungen",
            name: "Anaylse Auswertungen",
          },
          {
            id: "internal_audit_analyse_schwachstellen",
            name: "Analyse Schwachstellen",
          },
          {
            id: "internal_audit_auswertung_risikofaktoren",
            name: "Auswertung Risikofaktoren",
          },
        ],
      },
      {
        id: "revision",
        name: "Revision",
        skills: [
          {
            id: "revision_erstellung_revisionsplan",
            name: "Erstellung Revisionsplan",
          },
          {
            id: "revision_internes_kontrollsystem",
            name: "Internes Kontrollsystem",
          },
          {
            id: "revision_nachverfolgung_maßnahmen",
            name: "Nachverfolgung Maßnahmen",
          },
          {
            id: "revision_bewertung",
            name: "Bewertung",
          },
          {
            id: "revision_inventur",
            name: "Inventur",
          },
          {
            id: "revision_erstellung_auswertungen",
            name: "Erstellung Auswertungen",
          },
          {
            id: "revision_anaylse_auswertungen",
            name: "Anaylse Auswertungen",
          },
          {
            id: "revision_analyse_schwachstellen",
            name: "Analyse Schwachstellen",
          },
          {
            id: "revision_auswertung_risikofaktoren",
            name: "Auswertung Risikofaktoren",
          },
        ],
      },
      {
        id: "steuerfachangestellter",
        name: "Steuerfachangestellter",
        skills: [
          {
            id: "steuerfachangestellter_mandantenbuchhaltung",
            name: "Mandantenbuchhaltung",
          },
          {
            id: "steuerfachangestellter_lohn_und_gehaltsabrechnung",
            name: "Lohn- und Gehaltsabrechnung",
          },
          {
            id: "steuerfachangestellter_umsatzsteuervoranmeldung",
            name: "Umsatzsteuervoranmeldung",
          },
          {
            id: "steuerfachangestellter_zmmeldung",
            name: "ZM-Meldung",
          },
          {
            id: "steuerfachangestellter_debitorenbuchhaltung",
            name: "Debitorenbuchhaltung",
          },
          {
            id: "steuerfachangestellter_kreditorenbuchhaltung",
            name: "Kreditorenbuchhaltung",
          },
          {
            id: "steuerfachangestellter_verbuchung_zahlungen",
            name: "Verbuchung Zahlungen",
          },
          {
            id: "steuerfachangestellter_mahnwesen",
            name: "Mahnwesen",
          },
          {
            id: "steuerfachangestellter_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
          },
          {
            id: "steuerfachangestellter_monats/quartalsabschlüsse_nach_hgb",
            name: "Monats-/Quartalsabschlüsse nach HGB",
          },
          {
            id: "steuerfachangestellter_jahressabschlüsse_nach_hgb",
            name: "Jahressabschlüsse nach HGB",
          },
        ],
      },
      {
        id: "tax_manager",
        name: "Tax Manager",
        skills: [
          {
            id: "tax_manager_steuererklärungen_unternehmen",
            name: "Steuererklärungen Unternehmen",
          },
          {
            id: "tax_manager_steuererklärungen_privatpersonen",
            name: "Steuererklärungen Privatpersonen",
          },
          {
            id: "tax_manager_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "tax_manager_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "tax_manager_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "tax_manager_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "tax_manager_mandatenbetreuung",
            name: "Mandatenbetreuung",
          },
          {
            id: "tax_manager_betriebsprüfungen",
            name: "Betriebsprüfungen",
          },
          {
            id: "tax_manager_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "tax_manager_umsatzsteuer",
            name: "Umsatzsteuer",
          },
          {
            id: "tax_manager_tax_compliance",
            name: "Tax Compliance",
          },
          {
            id: "tax_manager_verrechnungspreisdokumentation",
            name: "Verrechnungspreisdokumentation",
          },
          {
            id: "tax_manager_fördermittel",
            name: "Fördermittel",
          },
        ],
      },
      {
        id: "steuerberater",
        name: "Steuerberater",
        skills: [
          {
            id: "steuerberater_steuererklärungen_unternehmen",
            name: "Steuererklärungen Unternehmen",
          },
          {
            id: "steuerberater_steuererklärungen_privatpersonen",
            name: "Steuererklärungen Privatpersonen",
          },
          {
            id: "steuerberater_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "steuerberater_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "steuerberater_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "steuerberater_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "steuerberater_mandatenbetreuung",
            name: "Mandatenbetreuung",
          },
          {
            id: "steuerberater_betriebsprüfungen",
            name: "Betriebsprüfungen",
          },
          {
            id: "steuerberater_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "steuerberater_umsatzsteuer",
            name: "Umsatzsteuer",
          },
          {
            id: "steuerberater_tax_compliance",
            name: "Tax Compliance",
          },
        ],
      },
      {
        id: "wirtschaftsprüfer",
        name: "Wirtschaftsprüfer",
        skills: [
          {
            id: "wirtschaftsprüfer_jahressabschlüsse_nach_hgb",
            name: "Jahressabschlüsse nach HGB",
          },
          {
            id: "wirtschaftsprüfer_jahressabschlüsse_nach_ifrs",
            name: "Jahressabschlüsse nach IFRS",
          },
          {
            id: "wirtschaftsprüfer_jahressabschlüsse_nach_usgaap",
            name: "Jahressabschlüsse nach US-GAAP",
          },
          {
            id: "wirtschaftsprüfer_betriebsprüfungen",
            name: "Betriebsprüfungen",
          },
          {
            id: "wirtschaftsprüfer_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "wirtschaftsprüfer_aufbau_controlling",
            name: "Aufbau Controlling",
          },
          {
            id: "wirtschaftsprüfer_consulting",
            name: "Consulting",
          },
        ],
      },
      {
        id: "experte_m&a",
        name: "Experte M&A",
        skills: [
          {
            id: "experte_m&a_unternehmensanalysen",
            name: "Unternehmensanalysen",
          },
          {
            id: "experte_m&a_unternehmensbewertungen",
            name: "Unternehmensbewertungen",
          },
          {
            id: "experte_m&a_finanz_und_planungsmodellen",
            name: "Finanz- und Planungsmodellen",
          },
          {
            id: "experte_m&a_markt_und_wettbewerbsanalysen",
            name: "Markt- und Wettbewerbsanalysen",
          },
          {
            id: "experte_m&a_erstellung_dokumente",
            name: "Erstellung Dokumente",
          },
          {
            id: "experte_m&a_pitch",
            name: "Pitch",
          },
          {
            id: "experte_m&a_due_diligence",
            name: "Due Diligence",
          },
          {
            id: "experte_m&a_sell_side",
            name: "Sell Side",
          },
          {
            id: "experte_m&a_buy_side",
            name: "Buy Side",
          },
          {
            id: "experte_m&a_kapitalmarkt",
            name: "Kapitalmarkt",
          },
          {
            id: "experte_m&a_transaktionsmanagement",
            name: "Transaktionsmanagement",
          },
          {
            id: "experte_m&a_closing",
            name: "Closing",
          },
          {
            id: "experte_m&a_beurteilung_von_businessmodellen",
            name: "Beurteilung von Businessmodellen",
          },
          {
            id: "experte_m&a_bewertung_businessmodelle",
            name: "Bewertung Businessmodelle",
          },
          {
            id: "experte_m&a_bilanzanalyse",
            name: "Bilanzanalyse",
          },
          {
            id: "experte_m&a_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "mathematiker",
        name: "Mathematiker",
        skills: [
          {
            id: "mathematiker_ausarbeitung_modelle",
            name: "Ausarbeitung Modelle",
          },
          {
            id: "mathematiker_anpassung_modelle",
            name: "Anpassung Modelle",
          },
          {
            id: "mathematiker_erstellung_gutachten",
            name: "Erstellung Gutachten",
          },
          {
            id: "mathematiker_prognoseberechnung",
            name: "Prognoseberechnung",
          },
          {
            id: "mathematiker_risikobewertung",
            name: "Risikobewertung",
          },
          {
            id: "mathematiker_leistungsfallberechnungen",
            name: "Leistungsfallberechnungen",
          },
          {
            id: "mathematiker_assetliabilitymanagement",
            name: "Asset-Liability-Management",
          },
          {
            id: "mathematiker_statistik",
            name: "Statistik",
          },
          {
            id: "mathematiker_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "referent_meldewesen",
        name: "Referent Meldewesen",
        skills: [
          {
            id: "referent_meldewesen_statistische_meldungen",
            name: "Statistische Meldungen",
          },
          {
            id: "referent_meldewesen_aufsichtsrechtliche_meldungen",
            name: "Aufsichtsrechtliche Meldungen",
          },
          {
            id: "referent_meldewesen_prozessdokumentationen",
            name: "Prozessdokumentationen",
          },
          {
            id: "referent_meldewesen_finanzanalyse",
            name: "Finanzanalyse",
          },
          {
            id: "referent_meldewesen_einarbeitung_neue_standards",
            name: "Einarbeitung neue Standards",
          },
          {
            id: "referent_meldewesen_bilanzstatistik",
            name: "Bilanzstatistik",
          },
          {
            id: "referent_meldewesen_terminüberwachung",
            name: "Terminüberwachung",
          },
          {
            id: "referent_meldewesen_kreditmeldewesen",
            name: "Kreditmeldewesen",
          },
          {
            id: "referent_meldewesen_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "lohn_und_gehaltsabrechner",
        name: "Lohn- und Gehaltsabrechner",
        skills: [
          {
            id: "lohn_und_gehaltsabrechner_stammdaten",
            name: "Stammdaten",
          },
          {
            id: "lohn_und_gehaltsabrechner_entgeltabrechnung",
            name: "Entgeltabrechnung",
          },
          {
            id: "lohn_und_gehaltsabrechner_eintritt_mitarbeiter",
            name: "Eintritt Mitarbeiter",
          },
          {
            id: "lohn_und_gehaltsabrechner_austritt_mitarbeiter",
            name: "Austritt Mitarbeiter",
          },
          {
            id: "lohn_und_gehaltsabrechner_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "lohn_und_gehaltsabrechner_baulohn",
            name: "Baulohn",
          },
          {
            id: "lohn_und_gehaltsabrechner_stundenlohn",
            name: "Stundenlohn",
          },
          {
            id: "lohn_und_gehaltsabrechner_sozialversicherungsrecht",
            name: "Sozialversicherungsrecht",
          },
          {
            id: "lohn_und_gehaltsabrechner_reporting",
            name: "Reporting",
          },
          {
            id: "lohn_und_gehaltsabrechner_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "lohn_und_gehaltsabrechner_pflege_abrechnungssystem",
            name: "Pflege Abrechnungssystem",
          },
          {
            id: "lohn_und_gehaltsabrechner_zeiterfassung",
            name: "Zeiterfassung",
          },
        ],
      },
      {
        id: "sonstige_finanzen",
        name: "Sonstige Finanzen",
      },
      {
        id: "projektmanager_digitalisierung",
        name: "Projektmanager Digitalisierung",
      },
      {
        id: "projektmanager_erpsystem",
        name: "Projektmanager ERP-System",
        skills: [
          {
            id: "projektmanager_erpsystem_sap_fi",
            name: "SAP FI",
          },
          {
            id: "projektmanager_erpsystem_sap_co",
            name: "SAP CO",
          },
          {
            id: "projektmanager_erpsystem_sap_bcs",
            name: "SAP BCS",
          },
          {
            id: "projektmanager_erpsystem_sap_4/hana",
            name: "SAP 4/Hana",
          },
          {
            id: "projektmanager_erpsystem_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "treasury",
        name: "Treasury",
        skills: [
          {
            id: "treasury_cashmanagement",
            name: "Cashmanagement",
          },
          {
            id: "treasury_hedging",
            name: "Hedging",
          },
          {
            id: "treasury_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "treasury_cashpooling",
            name: "Cashpooling",
          },
          {
            id: "treasury_optimierung_finanzkonzepte",
            name: "Optimierung Finanzkonzepte",
          },
          {
            id: "treasury_reporting",
            name: "Reporting",
          },
          {
            id: "treasury_transaktionscontrolling",
            name: "Transaktionscontrolling",
          },
          {
            id: "treasury_analyse_kapitalmarkt",
            name: "Analyse Kapitalmarkt",
          },
        ],
      },
    ],
  },
  {
    id: "controlling",
    name: "Controlling",
    sub_categories: [
      {
        id: "financial_controller",
        name: "Financial Controller",
        skills: [
          {
            id: "financial_controller_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "financial_controller_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "financial_controller_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "financial_controller_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "financial_controller_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "financial_controller_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "financial_controller_forecasting",
            name: "Forecasting",
          },
          {
            id: "financial_controller_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "financial_controller_planung",
            name: "Planung",
          },
          {
            id: "financial_controller_reporting",
            name: "Reporting",
          },
          {
            id: "financial_controller_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "financial_controller_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "financial_controller_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "financial_controller_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "financial_controller_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "financial_controller_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "financial_controller_bab",
            name: "BAB",
          },
          {
            id: "financial_controller_sox",
            name: "SOX",
          },
          {
            id: "financial_controller_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
        ],
      },
      {
        id: "projektcontoller",
        name: "Projektcontoller",
        skills: [
          {
            id: "projektcontoller_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "projektcontoller_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "projektcontoller_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "projektcontoller_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "projektcontoller_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "projektcontoller_forecasting",
            name: "Forecasting",
          },
          {
            id: "projektcontoller_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "projektcontoller_planung",
            name: "Planung",
          },
          {
            id: "projektcontoller_reporting",
            name: "Reporting",
          },
          {
            id: "projektcontoller_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "projektcontoller_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "projektcontoller_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "projektcontoller_percentage_of_completion/poc",
            name: "Percentage of Completion/POC",
          },
          {
            id: "projektcontoller_projektbewertung",
            name: "Projektbewertung",
          },
          {
            id: "projektcontoller_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "projektcontoller_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
        ],
      },
      {
        id: "produktionscontrolling",
        name: "Produktionscontrolling",
        skills: [
          {
            id: "produktionscontrolling_maschinenstundensätze",
            name: "Maschinenstundensätze",
          },
          {
            id: "produktionscontrolling_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "produktionscontrolling_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "produktionscontrolling_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "produktionscontrolling_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "produktionscontrolling_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "produktionscontrolling_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "produktionscontrolling_forecasting",
            name: "Forecasting",
          },
          {
            id: "produktionscontrolling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "produktionscontrolling_planung",
            name: "Planung",
          },
          {
            id: "produktionscontrolling_reporting",
            name: "Reporting",
          },
          {
            id: "produktionscontrolling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "produktionscontrolling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "produktionscontrolling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "produktionscontrolling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "produktionscontrolling_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "produktionscontrolling_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "produktionscontrolling_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "werkscontrolling",
        name: "Werkscontrolling",
        skills: [
          {
            id: "werkscontrolling_maschinenstundensätze",
            name: "Maschinenstundensätze",
          },
          {
            id: "werkscontrolling_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "werkscontrolling_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "werkscontrolling_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "werkscontrolling_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "werkscontrolling_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "werkscontrolling_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "werkscontrolling_forecasting",
            name: "Forecasting",
          },
          {
            id: "werkscontrolling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "werkscontrolling_planung",
            name: "Planung",
          },
          {
            id: "werkscontrolling_reporting",
            name: "Reporting",
          },
          {
            id: "werkscontrolling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "werkscontrolling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "werkscontrolling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "werkscontrolling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "werkscontrolling_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "werkscontrolling_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "werkscontrolling_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "werkscontrolling_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "business_unit_controlling",
        name: "Business Unit Controlling",
        skills: [
          {
            id: "business_unit_controlling_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "business_unit_controlling_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "business_unit_controlling_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "business_unit_controlling_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "business_unit_controlling_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "business_unit_controlling_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "business_unit_controlling_forecasting",
            name: "Forecasting",
          },
          {
            id: "business_unit_controlling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "business_unit_controlling_planung",
            name: "Planung",
          },
          {
            id: "business_unit_controlling_reporting",
            name: "Reporting",
          },
          {
            id: "business_unit_controlling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
        ],
      },
      {
        id: "financial_analyst",
        name: "Financial Analyst",
        skills: [
          {
            id: "financial_analyst_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "financial_analyst_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "financial_analyst_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "financial_analyst_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "financial_analyst_forecasting",
            name: "Forecasting",
          },
          {
            id: "financial_analyst_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "financial_analyst_planung",
            name: "Planung",
          },
          {
            id: "financial_analyst_reporting",
            name: "Reporting",
          },
          {
            id: "financial_analyst_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "financial_analyst_bilanzanalyse",
            name: "Bilanzanalyse",
          },
          {
            id: "financial_analyst_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "financial_analyst_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "financial_analyst_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "financial_analyst_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "financial_analyst_erstellung_von_businessplänen",
            name: "Erstellung von Businessplänen",
          },
        ],
      },
      {
        id: "business_analyst",
        name: "Business Analyst",
        skills: [
          {
            id: "business_analyst_erstellung_von_businessplänen",
            name: "Erstellung von Businessplänen",
          },
          {
            id: "business_analyst_bilanzanalyse",
            name: "Bilanzanalyse",
          },
          {
            id: "business_analyst_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "business_analyst_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "business_analyst_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "business_analyst_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "personalcontrolling",
        name: "Personalcontrolling",
        skills: [
          {
            id: "personalcontrolling_personalkosten",
            name: "Personalkosten",
          },
          {
            id: "personalcontrolling_forecasting",
            name: "Forecasting",
          },
          {
            id: "personalcontrolling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "personalcontrolling_planung",
            name: "Planung",
          },
          {
            id: "personalcontrolling_reporting",
            name: "Reporting",
          },
          {
            id: "personalcontrolling_hr_prozesse",
            name: "HR Prozesse",
          },
          {
            id: "personalcontrolling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "personalcontrolling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "personalcontrolling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "personalcontrolling_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "kalkulator",
        name: "Kalkulator",
        skills: [
          {
            id: "kalkulator_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "kalkulator_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "kalkulator_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "kalkulator_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "kalkulator_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "kalkulator_pricing",
            name: "Pricing",
          },
          {
            id: "kalkulator_angebotskalulation",
            name: "Angebotskalulation",
          },
        ],
      },
      {
        id: "teamleiter_controlling",
        name: "Teamleiter Controlling",
        skills: [
          {
            id: "teamleiter_controlling_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "teamleiter_controlling_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "teamleiter_controlling_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "teamleiter_controlling_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "teamleiter_controlling_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "teamleiter_controlling_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "teamleiter_controlling_forecasting",
            name: "Forecasting",
          },
          {
            id: "teamleiter_controlling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "teamleiter_controlling_planung",
            name: "Planung",
          },
          {
            id: "teamleiter_controlling_reporting",
            name: "Reporting",
          },
          {
            id: "teamleiter_controlling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "teamleiter_controlling_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "teamleiter_controlling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "teamleiter_controlling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "teamleiter_controlling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "teamleiter_controlling_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "teamleiter_controlling_bab",
            name: "BAB",
          },
          {
            id: "teamleiter_controlling_sox",
            name: "SOX",
          },
          {
            id: "teamleiter_controlling_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "teamleiter_controlling_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "teamleiter_controlling_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "referent_controlling",
        name: "Referent Controlling",
        skills: [
          {
            id: "referent_controlling_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "referent_controlling_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "referent_controlling_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "referent_controlling_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "referent_controlling_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "referent_controlling_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "referent_controlling_forecasting",
            name: "Forecasting",
          },
          {
            id: "referent_controlling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "referent_controlling_planung",
            name: "Planung",
          },
          {
            id: "referent_controlling_reporting",
            name: "Reporting",
          },
          {
            id: "referent_controlling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "referent_controlling_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "referent_controlling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "referent_controlling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "referent_controlling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "referent_controlling_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "referent_controlling_bab",
            name: "BAB",
          },
          {
            id: "referent_controlling_sox",
            name: "SOX",
          },
          {
            id: "referent_controlling_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "referent_controlling_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "referent_controlling_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "beteiligungscontrolling",
        name: "Beteiligungscontrolling",
        skills: [
          {
            id: "beteiligungscontrolling_betreuung_tochtergesellschaten",
            name: "Betreuung Tochtergesellschaten",
          },
          {
            id: "beteiligungscontrolling_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "beteiligungscontrolling_mitwirkung_abschlüsse_hgb",
            name: "Mitwirkung Abschlüsse HGB",
          },
          {
            id: "beteiligungscontrolling_mitwirkung_abschlüsse_ifrs",
            name: "Mitwirkung Abschlüsse IFRS",
          },
          {
            id: "beteiligungscontrolling_mitwirkung_abschlüsse_usgaap",
            name: "Mitwirkung Abschlüsse US-GAAP",
          },
          {
            id: "beteiligungscontrolling_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "beteiligungscontrolling_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "beteiligungscontrolling_forecasting",
            name: "Forecasting",
          },
          {
            id: "beteiligungscontrolling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "beteiligungscontrolling_planung",
            name: "Planung",
          },
          {
            id: "beteiligungscontrolling_reporting",
            name: "Reporting",
          },
          {
            id: "beteiligungscontrolling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "beteiligungscontrolling_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "beteiligungscontrolling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "beteiligungscontrolling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "beteiligungscontrolling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "beteiligungscontrolling_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "beteiligungscontrolling_bab",
            name: "BAB",
          },
          {
            id: "beteiligungscontrolling_sox",
            name: "SOX",
          },
          {
            id: "beteiligungscontrolling_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
        ],
      },
      {
        id: "leiter_controlling",
        name: "Leiter Controlling",
        skills: [
          {
            id: "leiter_controlling_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "leiter_controlling_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "leiter_controlling_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "leiter_controlling_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "leiter_controlling_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "leiter_controlling_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "leiter_controlling_forecasting",
            name: "Forecasting",
          },
          {
            id: "leiter_controlling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "leiter_controlling_planung",
            name: "Planung",
          },
          {
            id: "leiter_controlling_reporting",
            name: "Reporting",
          },
          {
            id: "leiter_controlling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "leiter_controlling_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "leiter_controlling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "leiter_controlling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "leiter_controlling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "leiter_controlling_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "leiter_controlling_bab",
            name: "BAB",
          },
          {
            id: "leiter_controlling_sox",
            name: "SOX",
          },
          {
            id: "leiter_controlling_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "leiter_controlling_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "leiter_controlling_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "finance_manager",
        name: "Finance Manager",
        skills: [
          {
            id: "finance_manager_mitwirkung_abschlüsse",
            name: "Mitwirkung Abschlüsse",
            sub_skills: [
              {
                id: "finance_manager_mitwirkung_abschlüsse_hgb",
                name: "HGB",
              },
              {
                id: "finance_manager_mitwirkung_abschlüsse_ifrs",
                name: "IFRS",
              },
              {
                id: "finance_manager_mitwirkung_abschlüsse_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "finance_manager_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "finance_manager_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "finance_manager_forecasting",
            name: "Forecasting",
          },
          {
            id: "finance_manager_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "finance_manager_planung",
            name: "Planung",
          },
          {
            id: "finance_manager_reporting",
            name: "Reporting",
          },
          {
            id: "finance_manager_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "finance_manager_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "finance_manager_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "finance_manager_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "finance_manager_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "finance_manager_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "finance_manager_bab",
            name: "BAB",
          },
          {
            id: "finance_manager_sox",
            name: "SOX",
          },
          {
            id: "finance_manager_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "finance_manager_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "finance_manager_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "wirtschaftsprüfer",
        name: "Wirtschaftsprüfer",
        skills: [
          {
            id: "wirtschaftsprüfer_jahressabschlüsse_nach",
            name: "Jahressabschlüsse nach",
            sub_skills: [
              {
                id: "wirtschaftsprüfer_jahressabschlüsse_nach_hgb",
                name: "HGB",
              },
              {
                id: "wirtschaftsprüfer_jahressabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "wirtschaftsprüfer_jahressabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
            ],
          },
          {
            id: "wirtschaftsprüfer_betriebsprüfungen",
            name: "Betriebsprüfungen",
          },
          {
            id: "wirtschaftsprüfer_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "wirtschaftsprüfer_aufbau_controlling",
            name: "Aufbau Controlling",
          },
          {
            id: "wirtschaftsprüfer_consulting",
            name: "Consulting",
          },
        ],
      },
      {
        id: "experte_m&a",
        name: "Experte M&A",
        skills: [
          {
            id: "experte_m&a_unternehmensanalysen",
            name: "Unternehmensanalysen",
          },
          {
            id: "experte_m&a_unternehmensbewertungen",
            name: "Unternehmensbewertungen",
          },
          {
            id: "experte_m&a_finanz_und_planungsmodellen",
            name: "Finanz- und Planungsmodellen",
          },
          {
            id: "experte_m&a_markt_und_wettbewerbsanalysen",
            name: "Markt- und Wettbewerbsanalysen",
          },
          {
            id: "experte_m&a_erstellung_dokumente",
            name: "Erstellung Dokumente",
          },
          {
            id: "experte_m&a_pitch",
            name: "Pitch",
          },
          {
            id: "experte_m&a_due_diligence",
            name: "Due Diligence",
          },
          {
            id: "experte_m&a_sell_side",
            name: "Sell Side",
          },
          {
            id: "experte_m&a_buy_side",
            name: "Buy Side",
          },
          {
            id: "experte_m&a_kapitalmarkt",
            name: "Kapitalmarkt",
          },
          {
            id: "experte_m&a_transaktionsmanagement",
            name: "Transaktionsmanagement",
          },
          {
            id: "experte_m&a_closing",
            name: "Closing",
          },
          {
            id: "experte_m&a_beurteilung_von_businessmodellen",
            name: "Beurteilung von Businessmodellen",
          },
          {
            id: "experte_m&a_bewertung_businessmodelle",
            name: "Bewertung Businessmodelle",
          },
          {
            id: "experte_m&a_bilanzanalyse",
            name: "Bilanzanalyse",
          },
          {
            id: "experte_m&a_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "cfo",
        name: "CFO",
        skills: [
          {
            id: "cfo_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "cfo_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "cfo_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "cfo_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "cfo_jahressabschlüsse_nach_ifrs",
            name: "Jahressabschlüsse nach IFRS",
          },
          {
            id: "cfo_jahressabschlüsse_nach_usgaap",
            name: "Jahressabschlüsse nach US-GAAP",
          },
          {
            id: "cfo_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "cfo_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "cfo_digitalsierung",
            name: "Digitalsierung",
          },
          {
            id: "cfo_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "cfo_steuererklärungen",
            name: "Steuererklärungen",
          },
          {
            id: "cfo_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "cfo_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "cfo_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "cfo_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "cfo_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "cfo_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "cfo_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "cfo_reporting",
            name: "Reporting",
          },
          {
            id: "cfo_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "cfo_controlling",
            name: "Controlling",
          },
          {
            id: "cfo_shared_service_center",
            name: "Shared Service Center",
          },
          {
            id: "cfo_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "cfo_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "cfo_forecasting",
            name: "Forecasting",
          },
          {
            id: "cfo_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "cfo_planung",
            name: "Planung",
          },
          {
            id: "cfo_reporting",
            name: "Reporting",
          },
          {
            id: "cfo_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "cfo_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "cfo_sox",
            name: "SOX",
          },
          {
            id: "cfo_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "cfo_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "cfo_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
        ],
      },
      {
        id: "kaufmännischer_leiter",
        name: "Kaufmännischer Leiter",
        skills: [
          {
            id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach",
            name: "Monats-/Quartalsabschlüsse nach",
            sub_skills: [
              {
                id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach_ifrs",
                name: "IFRS",
              },
              {
                id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach_usgaap",
                name: "US-GAAP",
              },
              {
                id: "kaufmännischer_leiter_monatsquartalsabschlüsse_nach_hgb",
                name: "HGB",
              },
            ],
          },
          {
            id: "kaufmännischer_leiter_jahressabschlüsse_nach_ifrs",
            name: "Jahressabschlüsse nach IFRS",
          },
          {
            id: "kaufmännischer_leiter_jahressabschlüsse_nach_usgaap",
            name: "Jahressabschlüsse nach US-GAAP",
          },
          {
            id: "kaufmännischer_leiter_kontenabstimmung",
            name: "Kontenabstimmung",
          },
          {
            id: "kaufmännischer_leiter_konsolidierung",
            name: "Konsolidierung",
          },
          {
            id: "kaufmännischer_leiter_digitalsierung",
            name: "Digitalsierung",
          },
          {
            id: "kaufmännischer_leiter_intercompanyabstimmung",
            name: "Intercompany-Abstimmung",
          },
          {
            id: "kaufmännischer_leiter_steuererklärungen",
            name: "Steuererklärungen",
          },
          {
            id: "kaufmännischer_leiter_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "kaufmännischer_leiter_liquiditätsplanung",
            name: "Liquiditätsplanung",
          },
          {
            id: "kaufmännischer_leiter_interne_kontrollsysteme",
            name: "Interne Kontrollsysteme",
          },
          {
            id: "kaufmännischer_leiter_weiterentwicklung_prozesse",
            name: "Weiterentwicklung Prozesse",
          },
          {
            id: "kaufmännischer_leiter_weiterentwicklung_guidelines",
            name: "Weiterentwicklung Guidelines",
          },
          {
            id: "kaufmännischer_leiter_rückstellungen",
            name: "Rückstellungen",
          },
          {
            id: "kaufmännischer_leiter_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "kaufmännischer_leiter_reporting",
            name: "Reporting",
          },
          {
            id: "kaufmännischer_leiter_ansprechpartner_steuerberater/wirtschaftsorüfer",
            name: "Ansprechpartner Steuerberater/Wirtschaftsorüfer",
          },
          {
            id: "kaufmännischer_leiter_controlling",
            name: "Controlling",
          },
          {
            id: "kaufmännischer_leiter_shared_service_center",
            name: "Shared Service Center",
          },
          {
            id: "kaufmännischer_leiter_transfer_pricing",
            name: "Transfer Pricing",
          },
          {
            id: "kaufmännischer_leiter_kostenstellenrechnung",
            name: "Kostenstellenrechnung",
          },
          {
            id: "kaufmännischer_leiter_forecasting",
            name: "Forecasting",
          },
          {
            id: "kaufmännischer_leiter_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "kaufmännischer_leiter_planung",
            name: "Planung",
          },
          {
            id: "kaufmännischer_leiter_reporting",
            name: "Reporting",
          },
          {
            id: "kaufmännischer_leiter_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "kaufmännischer_leiter_financial_planning_&_analysis/fp&a",
            name: "Financial Planning & Analysis/FP&A",
          },
          {
            id: "kaufmännischer_leiter_sox",
            name: "SOX",
          },
          {
            id: "kaufmännischer_leiter_weiterentwicklung_kpis",
            name: "Weiterentwicklung KPIs",
          },
          {
            id: "kaufmännischer_leiter_investitionscontrolling",
            name: "Investitionscontrolling",
          },
          {
            id: "kaufmännischer_leiter_wirtschaftlichkeitsrechnung",
            name: "Wirtschaftlichkeitsrechnung",
          },
          {
            id: "kaufmännischer_leiter_einkauf",
            name: "Einkauf",
          },
          {
            id: "kaufmännischer_leiter_personalwesen",
            name: "Personalwesen",
          },
          {
            id: "kaufmännischer_leiter_it",
            name: "IT",
          },
        ],
      },
      {
        id: "projektmanager_digitalisierung",
        name: "Projektmanager Digitalisierung",
      },
      {
        id: "projektmanager_erpsystem",
        name: "Projektmanager ERP-System",
        skills: [
          {
            id: "projektmanager_erpsystem_sap_fi",
            name: "SAP FI",
          },
          {
            id: "projektmanager_erpsystem_sap_co",
            name: "SAP CO",
          },
          {
            id: "projektmanager_erpsystem_sap_bcs",
            name: "SAP BCS",
          },
          {
            id: "projektmanager_erpsystem_sap_4/hana",
            name: "SAP 4/Hana",
          },
          {
            id: "projektmanager_erpsystem_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "vertriebscontrolling",
        name: "Vertriebscontrolling",
        skills: [
          {
            id: "vertriebscontrolling_forecasting",
            name: "Forecasting",
          },
          {
            id: "vertriebscontrolling_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "vertriebscontrolling_planung",
            name: "Planung",
          },
          {
            id: "vertriebscontrolling_reporting",
            name: "Reporting",
          },
          {
            id: "vertriebscontrolling_deckungsbeitragsrechnung",
            name: "Deckungsbeitragsrechnung",
          },
          {
            id: "vertriebscontrolling_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "vertriebscontrolling_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "vertriebscontrolling_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "vertriebscontrolling_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "vertriebscontrolling_einführung_kpis",
            name: "Einführung KPIs",
          },
          {
            id: "vertriebscontrolling_wettbewerbsanalysen",
            name: "Wettbewerbsanalysen",
          },
          {
            id: "vertriebscontrolling_kundenauswertungen",
            name: "Kundenauswertungen",
          },
          {
            id: "vertriebscontrolling_mitwirkung_abschlüsse_hgb",
            name: "Mitwirkung Abschlüsse HGB",
          },
          {
            id: "vertriebscontrolling_mitwirkung_abschlüsse_ifrs",
            name: "Mitwirkung Abschlüsse IFRS",
          },
          {
            id: "vertriebscontrolling_mitwirkung_abschlüsse_usgaap",
            name: "Mitwirkung Abschlüsse US-GAAP",
          },
        ],
      },
    ],
  },
  {
    id: "human_resources",
    name: "Human Resources",
    sub_categories: [
      {
        id: "personalsachbearbeiter",
        name: "Personalsachbearbeiter",
        skills: [
          {
            id: "personalsachbearbeiter_stammdaten",
            name: "Stammdaten",
          },
          {
            id: "personalsachbearbeiter_entgeltabrechnung",
            name: "Entgeltabrechnung",
          },
          {
            id: "personalsachbearbeiter_eintritt_mitarbeiter",
            name: "Eintritt Mitarbeiter",
          },
          {
            id: "personalsachbearbeiter_austritt_mitarbeiter",
            name: "Austritt Mitarbeiter",
          },
          {
            id: "personalsachbearbeiter_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "personalsachbearbeiter_pflege_abrechnungssystem",
            name: "Pflege Abrechnungssystem",
          },
          {
            id: "personalsachbearbeiter_zeiterfassung",
            name: "Zeiterfassung",
          },
          {
            id: "personalsachbearbeiter_recruiting_national",
            name: "Recruiting national",
          },
          {
            id: "personalsachbearbeiter_recruiting_international",
            name: "Recruiting international",
          },
          {
            id: "personalsachbearbeiter_stellenausschreibungen_verfassen",
            name: "Stellenausschreibungen verfassen",
          },
          {
            id: "personalsachbearbeiter_stellen_schalten",
            name: "Stellen schalten",
          },
        ],
      },
      {
        id: "personalreferent",
        name: "Personalreferent",
        skills: [
          {
            id: "personalreferent_recruiting_national",
            name: "Recruiting national",
          },
          {
            id: "personalreferent_recruiting_international",
            name: "Recruiting international",
          },
          {
            id: "personalreferent_stellenausschreibungen_verfassen",
            name: "Stellenausschreibungen verfassen",
          },
          {
            id: "personalreferent_stellen_schalten",
            name: "Stellen schalten",
          },
          {
            id: "personalreferent_arbeitsrecht",
            name: "Arbeitsrecht",
          },
          {
            id: "personalreferent_betriebsrat",
            name: "Betriebsrat",
          },
          {
            id: "personalreferent_active_sourcing",
            name: "Active Sourcing",
          },
          {
            id: "personalreferent_optimierung_candidateexperience",
            name: "Optimierung Candidate-Experience",
          },
          {
            id: "personalreferent_employer_branding",
            name: "Employer Branding",
          },
          {
            id: "personalreferent_personalentwicklung",
            name: "Personalentwicklung",
          },
          {
            id: "personalreferent_onboarding",
            name: "Onboarding",
          },
          {
            id: "personalreferent_offboarding",
            name: "Offboarding",
          },
          {
            id: "personalreferent_personalgespräche",
            name: "Personalgespräche",
          },
          {
            id: "personalreferent_digitalisierung",
            name: "Digitalisierung",
          },
          {
            id: "personalreferent_lohn_und_gehalt",
            name: "Lohn und Gehalt",
          },
          {
            id: "personalreferent_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "hr_business_partner",
        name: "HR Business Partner",
        skills: [
          {
            id: "hr_business_partner_recruiting_national",
            name: "Recruiting national",
          },
          {
            id: "hr_business_partner_recruiting_international",
            name: "Recruiting international",
          },
          {
            id: "hr_business_partner_stellenausschreibungen_verfassen",
            name: "Stellenausschreibungen verfassen",
          },
          {
            id: "hr_business_partner_stellen_schalten",
            name: "Stellen schalten",
          },
          {
            id: "hr_business_partner_arbeitsrecht",
            name: "Arbeitsrecht",
          },
          {
            id: "hr_business_partner_betriebsrat",
            name: "Betriebsrat",
          },
          {
            id: "hr_business_partner_active_sourcing",
            name: "Active Sourcing",
          },
          {
            id: "hr_business_partner_optimierung_candidateexperience",
            name: "Optimierung Candidate-Experience",
          },
          {
            id: "hr_business_partner_employer_branding",
            name: "Employer Branding",
          },
          {
            id: "hr_business_partner_personalentwicklung",
            name: "Personalentwicklung",
          },
          {
            id: "hr_business_partner_onboarding",
            name: "Onboarding",
          },
          {
            id: "hr_business_partner_offboarding",
            name: "Offboarding",
          },
          {
            id: "hr_business_partner_personalgespräche",
            name: "Personalgespräche",
          },
          {
            id: "hr_business_partner_digitalisierung",
            name: "Digitalisierung",
          },
          {
            id: "hr_business_partner_lohn_und_gehalt",
            name: "Lohn und Gehalt",
          },
          {
            id: "hr_business_partner_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "recruiter",
        name: "Recruiter",
        skills: [
          {
            id: "recruiter_stellenausschreibungen_verfassen",
            name: "Stellenausschreibungen verfassen",
          },
          {
            id: "recruiter_stellen_schalten",
            name: "Stellen schalten",
          },
          {
            id: "recruiter_vorstellungsgespräche",
            name: "Vorstellungsgespräche",
          },
          {
            id: "recruiter_telefoninterviews",
            name: "Telefoninterviews",
          },
          {
            id: "recruiter_videointerviews",
            name: "Videointerviews",
          },
          {
            id: "recruiter_recruitingevents",
            name: "Recruiting-Events",
          },
          {
            id: "recruiter_active_sourcing",
            name: "Active Sourcing",
          },
          {
            id: "recruiter_optimierung_candidateexperience",
            name: "Optimierung Candidate-Experience",
          },
          {
            id: "recruiter_employer_branding",
            name: "Employer Branding",
          },
          {
            id: "recruiter_networking",
            name: "Networking",
          },
          {
            id: "recruiter_recruiting_national",
            name: "Recruiting national",
          },
          {
            id: "recruiter_recruiting_international",
            name: "Recruiting international",
          },
        ],
      },
      {
        id: "personalentwickler",
        name: "Personalentwickler",
        skills: [
          {
            id: "personalentwickler_konzeption_personalentwicklungsintrumente",
            name: "Konzeption Personalentwicklungsintrumente",
          },
          {
            id: "personalentwickler_talentmanagement",
            name: "Talentmanagement",
          },
          {
            id: "personalentwickler_performancemanagement",
            name: "Performancemanagement",
          },
          {
            id: "personalentwickler_onboarding",
            name: "Onboarding",
          },
          {
            id: "personalentwickler_offboarding",
            name: "Offboarding",
          },
          {
            id: "personalentwickler_trainings",
            name: "Trainings",
          },
          {
            id: "personalentwickler_potentialanalysen",
            name: "Potentialanalysen",
          },
          {
            id: "personalentwickler_potentialworkshops",
            name: "Potential-Workshops",
          },
          {
            id: "personalentwickler_wissenstransfer",
            name: "Wissenstransfer",
          },
          {
            id: "personalentwickler_aufbau_lernplattform",
            name: "Aufbau Lernplattform",
          },
          {
            id: "personalentwickler_digitalisierung",
            name: "Digitalisierung",
          },
        ],
      },
      {
        id: "lohn_und_gehaltsabrechner",
        name: "Lohn- und Gehaltsabrechner",
        skills: [
          {
            id: "lohn_und_gehaltsabrechner_stammdaten",
            name: "Stammdaten",
          },
          {
            id: "lohn_und_gehaltsabrechner_entgeltabrechnung",
            name: "Entgeltabrechnung",
          },
          {
            id: "lohn_und_gehaltsabrechner_eintritt_mitarbeiter",
            name: "Eintritt Mitarbeiter",
          },
          {
            id: "lohn_und_gehaltsabrechner_austritt_mitarbeiter",
            name: "Austritt Mitarbeiter",
          },
          {
            id: "lohn_und_gehaltsabrechner_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "lohn_und_gehaltsabrechner_baulohn",
            name: "Baulohn",
          },
          {
            id: "lohn_und_gehaltsabrechner_stundenlohn",
            name: "Stundenlohn",
          },
          {
            id: "lohn_und_gehaltsabrechner_sozialversicherungsrecht",
            name: "Sozialversicherungsrecht",
          },
          {
            id: "lohn_und_gehaltsabrechner_reporting",
            name: "Reporting",
          },
          {
            id: "lohn_und_gehaltsabrechner_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "lohn_und_gehaltsabrechner_pflege_abrechnungssystem",
            name: "Pflege Abrechnungssystem",
          },
          {
            id: "lohn_und_gehaltsabrechner_zeiterfassung",
            name: "Zeiterfassung",
          },
        ],
      },
      {
        id: "leiter_personal",
        name: "Leiter Personal",
        skills: [
          {
            id: "leiter_personal_recruiting_national",
            name: "Recruiting national",
          },
          {
            id: "leiter_personal_recruiting_international",
            name: "Recruiting international",
          },
          {
            id: "leiter_personal_stellenausschreibungen_verfassen",
            name: "Stellenausschreibungen verfassen",
          },
          {
            id: "leiter_personal_stellen_schalten",
            name: "Stellen schalten",
          },
          {
            id: "leiter_personal_arbeitsrecht",
            name: "Arbeitsrecht",
          },
          {
            id: "leiter_personal_betriebsrat",
            name: "Betriebsrat",
          },
          {
            id: "leiter_personal_active_sourcing",
            name: "Active Sourcing",
          },
          {
            id: "leiter_personal_optimierung_candidateexperience",
            name: "Optimierung Candidate-Experience",
          },
          {
            id: "leiter_personal_employer_branding",
            name: "Employer Branding",
          },
          {
            id: "leiter_personal_personalentwicklung",
            name: "Personalentwicklung",
          },
          {
            id: "leiter_personal_onboarding",
            name: "Onboarding",
          },
          {
            id: "leiter_personal_offboarding",
            name: "Offboarding",
          },
          {
            id: "leiter_personal_personalgespräche",
            name: "Personalgespräche",
          },
          {
            id: "leiter_personal_digitalisierung",
            name: "Digitalisierung",
          },
          {
            id: "leiter_personal_lohn_und_gehalt",
            name: "Lohn und Gehalt",
          },
          {
            id: "leiter_personal_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "experte_arbeitsrecht",
        name: "Experte Arbeitsrecht",
        skills: [
          {
            id: "experte_arbeitsrecht_arbeitsrecht",
            name: "Arbeitsrecht",
          },
          {
            id: "experte_arbeitsrecht_sozialversicherungsrecht",
            name: "Sozialversicherungsrecht",
          },
          {
            id: "experte_arbeitsrecht_betriebsvereinbarungen",
            name: "Betriebsvereinbarungen",
          },
          {
            id: "experte_arbeitsrecht_betriebsrat",
            name: "Betriebsrat",
          },
          {
            id: "experte_arbeitsrecht_arbeitsrechtliche_streitigkeiten",
            name: "Arbeitsrechtliche Streitigkeiten",
          },
          {
            id: "experte_arbeitsrecht_rechtsanwalt",
            name: "Rechtsanwalt",
          },
          {
            id: "experte_arbeitsrecht_syndikusanwalt",
            name: "Syndikusanwalt",
          },
          {
            id: "experte_arbeitsrecht_vergütungssysteme",
            name: "Vergütungssysteme",
          },
          {
            id: "experte_arbeitsrecht_gewerkschaften",
            name: "Gewerkschaften",
          },
          {
            id: "experte_arbeitsrecht_mediation",
            name: "Mediation",
          },
          {
            id: "experte_arbeitsrecht_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "personalcontroller",
        name: "Personalcontroller",
        skills: [
          {
            id: "personalcontroller_personalkosten",
            name: "Personalkosten",
          },
          {
            id: "personalcontroller_forecasting",
            name: "Forecasting",
          },
          {
            id: "personalcontroller_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "personalcontroller_planung",
            name: "Planung",
          },
          {
            id: "personalcontroller_reporting",
            name: "Reporting",
          },
          {
            id: "personalcontroller_hr_prozesse",
            name: "HR Prozesse",
          },
          {
            id: "personalcontroller_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "personalcontroller_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "personalcontroller_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "personalcontroller_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
    ],
  },
  {
    id: "office_und_administration",
    name: "Office und Administration",
    sub_categories: [
      {
        id: "assistenz_abteilungteam",
        name: "Assistenz Abteilung/Team",
        skills: [
          {
            id: "assistenz_abteilungteam_terminabstimmung",
            name: "Terminabstimmung",
          },
          {
            id: "assistenz_abteilungteam_terminkoordination",
            name: "Terminkoordination",
          },
          {
            id: "assistenz_abteilungteam_vor/nachbereitung_meetings",
            name: "Vor-/Nachbereitung Meetings",
          },
          {
            id: "assistenz_abteilungteam_korrespondenz",
            name: "Korrespondenz",
          },
          {
            id: "assistenz_abteilungteam_erstellung_präsentationen",
            name: "Erstellung Präsentationen",
          },
          {
            id: "assistenz_abteilungteam_projektmanagement",
            name: "Projektmanagement",
          },
          {
            id: "assistenz_abteilungteam_sekretariatsaufgaben",
            name: "Sekretariatsaufgaben",
          },
          {
            id: "assistenz_abteilungteam_erstellung_auswertungen",
            name: "Erstellung Auswertungen",
          },
          {
            id: "assistenz_abteilungteam_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "assistenz_geschäftsführung",
        name: "Assistenz Geschäftsführung",
        skills: [
          {
            id: "assistenz_geschäftsführung_terminabstimmung",
            name: "Terminabstimmung",
          },
          {
            id: "assistenz_geschäftsführung_terminkoordination",
            name: "Terminkoordination",
          },
          {
            id: "assistenz_geschäftsführung_vor/nachbereitung_meetings",
            name: "Vor-/Nachbereitung Meetings",
          },
          {
            id: "assistenz_geschäftsführung_reiseplanung",
            name: "Reiseplanung",
          },
          {
            id: "assistenz_geschäftsführung_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "assistenz_geschäftsführung_korrespondenz",
            name: "Korrespondenz",
          },
          {
            id: "assistenz_geschäftsführung_erstellung_präsentationen",
            name: "Erstellung Präsentationen",
          },
          {
            id: "assistenz_geschäftsführung_projektmanagement",
            name: "Projektmanagement",
          },
          {
            id: "assistenz_geschäftsführung_sekretariatsaufgaben",
            name: "Sekretariatsaufgaben",
          },
          {
            id: "assistenz_geschäftsführung_erstellung_auswertungen",
            name: "Erstellung Auswertungen",
          },
          {
            id: "assistenz_geschäftsführung_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "sachbearbeitung",
        name: "Sachbearbeitung",
        skills: [
          {
            id: "sachbearbeitung_auftragsbearbeitung",
            name: "Auftragsbearbeitung",
          },
          {
            id: "sachbearbeitung_angebotserstellung",
            name: "Angebotserstellung",
          },
          {
            id: "sachbearbeitung_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "sachbearbeitung_lieferscheinerstellung",
            name: "Lieferscheinerstellung",
          },
          {
            id: "sachbearbeitung_einsatzberatung",
            name: "Einsatzberatung",
          },
          {
            id: "sachbearbeitung_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "sachbearbeitung_disposition",
            name: "Disposition",
          },
          {
            id: "sachbearbeitung_fakturierung",
            name: "Fakturierung",
          },
          {
            id: "sachbearbeitung_auftragsabwicklung",
            name: "Auftragsabwicklung",
          },
          {
            id: "sachbearbeitung_bestellabwicklung",
            name: "Bestellabwicklung",
          },
          {
            id: "sachbearbeitung_lieferterminkoordination",
            name: "Lieferterminkoordination",
          },
          {
            id: "sachbearbeitung_reklamationsbearbeitung",
            name: "Reklamationsbearbeitung",
          },
          {
            id: "sachbearbeitung_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "sachbearbeitung_stammdatenpflege",
            name: "Stammdatenpflege",
          },
          {
            id: "sachbearbeitung_lieferantenanlage",
            name: "Lieferantenanlage",
          },
          {
            id: "sachbearbeitung_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "referent_geschäftsführung",
        name: "Referent Geschäftsführung",
        skills: [
          {
            id: "referent_geschäftsführung_terminabstimmung",
            name: "Terminabstimmung",
          },
          {
            id: "referent_geschäftsführung_terminkoordination",
            name: "Terminkoordination",
          },
          {
            id: "referent_geschäftsführung_projektmanagement",
            name: "Projektmanagement",
          },
          {
            id: "referent_geschäftsführung_erstellung_auswertungen",
            name: "Erstellung Auswertungen",
          },
          {
            id: "referent_geschäftsführung_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "referent_geschäftsführung_budgetierung",
            name: "Budgetierung",
          },
          {
            id: "referent_geschäftsführung_planung",
            name: "Planung",
          },
          {
            id: "referent_geschäftsführung_reporting",
            name: "Reporting",
          },
          {
            id: "referent_geschäftsführung_ad_hocauswertungen",
            name: "Ad hoc-Auswertungen",
          },
          {
            id: "referent_geschäftsführung_analysen/auswertungen",
            name: "Analysen/Auswertungen",
          },
          {
            id: "referent_geschäftsführung_sollistvergleiche",
            name: "Soll-Ist-Vergleiche",
          },
          {
            id: "referent_geschäftsführung_erstellung_präsentationen",
            name: "Erstellung Präsentationen",
          },
        ],
      },
      {
        id: "empfangsmitarbeiter",
        name: "Empfangsmitarbeiter",
        skills: [
          {
            id: "empfangsmitarbeiter_telefonzentrale",
            name: "Telefonzentrale",
          },
          {
            id: "empfangsmitarbeiter_posteingang",
            name: "Posteingang",
          },
          {
            id: "empfangsmitarbeiter_postausgang",
            name: "Postausgang",
          },
          {
            id: "empfangsmitarbeiter_reiseplanung",
            name: "Reiseplanung",
          },
          {
            id: "empfangsmitarbeiter_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "empfangsmitarbeiter_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "fuhrpark_verantwortlicher",
        name: "Fuhrpark Verantwortlicher",
        skills: [
          {
            id: "fuhrpark_verantwortlicher_fuhrparkmanagement",
            name: "Fuhrparkmanagement",
          },
          {
            id: "fuhrpark_verantwortlicher_reiseplanung",
            name: "Reiseplanung",
          },
          {
            id: "fuhrpark_verantwortlicher_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "fuhrpark_verantwortlicher_leasingkoordination",
            name: "Leasingkoordination",
          },
          {
            id: "fuhrpark_verantwortlicher_tankkartenverwaltung",
            name: "Tankkartenverwaltung",
          },
          {
            id: "fuhrpark_verantwortlicher_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "fuhrpark_verantwortlicher_steuerung_fahrzeugpool",
            name: "Steuerung Fahrzeugpool",
          },
          {
            id: "fuhrpark_verantwortlicher_führerscheinkontrolle",
            name: "Führerscheinkontrolle",
          },
        ],
      },
      {
        id: "office_manager",
        name: "Office Manager",
        skills: [
          {
            id: "office_manager_terminabstimmung",
            name: "Terminabstimmung",
          },
          {
            id: "office_manager_terminkoordination",
            name: "Terminkoordination",
          },
          {
            id: "office_manager_vor/nachbereitung_meetings",
            name: "Vor-/Nachbereitung Meetings",
          },
          {
            id: "office_manager_telefonzentrale",
            name: "Telefonzentrale",
          },
          {
            id: "office_manager_posteingang",
            name: "Posteingang",
          },
          {
            id: "office_manager_fuhrparkmanagement",
            name: "Fuhrparkmanagement",
          },
          {
            id: "office_manager_eventplanung",
            name: "Eventplanung",
          },
          {
            id: "office_manager_reiseplanung",
            name: "Reiseplanung",
          },
          {
            id: "office_manager_reisekosten",
            name: "Reisekosten",
          },
          {
            id: "office_manager_sachbearbeitung",
            name: "Sachbearbeitung",
          },
          {
            id: "office_manager_büromaterialbestellung",
            name: "Büromaterialbestellung",
          },
          {
            id: "office_manager_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
    ],
  },
  {
    id: "sales_und_business_development",
    name: "Sales und Business Development",
    sub_categories: [
      {
        id: "vertriebsinnendienst",
        name: "Vertriebsinnendienst",
        skills: [
          {
            id: "vertriebsinnendienst_kundenakquise",
            name: "Kundenakquise",
          },
          {
            id: "vertriebsinnendienst_kundenbetreuung",
            name: "Kundenbetreuung",
          },
          {
            id: "vertriebsinnendienst_auftragsbearbeitung",
            name: "Auftragsbearbeitung",
          },
          {
            id: "vertriebsinnendienst_angebotserstellung",
            name: "Angebotserstellung",
          },
          {
            id: "vertriebsinnendienst_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "vertriebsinnendienst_lieferscheinerstellung",
            name: "Lieferscheinerstellung",
          },
          {
            id: "vertriebsinnendienst_einsatzberatung",
            name: "Einsatzberatung",
          },
          {
            id: "vertriebsinnendienst_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "vertriebsinnendienst_disposition",
            name: "Disposition",
          },
          {
            id: "vertriebsinnendienst_fakturierung",
            name: "Fakturierung",
          },
          {
            id: "vertriebsinnendienst_auftragsabwicklung",
            name: "Auftragsabwicklung",
          },
          {
            id: "vertriebsinnendienst_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "vertriebsaussendienst",
        name: "Vertriebsaussendienst",
        skills: [
          {
            id: "vertriebsaussendienst_kundenakquise",
            name: "Kundenakquise",
          },
          {
            id: "vertriebsaussendienst_kundenbetreuung",
            name: "Kundenbetreuung",
          },
          {
            id: "vertriebsaussendienst_auftragsbearbeitung",
            name: "Auftragsbearbeitung",
          },
          {
            id: "vertriebsaussendienst_angebotserstellung",
            name: "Angebotserstellung",
          },
          {
            id: "vertriebsaussendienst_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "vertriebsaussendienst_lieferscheinerstellung",
            name: "Lieferscheinerstellung",
          },
          {
            id: "vertriebsaussendienst_einsatzberatung",
            name: "Einsatzberatung",
          },
          {
            id: "vertriebsaussendienst_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "vertriebsaussendienst_disposition",
            name: "Disposition",
          },
          {
            id: "vertriebsaussendienst_fakturierung",
            name: "Fakturierung",
          },
          {
            id: "vertriebsaussendienst_key_account_management",
            name: "Key Account Management",
          },
        ],
      },
      {
        id: "key_account_manager",
        name: "Key Account Manager",
        skills: [
          {
            id: "key_account_manager_kundenbetreuung",
            name: "Kundenbetreuung",
          },
          {
            id: "key_account_manager_auftragsbearbeitung",
            name: "Auftragsbearbeitung",
          },
          {
            id: "key_account_manager_angebotserstellung",
            name: "Angebotserstellung",
          },
          {
            id: "key_account_manager_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "key_account_manager_lieferscheinerstellung",
            name: "Lieferscheinerstellung",
          },
          {
            id: "key_account_manager_einsatzberatung",
            name: "Einsatzberatung",
          },
          {
            id: "key_account_manager_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "key_account_manager_disposition",
            name: "Disposition",
          },
          {
            id: "key_account_manager_fakturierung",
            name: "Fakturierung",
          },
          {
            id: "key_account_manager_key_account_management",
            name: "Key Account Management",
          },
          {
            id: "key_account_manager_marktanalysen",
            name: "Marktanalysen",
          },
          {
            id: "key_account_manager_vertragsmanagement",
            name: "Vertragsmanagement",
          },
          {
            id: "key_account_manager_reporting",
            name: "Reporting",
          },
          {
            id: "key_account_manager_strategische_kundenentwicklung",
            name: "Strategische Kundenentwicklung",
          },
          {
            id: "key_account_manager_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "key_account_manager_wettbewerbsanalysen",
            name: "Wettbewerbsanalysen",
          },
        ],
      },
      {
        id: "account_manager",
        name: "Account Manager",
        skills: [
          {
            id: "account_manager_kundenakquise",
            name: "Kundenakquise",
          },
          {
            id: "account_manager_kundenbetreuung",
            name: "Kundenbetreuung",
          },
          {
            id: "account_manager_auftragsbearbeitung",
            name: "Auftragsbearbeitung",
          },
          {
            id: "account_manager_angebotserstellung",
            name: "Angebotserstellung",
          },
          {
            id: "account_manager_rechnungsprüfung",
            name: "Rechnungsprüfung",
          },
          {
            id: "account_manager_lieferscheinerstellung",
            name: "Lieferscheinerstellung",
          },
          {
            id: "account_manager_einsatzberatung",
            name: "Einsatzberatung",
          },
          {
            id: "account_manager_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "account_manager_disposition",
            name: "Disposition",
          },
          {
            id: "account_manager_fakturierung",
            name: "Fakturierung",
          },
          {
            id: "account_manager_auftragsabwicklung",
            name: "Auftragsabwicklung",
          },
          {
            id: "account_manager_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "business_development_manager",
        name: "Business Development Manager",
        skills: [
          {
            id: "business_development_manager_produktmanagement",
            name: "Produktmanagement",
          },
          {
            id: "business_development_manager_marketingmaßnahmen",
            name: "Marketingmaßnahmen",
          },
          {
            id: "business_development_manager_projektmanagement",
            name: "Projektmanagement",
          },
          {
            id: "business_development_manager_key_account_management",
            name: "Key Account Management",
          },
          {
            id: "business_development_manager_marktanalysen",
            name: "Marktanalysen",
          },
          {
            id: "business_development_manager_vertragsmanagement",
            name: "Vertragsmanagement",
          },
          {
            id: "business_development_manager_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "business_development_manager_reporting",
            name: "Reporting",
          },
          {
            id: "business_development_manager_strategische_kundenentwicklung",
            name: "Strategische Kundenentwicklung",
          },
          {
            id: "business_development_manager_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "business_development_manager_wettbewerbsanalysen",
            name: "Wettbewerbsanalysen",
          },
        ],
      },
      {
        id: "gebietsleiter",
        name: "Gebietsleiter",
        skills: [
          {
            id: "gebietsleiter_kundenbetreuung",
            name: "Kundenbetreuung",
          },
          {
            id: "gebietsleiter_marketingmaßnahmen",
            name: "Marketingmaßnahmen",
          },
          {
            id: "gebietsleiter_projektmanagement",
            name: "Projektmanagement",
          },
          {
            id: "gebietsleiter_marktsegmentierung",
            name: "Marktsegmentierung",
          },
          {
            id: "gebietsleiter_reporting",
            name: "Reporting",
          },
          {
            id: "gebietsleiter_vertragsmanagement",
            name: "Vertragsmanagement",
          },
          {
            id: "gebietsleiter_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "gebietsleiter_strategische_kundenentwicklung",
            name: "Strategische Kundenentwicklung",
          },
          {
            id: "gebietsleiter_pricing",
            name: "Pricing",
          },
          {
            id: "gebietsleiter_key_account_management",
            name: "Key Account Management",
          },
          {
            id: "gebietsleiter_marktanalysen",
            name: "Marktanalysen",
          },
          {
            id: "gebietsleiter_vertragsmanagement",
            name: "Vertragsmanagement",
          },
        ],
      },
      {
        id: "vertriebsleiter",
        name: "Vertriebsleiter",
        skills: [
          {
            id: "vertriebsleiter_kundenbetreuung",
            name: "Kundenbetreuung",
          },
          {
            id: "vertriebsleiter_marketingmaßnahmen",
            name: "Marketingmaßnahmen",
          },
          {
            id: "vertriebsleiter_projektmanagement",
            name: "Projektmanagement",
          },
          {
            id: "vertriebsleiter_marktsegmentierung",
            name: "Marktsegmentierung",
          },
          {
            id: "vertriebsleiter_reporting",
            name: "Reporting",
          },
          {
            id: "vertriebsleiter_vertragsmanagement",
            name: "Vertragsmanagement",
          },
          {
            id: "vertriebsleiter_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "vertriebsleiter_strategische_kundenentwicklung",
            name: "Strategische Kundenentwicklung",
          },
          {
            id: "vertriebsleiter_pricing",
            name: "Pricing",
          },
          {
            id: "vertriebsleiter_key_account_management",
            name: "Key Account Management",
          },
          {
            id: "vertriebsleiter_marktanalysen",
            name: "Marktanalysen",
          },
          {
            id: "vertriebsleiter_vertragsmanagement",
            name: "Vertragsmanagement",
          },
        ],
      },
      {
        id: "cso",
        name: "CSO",
        skills: [
          {
            id: "cso_kundenbetreuung",
            name: "Kundenbetreuung",
          },
          {
            id: "cso_marketingmaßnahmen",
            name: "Marketingmaßnahmen",
          },
          {
            id: "cso_projektmanagement",
            name: "Projektmanagement",
          },
          {
            id: "cso_marktsegmentierung",
            name: "Marktsegmentierung",
          },
          {
            id: "cso_reporting",
            name: "Reporting",
          },
          {
            id: "cso_vertragsmanagement",
            name: "Vertragsmanagement",
          },
          {
            id: "cso_kundenentwicklung",
            name: "Kundenentwicklung",
          },
          {
            id: "cso_strategische_kundenentwicklung",
            name: "Strategische Kundenentwicklung",
          },
          {
            id: "cso_pricing",
            name: "Pricing",
          },
          {
            id: "cso_key_account_management",
            name: "Key Account Management",
          },
          {
            id: "cso_marktanalysen",
            name: "Marktanalysen",
          },
          {
            id: "cso_vertragsmanagement",
            name: "Vertragsmanagement",
          },
        ],
      },
    ],
  },
  {
    id: "einkauf_und_supply_chain",
    name: "Einkauf und Supply Chain",
    sub_categories: [
      {
        id: "einkaufssachbearbeiter",
        name: "Einkaufssachbearbeiter",
        skills: [
          {
            id: "einkaufssachbearbeiter_bestellabwicklung",
            name: "Bestellabwicklung",
          },
          {
            id: "einkaufssachbearbeiter_lieferterminkoordination",
            name: "Lieferterminkoordination",
          },
          {
            id: "einkaufssachbearbeiter_reklamationsbearbeitung",
            name: "Reklamationsbearbeitung",
          },
          {
            id: "einkaufssachbearbeiter_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "einkaufssachbearbeiter_stammdatenpflege",
            name: "Stammdatenpflege",
          },
          {
            id: "einkaufssachbearbeiter_lieferantenanlage",
            name: "Lieferantenanlage",
          },
          {
            id: "einkaufssachbearbeiter_preisvergleiche",
            name: "Preisvergleiche",
          },
          {
            id: "einkaufssachbearbeiter_inventur",
            name: "Inventur",
          },
          {
            id: "einkaufssachbearbeiter_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "einkaufssachbearbeiter_disposition",
            name: "Disposition",
          },
        ],
      },
      {
        id: "operativer_einkäufer",
        name: "Operativer Einkäufer",
        skills: [
          {
            id: "operativer_einkäufer_bestellabwicklung",
            name: "Bestellabwicklung",
          },
          {
            id: "operativer_einkäufer_lieferterminkoordination",
            name: "Lieferterminkoordination",
          },
          {
            id: "operativer_einkäufer_reklamationsbearbeitung",
            name: "Reklamationsbearbeitung",
          },
          {
            id: "operativer_einkäufer_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "operativer_einkäufer_ausschreibungen",
            name: "Ausschreibungen",
          },
          {
            id: "operativer_einkäufer_stammdatenpflege",
            name: "Stammdatenpflege",
          },
          {
            id: "operativer_einkäufer_lieferantenanlage",
            name: "Lieferantenanlage",
          },
          {
            id: "operativer_einkäufer_nachunternehmerleistungen",
            name: "Nachunternehmerleistungen",
          },
          {
            id: "operativer_einkäufer_preisvergleiche",
            name: "Preisvergleiche",
          },
          {
            id: "operativer_einkäufer_lieferantenaudits",
            name: "Lieferantenaudits",
          },
          {
            id: "operativer_einkäufer_inventur",
            name: "Inventur",
          },
          {
            id: "operativer_einkäufer_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "operativer_einkäufer_disposition",
            name: "Disposition",
          },
        ],
      },
      {
        id: "strategischer_einkäufer",
        name: "Strategischer Einkäufer",
        skills: [
          {
            id: "strategischer_einkäufer_preisverhandlungen",
            name: "Preisverhandlungen",
          },
          {
            id: "strategischer_einkäufer_vertragsverhandlungen",
            name: "Vertragsverhandlungen",
          },
          {
            id: "strategischer_einkäufer_global_sourcing",
            name: "Global Sourcing",
          },
          {
            id: "strategischer_einkäufer_musterteilbeschaffung",
            name: "Musterteilbeschaffung",
          },
          {
            id: "strategischer_einkäufer_marktbeobachtung",
            name: "Marktbeobachtung",
          },
          {
            id: "strategischer_einkäufer_beschaffungsplanung",
            name: "Beschaffungsplanung",
          },
          {
            id: "strategischer_einkäufer_lieferantenauswahl",
            name: "Lieferantenauswahl",
          },
          {
            id: "strategischer_einkäufer_ausschreibungen",
            name: "Ausschreibungen",
          },
          {
            id: "strategischer_einkäufer_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "strategischer_einkäufer_preisvergleiche",
            name: "Preisvergleiche",
          },
          {
            id: "strategischer_einkäufer_lieferantenaudits",
            name: "Lieferantenaudits",
          },
          {
            id: "strategischer_einkäufer_inventurbewertung",
            name: "Inventurbewertung",
          },
          {
            id: "strategischer_einkäufer_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "strategischer_einkäufer_lieferantenqualifizierung",
            name: "Lieferantenqualifizierung",
          },
        ],
      },
      {
        id: "lead_buyer",
        name: "Lead Buyer",
        skills: [
          {
            id: "lead_buyer_preisverhandlungen",
            name: "Preisverhandlungen",
          },
          {
            id: "lead_buyer_vertragsverhandlungen",
            name: "Vertragsverhandlungen",
          },
          {
            id: "lead_buyer_global_sourcing",
            name: "Global Sourcing",
          },
          {
            id: "lead_buyer_musterteilbeschaffung",
            name: "Musterteilbeschaffung",
          },
          {
            id: "lead_buyer_marktbeobachtung",
            name: "Marktbeobachtung",
          },
          {
            id: "lead_buyer_beschaffungsplanung",
            name: "Beschaffungsplanung",
          },
          {
            id: "lead_buyer_lieferantenauswahl",
            name: "Lieferantenauswahl",
          },
          {
            id: "lead_buyer_ausschreibungen",
            name: "Ausschreibungen",
          },
          {
            id: "lead_buyer_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "lead_buyer_preisvergleiche",
            name: "Preisvergleiche",
          },
          {
            id: "lead_buyer_lieferantenaudits",
            name: "Lieferantenaudits",
          },
          {
            id: "lead_buyer_inventurbewertung",
            name: "Inventurbewertung",
          },
          {
            id: "lead_buyer_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "lead_buyer_lieferantenqualifizierung",
            name: "Lieferantenqualifizierung",
          },
        ],
      },
      {
        id: "technischer_einkäufer",
        name: "Technischer Einkäufer",
        skills: [
          {
            id: "technischer_einkäufer_preisverhandlungen",
            name: "Preisverhandlungen",
          },
          {
            id: "technischer_einkäufer_vertragsverhandlungen",
            name: "Vertragsverhandlungen",
          },
          {
            id: "technischer_einkäufer_global_sourcing",
            name: "Global Sourcing",
          },
          {
            id: "technischer_einkäufer_musterteilbeschaffung",
            name: "Musterteilbeschaffung",
          },
          {
            id: "technischer_einkäufer_marktbeobachtung",
            name: "Marktbeobachtung",
          },
          {
            id: "technischer_einkäufer_beschaffungsplanung",
            name: "Beschaffungsplanung",
          },
          {
            id: "technischer_einkäufer_lieferantenauswahl",
            name: "Lieferantenauswahl",
          },
          {
            id: "technischer_einkäufer_ausschreibungen",
            name: "Ausschreibungen",
          },
          {
            id: "technischer_einkäufer_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "technischer_einkäufer_preisvergleiche",
            name: "Preisvergleiche",
          },
          {
            id: "technischer_einkäufer_lieferantenaudits",
            name: "Lieferantenaudits",
          },
          {
            id: "technischer_einkäufer_inventurbewertung",
            name: "Inventurbewertung",
          },
          {
            id: "technischer_einkäufer_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "technischer_einkäufer_lieferantenqualifizierung",
            name: "Lieferantenqualifizierung",
          },
          {
            id: "technischer_einkäufer_single_sourcing",
            name: "Single Sourcing",
          },
        ],
      },
      {
        id: "supply_chain_manager",
        name: "Supply Chain Manager",
        skills: [
          {
            id: "supply_chain_manager_steuerung_lieferkette",
            name: "Steuerung Lieferkette",
          },
          {
            id: "supply_chain_manager_musterteilbeschaffung",
            name: "Musterteilbeschaffung",
          },
          {
            id: "supply_chain_manager_global_sourcing",
            name: "Global Sourcing",
          },
          {
            id: "supply_chain_manager_lieferantenqualifizierung",
            name: "Lieferantenqualifizierung",
          },
          {
            id: "supply_chain_manager_beschaffungsplanung",
            name: "Beschaffungsplanung",
          },
          {
            id: "supply_chain_manager_speditionsauswahl",
            name: "Speditionsauswahl",
          },
          {
            id: "supply_chain_manager_ausschreibungen",
            name: "Ausschreibungen",
          },
          {
            id: "supply_chain_manager_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "supply_chain_manager_preisvergleiche",
            name: "Preisvergleiche",
          },
          {
            id: "supply_chain_manager_lieferantenaudits",
            name: "Lieferantenaudits",
          },
          {
            id: "supply_chain_manager_speditionsplanung",
            name: "Speditionsplanung",
          },
          {
            id: "supply_chain_manager_lean_management",
            name: "Lean Management",
          },
          {
            id: "supply_chain_manager_optimierung_supply_chain",
            name: "Optimierung Supply Chain",
          },
        ],
      },
      {
        id: "leiter_einkauf",
        name: "Leiter Einkauf",
        skills: [
          {
            id: "leiter_einkauf_preisverhandlungen",
            name: "Preisverhandlungen",
          },
          {
            id: "leiter_einkauf_vertragsverhandlungen",
            name: "Vertragsverhandlungen",
          },
          {
            id: "leiter_einkauf_global_sourcing",
            name: "Global Sourcing",
          },
          {
            id: "leiter_einkauf_musterteilbeschaffung",
            name: "Musterteilbeschaffung",
          },
          {
            id: "leiter_einkauf_marktbeobachtung",
            name: "Marktbeobachtung",
          },
          {
            id: "leiter_einkauf_beschaffungsplanung",
            name: "Beschaffungsplanung",
          },
          {
            id: "leiter_einkauf_lieferantenauswahl",
            name: "Lieferantenauswahl",
          },
          {
            id: "leiter_einkauf_ausschreibungen",
            name: "Ausschreibungen",
          },
          {
            id: "leiter_einkauf_angebotseinholung",
            name: "Angebotseinholung",
          },
          {
            id: "leiter_einkauf_preisvergleiche",
            name: "Preisvergleiche",
          },
          {
            id: "leiter_einkauf_lieferantenaudits",
            name: "Lieferantenaudits",
          },
          {
            id: "leiter_einkauf_inventurbewertung",
            name: "Inventurbewertung",
          },
          {
            id: "leiter_einkauf_sonstiges",
            name: "Sonstiges",
          },
          {
            id: "leiter_einkauf_lieferantenqualifizierung",
            name: "Lieferantenqualifizierung",
          },
        ],
      },
    ],
  },
  {
    id: "marketing",
    name: "Marketing",
    sub_categories: [
      {
        id: "referent_marketing",
        name: "Referent Marketing",
        skills: [
          {
            id: "referent_marketing_marketingkonzepte",
            name: "Marketingkonzepte",
          },
          {
            id: "referent_marketing_kommunikationspolitik",
            name: "Kommunikationspolitik",
          },
          {
            id: "referent_marketing_mediawerbung",
            name: "Mediawerbung",
          },
          {
            id: "referent_marketing_verkaufsförderung",
            name: "Verkaufsförderung",
          },
          {
            id: "referent_marketing_direktwerbung",
            name: "Direktwerbung",
          },
          {
            id: "referent_marketing_verkaufsförderung",
            name: "Verkaufsförderung",
          },
          {
            id: "referent_marketing_öffentlichkeitsarbeit",
            name: "Öffentlichkeitsarbeit",
          },
          {
            id: "referent_marketing_veranstaltungsmanagement",
            name: "Veranstaltungsmanagement",
          },
          {
            id: "referent_marketing_internetauftritt",
            name: "Internetauftritt",
          },
          {
            id: "referent_marketing_erstellen_mailings",
            name: "Erstellen Mailings",
          },
          {
            id: "referent_marketing_erstellung_werbematerial",
            name: "Erstellung Werbematerial",
          },
        ],
      },
      {
        id: "produktmanager",
        name: "Produktmanager",
        skills: [
          {
            id: "produktmanager_marktananalysen",
            name: "Marktananalysen",
          },
          {
            id: "produktmanager_kundenanalysen",
            name: "Kundenanalysen",
          },
          {
            id: "produktmanager_trendanalysen",
            name: "Trendanalysen",
          },
          {
            id: "produktmanager_weiterentwicklung_portfolio",
            name: "Weiterentwicklung Portfolio",
          },
          {
            id: "produktmanager_einführung_produkte",
            name: "Einführung Produkte",
          },
          {
            id: "produktmanager_einführung_dienstleistungen",
            name: "Einführung Dienstleistungen",
          },
          {
            id: "produktmanager_omnichannelvermarktung",
            name: "Omnichannel-Vermarktung",
          },
          {
            id: "produktmanager_pricing",
            name: "Pricing",
          },
          {
            id: "produktmanager_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "leiter_kommunikation",
        name: "Leiter Kommunikation",
        skills: [
          {
            id: "leiter_kommunikation_pr_strategie",
            name: "PR Strategie",
          },
          {
            id: "leiter_kommunikation_pr_maßnahmen",
            name: "PR Maßnahmen",
          },
          {
            id: "leiter_kommunikation_texterstellung",
            name: "Texterstellung",
          },
          {
            id: "leiter_kommunikation_verfassen_beiträge",
            name: "Verfassen Beiträge",
          },
          {
            id: "leiter_kommunikation_organisation_fotoshootings",
            name: "Organisation Fotoshootings",
          },
          {
            id: "leiter_kommunikation_hrkampagnen",
            name: "HR-Kampagnen",
          },
          {
            id: "leiter_kommunikation_social_media_betreuung",
            name: "Social Media Betreuung",
          },
          {
            id: "leiter_kommunikation_einheitliche_außenkommunikation",
            name: "Einheitliche Außenkommunikation",
          },
          {
            id: "leiter_kommunikation_entwicklung_ci/cd",
            name: "Entwicklung CI/CD",
          },
          {
            id: "leiter_kommunikation_steuerung_ci/cd",
            name: "Steuerung CI/CD",
          },
          {
            id: "leiter_kommunikation_unternehmenspositionierung",
            name: "Unternehmenspositionierung",
          },
          {
            id: "leiter_kommunikation_öffentlichkeitsarbeit",
            name: "Öffentlichkeitsarbeit",
          },
          {
            id: "leiter_kommunikation_messeplanung",
            name: "Messeplanung",
          },
          {
            id: "leiter_kommunikation_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "leiter_marketing",
        name: "Leiter Marketing",
        skills: [
          {
            id: "leiter_marketing_marketingkonzepte",
            name: "Marketingkonzepte",
          },
          {
            id: "leiter_marketing_kommunikationspolitik",
            name: "Kommunikationspolitik",
          },
          {
            id: "leiter_marketing_mediawerbung",
            name: "Mediawerbung",
          },
          {
            id: "leiter_marketing_verkaufsförderung",
            name: "Verkaufsförderung",
          },
          {
            id: "leiter_marketing_direktwerbung",
            name: "Direktwerbung",
          },
          {
            id: "leiter_marketing_verkaufsförderung",
            name: "Verkaufsförderung",
          },
          {
            id: "leiter_marketing_öffentlichkeitsarbeit",
            name: "Öffentlichkeitsarbeit",
          },
          {
            id: "leiter_marketing_veranstaltungsmanagement",
            name: "Veranstaltungsmanagement",
          },
          {
            id: "leiter_marketing_internetauftritt",
            name: "Internetauftritt",
          },
          {
            id: "leiter_marketing_erstellen_mailings",
            name: "Erstellen Mailings",
          },
          {
            id: "leiter_marketing_erstellung_werbematerial",
            name: "Erstellung Werbematerial",
          },
        ],
      },
      {
        id: "eventmanager",
        name: "Eventmanager",
        skills: [
          {
            id: "eventmanager_konzeption_veranstaltungen",
            name: "Konzeption Veranstaltungen",
          },
          {
            id: "eventmanager_umsetzung_programm",
            name: "Umsetzung Programm",
          },
          {
            id: "eventmanager_koordination_dienstleister",
            name: "Koordination Dienstleister",
          },
          {
            id: "eventmanager_koordination_referenten",
            name: "Koordination Referenten",
          },
          {
            id: "eventmanager_marketingplanung",
            name: "Marketingplanung",
          },
          {
            id: "eventmanager_social_media_marketing",
            name: "Social Media Marketing",
          },
          {
            id: "eventmanager_öffentlichkeitsarbeit",
            name: "Öffentlichkeitsarbeit",
          },
          {
            id: "eventmanager_organisation_messeauftritte",
            name: "Organisation Messeauftritte",
          },
          {
            id: "eventmanager_kostenkontrolle",
            name: "Kostenkontrolle",
          },
          {
            id: "eventmanager_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "digital_marketing",
        name: "Digital Marketing",
        skills: [
          {
            id: "digital_marketing_seo/sea",
            name: "SEO/SEA",
          },
          {
            id: "digital_marketing_entwicklung_digitaler_inhalte",
            name: "Entwicklung digitaler Inhalte",
          },
          {
            id: "digital_marketing_emailmarketing",
            name: "E-Mail-Marketing",
          },
          {
            id: "digital_marketing_social_media_marketing",
            name: "Social Media Marketing",
          },
          {
            id: "digital_marketing_monitoring_conversion_rates",
            name: "Monitoring Conversion Rates",
          },
          {
            id: "digital_marketing_omnichannel_kampagnen",
            name: "Omni-Channel Kampagnen",
          },
          {
            id: "digital_marketing_steuerung_agenturen",
            name: "Steuerung Agenturen",
          },
          {
            id: "digital_marketing_contentplanung",
            name: "Content-Planung",
          },
          {
            id: "digital_marketing_budgetplanung",
            name: "Budgetplanung",
          },
          {
            id: "digital_marketing_budgetkontrolle",
            name: "Budgetkontrolle",
          },
          {
            id: "digital_marketing_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "contentmanager",
        name: "Contentmanager",
        skills: [
          {
            id: "contentmanager_planung_social_media",
            name: "Planung Social Media",
          },
          {
            id: "contentmanager_planung_webinhalte",
            name: "Planung Webinhalte",
          },
          {
            id: "contentmanager_erstellen_bildinhalte",
            name: "Erstellen Bildinhalte",
          },
          {
            id: "contentmanager_erstellen_texte",
            name: "Erstellen Texte",
          },
          {
            id: "contentmanager_erstellung_multimediainhalte",
            name: "Erstellung Multimediainhalte",
          },
          {
            id: "contentmanager_redaktionspläne",
            name: "Redaktionspläne",
          },
          {
            id: "contentmanager_seo/sea",
            name: "SEO/SEA",
          },
        ],
      },
      {
        id: "mitarbeiter_kommunikation",
        name: "Mitarbeiter Kommunikation",
        skills: [
          {
            id: "mitarbeiter_kommunikation_pr_maßnahmen",
            name: "PR Maßnahmen",
          },
          {
            id: "mitarbeiter_kommunikation_texterstellung",
            name: "Texterstellung",
          },
          {
            id: "mitarbeiter_kommunikation_verfassen_beiträge",
            name: "Verfassen Beiträge",
          },
          {
            id: "mitarbeiter_kommunikation_organisation_fotoshootings",
            name: "Organisation Fotoshootings",
          },
          {
            id: "mitarbeiter_kommunikation_hrkampagnen",
            name: "HR-Kampagnen",
          },
          {
            id: "mitarbeiter_kommunikation_social_media_betreuung",
            name: "Social Media Betreuung",
          },
          {
            id: "mitarbeiter_kommunikation_einheitliche_außenkommunikation",
            name: "Einheitliche Außenkommunikation",
          },
          {
            id: "mitarbeiter_kommunikation_entwicklung_ci/cd",
            name: "Entwicklung CI/CD",
          },
          {
            id: "mitarbeiter_kommunikation_steuerung_ci/cd",
            name: "Steuerung CI/CD",
          },
          {
            id: "mitarbeiter_kommunikation_unternehmenspositionierung",
            name: "Unternehmenspositionierung",
          },
          {
            id: "mitarbeiter_kommunikation_öffentlichkeitsarbeit",
            name: "Öffentlichkeitsarbeit",
          },
          {
            id: "mitarbeiter_kommunikation_messeplanung",
            name: "Messeplanung",
          },
          {
            id: "mitarbeiter_kommunikation_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "mediaberatung",
        name: "Mediaberatung",
      },
      {
        id: "social_media_manager",
        name: "Social Media Manager",
        skills: [
          {
            id: "social_media_manager_entwicklung_kampagnen",
            name: "Entwicklung Kampagnen",
          },
          {
            id: "social_media_manager_monitoring_conversion_rates",
            name: "Monitoring Conversion Rates",
          },
          {
            id: "social_media_manager_marktbeobachtung",
            name: "Marktbeobachtung",
          },
          {
            id: "social_media_manager_verfassen_beiträge",
            name: "Verfassen Beiträge",
          },
          {
            id: "social_media_manager_organisation_fotoshootings",
            name: "Organisation Fotoshootings",
          },
        ],
      },
    ],
  },
  {
    id: "innovation",
    name: "Innovation",
    sub_categories: [
      {
        id: "innovationsmanager",
        name: "Innovationsmanager",
        skills: [
          {
            id: "innovationsmanager_ideenmanagement",
            name: "Ideenmanagement",
          },
          {
            id: "innovationsmanager_präsentation_innovationen",
            name: "Präsentation Innovationen",
          },
          {
            id: "innovationsmanager_konzepterstellung",
            name: "Konzepterstellung",
          },
          {
            id: "innovationsmanager_steuerung_innovationsmanagement",
            name: "Steuerung Innovationsmanagement",
          },
          {
            id: "innovationsmanager_inkubation",
            name: "Inkubation",
          },
          {
            id: "innovationsmanager_marktanalyse ",
            name: "Marktanalyse ",
          },
          {
            id: "innovationsmanager_validierung_prototypen",
            name: "Validierung Prototypen",
          },
          {
            id: "innovationsmanager_startup_scouting",
            name: "Startup Scouting",
          },
          {
            id: "innovationsmanager_prototyping",
            name: "Prototyping",
          },
          {
            id: "innovationsmanager_design_sprints",
            name: "Design Sprints",
          },
          {
            id: "innovationsmanager_startup_entwicklung",
            name: "Startup Entwicklung",
          },
          {
            id: "innovationsmanager_testing",
            name: "Testing",
          },
          {
            id: "innovationsmanager_bmc",
            name: "BMC",
          },
          {
            id: "innovationsmanager_sonstiges",
            name: "Sonstiges",
          },
        ],
      },
      {
        id: "startup_scout",
        name: "Startup Scout",
        skills: [
          {
            id: "startup_scout_ideenmanagement",
            name: "Ideenmanagement",
          },
          {
            id: "startup_scout_teilnahme_messen",
            name: "Teilnahme Messen",
          },
          {
            id: "startup_scout_konzepterstellung",
            name: "Konzepterstellung",
          },
          {
            id: "startup_scout_startup_scouting",
            name: "Startup Scouting",
          },
          {
            id: "startup_scout_inkubation",
            name: "Inkubation",
          },
          {
            id: "startup_scout_marktanalyse ",
            name: "Marktanalyse ",
          },
          {
            id: "startup_scout_validierung_prototypen",
            name: "Validierung Prototypen",
          },
        ],
      },
      {
        id: "manager_digitalisierung",
        name: "Manager Digitalisierung",
      },
      {
        id: "projektmanagement",
        name: "Projektmanagement",
      },
      {
        id: "venture_development",
        name: "Venture Development",
      },
      {
        id: "investment_manager",
        name: "Investment Manager",
      },
      {
        id: "business_anaylst",
        name: "Business Anaylst",
      },
      {
        id: "cdo",
        name: "CDO",
      },
    ],
  },
];
