<h1 *ngIf="slide.heading">{{ slide.heading }}</h1>
<h2 *ngIf="slide.question" class="pb" [innerHTML]="slide.question"></h2>

<ion-grid>
  <ion-row>
    <ion-col size="12"> Wie sehr stimmst du dieser Aussage zu? </ion-col>
    <ion-col *ngIf="slide.answers && !dataService.isMobile" offsetMd="1" sizeMd="3">
      <ion-card>
        <ion-card-content style="padding-bottom: 13px !important">
          {{ slide.answers[0].name }}
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col *ngIf="slide.answers" [offset]="dataService.isMobile?0:4" [size]="dataService.isMobile?12:3" >
      <ion-card>
        <ion-card-content style="padding-bottom: 13px !important">
          {{ slide.answers[1].name }}
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col offset="1" size="10" class="slider">
      <ngx-slider [(value)]="value" [options]="optionsSliderFull"></ngx-slider>
    </ion-col>
    <ion-col size="12" *ngIf="dataService.isMobile &&slide.answers">
      <ion-card>
        <ion-card-content style="padding-bottom: 13px !important">
          {{ slide.answers[0].name }}
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col size="12">
      <p *ngIf="value != 50">Du stimmst der Aussage zu {{value}}% zu.</p>
      <p *ngIf="value == 50" class="typeInfo">
        50% kann nicht ausgewählt werden
      </p>
      <ui-select *ngIf="value != 50" [items]="weiterButton"></ui-select>
    </ion-col>
  </ion-row>
</ion-grid>
