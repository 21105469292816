export default [
  {
    questionID: "sf_finderichtigeworte",
    question:
      "Egal ob ich mich mit Kolleg:innen, Vorgesetzten oder Kund:innen unterhalte, ich finde die richtigen Worte.",
  },
  {
    questionID: "sf_einstellungnachempfinden",
    question:
      "Wenn Personen aus meinem Arbeitsumfeld ihre Einstellungen und Empfindungen mitteilen, kann ich diese gut verstehen und nachempfinden.",
  },
  {
    questionID: "sf_kolleginnenmitenergieanstecken",
    question:
      "Ich hab die Erfahrung gemacht,<br/> dass ich meine Kolleg:innen mit meiner Energie und Motivation anstecken kann.",
  },
  {
    questionID: "sf_zuspaetfuertermin",
    question:
      "Du hast um 15Uhr einen Termin auf der Arbeit mit deinen Kolleg:innen.<br/>Es ist 14.55Uhr und du brauchst noch mindestens 10 Minuten bis du vor Ort bist. Wie sehr passt dieses Szenario zu dir?",
  },
  {
    questionID: "sf_immerlachenaufdenlippen",
    question:
      "Egal ob montagmorgens oder kurz vor Feierabend<br/>- ich habe so gut wie immer ein Lachen auf den Lippen.",
  },
  {
    questionID: "sf_jedeinverantwortungfuerglueck",
    question:
      "Ich bin der Meinung, jeder im Team trägt selbst die Verantwortung, dass sie/er glücklich und zufrieden ist.",
  },
  {
    questionID: "sf_keinenfestenarbeitsplatz",
    question:
      "Ich finde es gut, keinen festen Arbeitsplatz im Unternehmen zu haben.<br/>Ich suche mir gerne jedes Mal ein nettes Plätzchen zum Arbeiten.",
  },
];
