import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Circle } from "konva/lib/shapes/Circle";
import { Arrow } from "konva/lib/shapes/Arrow";
import { Text } from "konva/lib/shapes/Text";
import { Stage } from "konva/lib/Stage";
import { Layer } from "konva/lib/Layer";

@Component({
  selector: "ui-matrix",
  templateUrl: "./ui-matrix.component.html",
  styleUrls: ["./ui-matrix.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiMatrixComponent implements AfterViewInit {
  @Input() id;
  @Input() position;
  @Input() textX = "LabelX";
  @Input() textY = "LabelY";
  @Output() positionChange = new EventEmitter();

  darkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  containerWidth = 600;
  radius = 50;

  constructor() {}

  ngAfterViewInit() {
    this.initSlider();
  }

  initSlider() {
    if (
      !(
        document.getElementById(`outer-drag-container-${this.id}`)
          ?.offsetWidth > 0
      )
    ) {
      setTimeout(() => this.initSlider(), 500);
      console.log("wait");
      return;
    }
    var sceneWidth = this.containerWidth;

    var stage = new Stage({
      container: `drag-container-${this.id}`,
      width: sceneWidth,
      height: sceneWidth,
    });

    var layer = new Layer();

    const arrowCommonOptions = {
      pointerLength: 10,
      pointerWidth: 10,
      fill: this.darkMode ? "white" : "black",
      stroke: this.darkMode ? "white" : "black",
      strokeWidth: 2,
    };
    var arrowY = new Arrow({
      x: 15,
      y: sceneWidth - 15,
      points: [0, 0, 0, -sceneWidth + 30],
      ...arrowCommonOptions,
    });

    var arrowX = new Arrow({
      x: 15,
      y: sceneWidth - 15,
      points: [0, 0, sceneWidth - 30, 0],
      ...arrowCommonOptions,
    });

    const textCommonOptions = {
      fontFamily: "Roboto",
      fill: this.darkMode ? "white" : "black",
      verticalAlign: "bottom",
    };
    var textY = new Text({
      x: 30,
      y: 5,
      text: this.textY,
      fontSize: 16,
      ...textCommonOptions,
    });

    var textX = new Text({
      x: sceneWidth - 10,
      y: sceneWidth - 60,
      align: "right",
      text: this.textX,
      fontSize: 16,
      ...textCommonOptions,
    });

    var textY100 = new Text({
      x: 0,
      y: 5,
      fontSize: 10,
      text: "100%",
      ...textCommonOptions,
    });

    var textX100 = new Text({
      x: sceneWidth - 60,
      y: sceneWidth - 10,
      align: "right",
      fontSize: 10,
      text: "100%",
      ...textCommonOptions,
    });

    var text0 = new Text({
      x: 0,
      y: sceneWidth - 10,
      fontSize: 10,
      text: "0%",
      ...textCommonOptions,
    });

    layer.add(arrowY);
    layer.add(arrowX);
    layer.add(textY);
    layer.add(textX);
    layer.add(textY100);
    layer.add(textX100);
    layer.add(text0);
    textX.offsetX(textX.width());

    var circle = new Circle({
      x: stage.width() / 2,
      y: stage.height() / 2,
      radius: this.radius,
      fill: "#ff6b1e",
      draggable: true,
    });

    circle.on("dragmove", () => {
      const pos = circle.absolutePosition();

      this.checkBoundaries(pos.x, pos.y, circle, stage);
    });

    circle.on("dragend", (event) => {
      console.log("dragend", event.target.attrs);
      const { x, y } = event.target.attrs;
      this.setPosition(x, y);
    });


    // add cursor styling
    circle.on("mouseover", function () {
      document.body.style.cursor = "pointer";
    });
    circle.on("mouseout", function () {
      document.body.style.cursor = "default";
    });


  //   circle.add(new Text({
  //     text:'123',
  //     fontSize: 18,
  //     fontFamily: 'Calibri',
  //     fill: '#000',
  //     width: 130,
  //     padding: 5,
  //     align: 'center'
  // }));

    stage.on("tap", (event) => {
      console.log("tap", event);
      this.handleClickTapEvent(
        (event.target as Stage).pointerPos.x,
        (event.target as Stage).pointerPos.y,
        circle
        , stage
      );
    });

    stage.on("click", (event) => {
      console.log("click", event.evt);
      this.handleClickTapEvent(event.evt.offsetX, event.evt.offsetY, circle, stage);
    });

    layer.add(circle);
    stage.add(layer);

    const fitStageIntoParentContainer = () => {
      var container = document.getElementById(
        `outer-drag-container-${this.id}`
      );

      // now we need to fit stage into parent container
      this.containerWidth = container.offsetWidth;

      // but we also make the full scene visible
      // so we need to scale all objects on canvas
      const scale = this.containerWidth / sceneWidth;

      stage.width(sceneWidth * scale);
      stage.height(sceneWidth * scale);
      stage.scale({ x: scale, y: scale });
      this.setPosition((sceneWidth * scale) / 2, (sceneWidth * scale) / 2);
    };

    fitStageIntoParentContainer();
    window.addEventListener("resize", fitStageIntoParentContainer);
  }

  handleClickTapEvent(x, y, circle, stage) {
    circle.setAbsolutePosition({ x, y });
    this.setPosition(x, y);
    this.checkBoundaries(x,y, circle, stage);
  }

  checkBoundaries = (x, y, circle, stage) => {
    if (x < 66) {
      circle.x(66);
    }

    const width = stage.width() - 60;
    if (x > width) {
      circle.x(width);
    }

    const widthYmin = 60;
    if (y < widthYmin) {
      circle.y(widthYmin);
    }

    const widthYMax = stage.height() - 66;
    if (y > widthYMax) {
      circle.y(widthYMax);
    }
  };

  setPosition(x, y) {
    console.log(x, y);
    this.position = {
      x: x / this.containerWidth,
      y: 1 - y / this.containerWidth,
    };
    this.positionChange.emit(this.position);
    console.log("xy", this.position);
  }
}
