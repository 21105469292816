<h1 *ngIf="slide.heading" class="pb">{{ slide.heading }}</h1>
<h2 *ngIf="slide.question" class="pb" [innerHTML]="slide.question"></h2>

<ion-grid>
  <ion-row>
    <ion-col size="12" class="pb"> Welcher Seite stimmst du mehr zu? </ion-col>
    <ion-col [offset]="dataService.isMobile?0:1" [size]="dataService.isMobile?12:3">
      <ion-card>
        <ion-card-content style="padding-bottom: 13px !important">
          {{ slide.answers[0].name }}
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col offsetMd="4" sizeMd="3" *ngIf="!dataService.isMobile">
      <ion-card>
        <ion-card-content style="padding-bottom: 13px !important">
          {{ slide.answers[1].name }}
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col offset="1" size="10" class="slider pb">
      <ngx-slider *ngIf="optionsSliderRatio" [(value)]="value" [options]="optionsSliderRatio"></ngx-slider>
      <p style="font-size: 8pt;padding:0;">Du kannst den Wert durch ziehen des großen Punktes auf der Linie verändern.</p>
    </ion-col>
    <ion-col size="12" *ngIf="dataService.isMobile">
      <ion-card>
        <ion-card-content style="padding-bottom: 13px !important">
          {{ slide.answers[1].name }}
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col size="12">
      <p *ngIf="value == 50" class="typeInfo">
        50% kann nicht ausgewählt werden
      </p>
      <ui-select *ngIf="value != 50" [items]="weiterButton"></ui-select>
    </ion-col>
  </ion-row>
</ion-grid>