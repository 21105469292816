import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FitWelcomeComponent } from "./fit-welcome/fit-welcome.component";
import { FitBinaryComponent } from "./fit-binary/fit-binary.component";
import { FitListComponent } from "./fit-list/fit-list.component";
import { FitMatrixComponent } from "./fit-matrix/fit-matrix.component";
import { FitSliderFullComponent } from "./fit-slider-full/fit-slider-full.component";
import { FitSliderRatioComponent } from "./fit-slider-ratio/fit-slider-ratio.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiComponentsModule } from "src/app/ui-components/ui-components.module";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { FitComponent } from "./fit.component";
import { RouterModule, Routes } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { FitMatrixIntroductionComponent } from "./fit-matrix-introduction/fit-matrix-introduction.component";
const routes: Routes = [
  {
    path: "",
    component: FitComponent,
  },
];

@NgModule({
  declarations: [
    FitWelcomeComponent,
    FitBinaryComponent,
    FitListComponent,
    FitMatrixComponent,
    FitSliderFullComponent,
    FitSliderRatioComponent,
    FitMatrixIntroductionComponent,
    FitComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    NgSelectModule,
    UiComponentsModule,
    NgxSliderModule,
    IonicModule,
  ],
  exports: [
    FitWelcomeComponent,
    FitBinaryComponent,
    FitListComponent,
    FitMatrixComponent,
    FitSliderFullComponent,
    FitSliderRatioComponent,
  ],
})
export class FitModule {}
