export default [
  {
    questionID: "m_langeprojekt",
    heading: "Echt jetzt?!",
    question:
      "Du hast lange an einem Projekt gearbeitet und bist mit dem Ergebnis sehr zufrieden.<br/>Dein:e Vorgesetzte:r sieht das allerdings anders. Und jetzt?",
    answers: [
      {
        id: "rechthaben",
        name: "Hmm... er/sie wird schon Recht haben.\nIch hätte es noch besser machen können.",
      },
      {
        id: "sprechean",
        name: "Das seh' ich nicht so und spreche das auch so an.",
      },
    ],
  },
  {
    questionID: "m_mahlzeit",
    heading: "Mahlzeit!",
    question: "Wie würdest du zukünftig gerne deine Mittagspause verbringen?",
    answers: [
      {
        id: "mehrkolleginnen",
        name: "Ich trommle meine Kolleg:innen zusammen\n- je mehr desto besser.",
      },
      {
        id: "spontanbeiallem",
        name: "Egal ob gut bürgerlich, italienisch oder der Chinese nebenan\n- ich bin spontan bei allem dabei.",
      },
    ],
  },
  {
    questionID: "m_mitarbeiterdesmonats",
    heading: "Herzlichen Glückwunsch!",
    question:
      "Du bist Mitarbeiter:in des Monats.<br/>Wie sieht deine Reaktion aus?",
    answers: [
      {
        id: "keinerede",
        name: "Ich bin froh, wenn ich keine Rede halten muss.\nDa bekomme ich kein gerades Wort heraus.",
      },
      {
        id: "tollleistung",
        name: "Ich finde es toll, dass meine Leistung im Vergleich zu\nden anderen so hervorgehoben wird.",
      },
    ],
  },
  {
    questionID: "m_lautstarkeausseinandersetzung",
    heading: "Es rappelt im Karton!",
    question:
      "Mehrere Kolleg:innen haben eine lautstarke Auseinandersetzung.<br/>Wie gehst du damit um?",
    answers: [
      {
        id: "jedenverstehen",
        name: "Mir ist es wichtig jede:n Beteiligte:n zu verstehen",
      },
      {
        id: "nichteinmischen",
        name: "Ich misch mich besser nicht ein\n- paar Tage dicke Luft und das wird sich wieder regeln.",
      },
    ],
  },
  {
    questionID: "m_schwierigeaufgabe",
    heading: "Der Kopf raucht!",
    question:
      "Dein:e Vorgesetzte:r gibt dir eine Aufgabe, die du nicht auf Anhieb lösen kannst.<br/>Wie gehst du jetzt vor?",
    answers: [
      {
        id: "detailliertinformieren",
        name: "Ich betrachte jede mögliche Sichtweise des Problems\n und informiere mich detailliert.",
      },
      {
        id: "sofortloslegen",
        name: "Ich stürz mich sofort drauf.\nIch kenne das Ziel und erreiche es durch ausprobieren und anpassen.",
      },
    ],
  },
  {
    questionID: "m_umsetzungideen",
    heading: "Ran an die Arbeit!",
    question: "Die Umsetzung meiner Ideen ...",
    answers: [
      {
        id: "kurzfristigwenigdetailliert",
        name: "... plane ich lieber kurzfristig und wenig detailliert\n- alles andere kommt später.",
      },
      {
        id: "unkonventionellkreativ",
        name: "... ist unkonventionell und kreativ\n- auf die Ideen würde nicht jeder kommen.",
      },
    ],
  },
];
