import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-fit-matrix",
  templateUrl: "./fit-matrix.component.html",
  styleUrls: ["./fit-matrix.component.scss"],
})
export class FitMatrixComponent implements OnInit {
  @Input() slide;
  @Output() selectAnswer = new EventEmitter();

  position;

  public weiterButton = [
    {
      id: "weiter",
      name: "Weiter",
      iconClass: "far fa-forward",
      onClick: () => {
        this.selectAnswer.emit([
          {
            id: this.slide.answers[0].id,
            value: this.position.x,
          },
          {
            id: this.slide.answers[1].id,
            value: this.position.y,
          },
        ]);
      },
    },
  ];

  constructor() {}

  ngOnInit() {}
}
