export default [
  {
    questionID: "l_list",
    question: "Wie würdest du dich beschreiben?",
    answers: [
      { id: "logisch", name: "logisch" },
      { id: "konfliktbereit", name: "konfliktbereit" },
      { id: "kontaktfreudig", name: "kontaktfreudig" },
      { id: "fröhlich", name: "fröhlich" },
      { id: "redegewandt", name: "redegewandt" },
      { id: "empathisch", name: "empathisch" },
      { id: "friedliebend", name: "friedliebend" },
      { id: "achtsam", name: "achtsam" },
      { id: "selbstsicher", name: "selbstsicher" },
      { id: "zuverlässig", name: "zuverlässig" },
      { id: "tatkräftig", name: "tatkräftig" },
      { id: "einbeziehend", name: "einbeziehend" },
      { id: "motivierend", name: "motivierend" },
      { id: "organisiert", name: "organisiert" },
      { id: "originell", name: "originell" },
      { id: "unvoreingenommen", name: "unvoreingenommen" },
    ],
  },
];
